import { useTranslation } from 'react-i18next';
import './hero.css'
import parse from 'html-react-parser'
import { useState } from 'react';
import Animate from '../../about/Animate';
import { Modal } from 'react-bootstrap';

const Hero = ({countries}: any) => {
    const {t, i18n} = useTranslation();
    const [show, setShow] = useState(false);
    const handleFormSubmit = (event) => {
        event.preventDefault();
        
        // Form submission logic here
        
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'form_submit',
          category: 'user_interaction',
          action: 'submitted_form',
          label: 'contact_us_form',
        });
      };
      
    // devices_list
    const [devices_list, setDevices_list] = useState<any>([
        // Apple Devices
        "iPhone 15",
        "iPhone 15 Pro",
        "iPhone 14",
        "iPhone 14 Pro",
        "iPhone 13",
        "iPhone 13 Pro",
        "iPhone 12",
        "iPhone 12 Pro",
        "iPhone SE (2nd generation)",
        "iPad Pro",
        "iPad Air (3rd generation and later)",
        "iPad (7th generation and later)",
        "iPad mini (5th generation and later)",
    
        // Samsung Devices
        "Samsung Galaxy S23",
        "Samsung Galaxy S23+",
        "Samsung Galaxy S23 Ultra",
        "Samsung Galaxy S22",
        "Samsung Galaxy S22+",
        "Samsung Galaxy S22 Ultra",
        "Samsung Galaxy S21",
        "Samsung Galaxy S21+",
        "Samsung Galaxy S21 Ultra",
        "Samsung Galaxy Z Fold5",
        "Samsung Galaxy Z Flip5",
        "Samsung Galaxy Z Fold4",
        "Samsung Galaxy Z Flip4",
        "Samsung Galaxy Z Fold3",
        "Samsung Galaxy Z Flip3",
        "Samsung Galaxy Note 20",
        "Samsung Galaxy Note 20 Ultra",
    
        // Google Devices
        "Google Pixel 8",
        "Google Pixel 8 Pro",
        "Google Pixel 7",
        "Google Pixel 7 Pro",
        "Google Pixel 6",
        "Google Pixel 6 Pro",
        "Google Pixel 6a",
        "Google Pixel 5",
        "Google Pixel 5a",
        "Google Pixel 4",
        "Google Pixel 4a",
    
        // Microsoft Devices
        "Microsoft Surface Duo",
        "Microsoft Surface Duo 2",
    
        // Motorola Devices
        "Motorola Razr (2019)",
        "Motorola Razr (2020)",
        "Motorola Razr (2022)",
        "Motorola Edge+ (2022)",
    
        // Huawei Devices
        "Huawei P40",
        "Huawei P40 Pro",
        "Huawei Mate 40 Pro",
        "Huawei Mate 30 Pro",
    
        // Other Devices
        "Oppo Find X3",
        "Oppo Find X5",
        "Xiaomi 12",
        "Xiaomi 13",
        "Sony Xperia 10 III",
        "Sony Xperia 5 III",
        "Honor Magic 4 Pro"
    ]);
    const [searchQuery, setSearchQuery] = useState<string>('');

    // Filter devices based on the search query
    const filteredDevices = devices_list.filter(device =>
        device.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleClose = () => setShow(false);
    const handleShow = () =>{
        setShow(true);
        handleFormSubmit(
            {
                preventDefault: () => {}
            }
        );
        
    }
    const isMobile = window.innerWidth < 992;
    return (
        <Animate delay={0.1}>
        <div className="hero">
            <div className="row">
                <div className="col-12 col-lg-6 order-2 order-lg-1 z-1">
                    <Animate delay={0.2}>
                    <div className={"d-flex flex-column justify-content-center retroo " + (i18n.language === 'en' ? "  pe-lg-5 me-lg-5" : " ps-lg-5 ms-lg-5")} style={{
                    }}>
                        <Animate delay={0.3}>
                        <p className="tag mt-3 c-primary mx-auto mx-lg-0 my-3 d-none d-lg-block
                        ">{t("esim.section1.tag")}</p>
                        </Animate>
                        <Animate delay={0.4}>
                        {
                            !isMobile ? 
                            <h1 className="hero__title">{parse(t("esim.section1.title_mobile"))}</h1>
                            :
                            <h1 className="hero__title">{parse(t("esim.section1.title"))}</h1>
                        }
                        </Animate>
                        <Animate delay={0.5}>
                        <p className="mb-0 d-none d-lg-block">{parse(t("esim.section1.sub_title"))}</p>
                        </Animate>
                        <div className='d-none d-lg-flex justify-content-center justify-content-lg-start mt-3 align-items-center'>
                            <div className={"btn-text-hero"} style={{cursor:"pointer"}} onClick={handleShow}>
                                <span className="">{t("esim.section1.country")}
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: i18n.language === 'en' ? "scale(-0.7)" : 'scale(0.7)'}}>
                            <path d="M15.95 31.3669C16.2666 31.3669 16.5833 31.2502 16.8333 31.0002C17.3166 30.5169 17.3166 29.7169 16.8333 29.2335L7.59997 20.0002L16.8333 10.7669C17.3166 10.2835 17.3166 9.48353 16.8333 9.0002C16.35 8.51686 15.55 8.51686 15.0666 9.0002L4.94996 19.1169C4.46663 19.6002 4.46663 20.4002 4.94996 20.8835L15.0666 31.0002C15.3166 31.2502 15.6333 31.3669 15.95 31.3669Z" fill="#3eb6ae"></path><path d="M6.1165 21.25H34.1665C34.8498 21.25 35.4165 20.6833 35.4165 20C35.4165 19.3167 34.8498 18.75 34.1665 18.75H6.1165C5.43317 18.75 4.8665 19.3167 4.8665 20C4.8665 20.6833 5.43317 21.25 6.1165 21.25Z" fill="#3eb6ae"></path></svg>

                                </span>
                            </div>
                            <span className="mx-3"></span>
                            <div className={"btn-text-hero"} style={{cursor:"pointer"}}>
                                <a href="#how_to_install_esim" className="text-decoration-none">
                                <span className="">{t("esim.section1.install")}</span>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: i18n.language === 'en' ? "scale(-0.7)" : "scale(0.7)"}}><path d="M15.95 31.3669C16.2666 31.3669 16.5833 31.2502 16.8333 31.0002C17.3166 30.5169 17.3166 29.7169 16.8333 29.2335L7.59997 20.0002L16.8333 10.7669C17.3166 10.2835 17.3166 9.48353 16.8333 9.0002C16.35 8.51686 15.55 8.51686 15.0666 9.0002L4.94996 19.1169C4.46663 19.6002 4.46663 20.4002 4.94996 20.8835L15.0666 31.0002C15.3166 31.2502 15.6333 31.3669 15.95 31.3669Z" fill="#3eb6ae"></path><path d="M6.1165 21.25H34.1665C34.8498 21.25 35.4165 20.6833 35.4165 20C35.4165 19.3167 34.8498 18.75 34.1665 18.75H6.1165C5.43317 18.75 4.8665 19.3167 4.8665 20C4.8665 20.6833 5.43317 21.25 6.1165 21.25Z" fill="#3eb6ae"></path></svg>
                                </a>
                            </div>
                        </div>
                        <div className='d-flex d-lg-none justify-content-center justify-content-lg-start mt-3 align-items-center'>
                            <div className={"btn-text-hero m-0"} style={{cursor:"pointer", minWidth:"120px"}} onClick={handleShow}>
                                <span className="">{t("esim.section1.country")}

                                </span>
                            </div>
                            <span className="mx-3">|</span>
                            <div className={"btn-text-hero m-0"} style={{cursor:"pointer", minWidth:"120px"}}>
                                <a href="#how_to_install_esim" className="text-decoration-none">
                                <span className="">{t("esim.section1.install")}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    </Animate>
                </div>
                <div className="col-12 col-lg-6 position-relative order-1 order-lg-2 d-block my-4" style={{}}>
                    {/* <div className="top_gradient" style={{background: "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)", height: "200px", position: "absolute", top: "0", width: "calc(100% - 24px)"}}/> */}
                        <div className="images_bg" style={{backgroundImage: "url(./media/hero/hero.png)"}}/>
                    {/* <div className="button_gradient" style={{background: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)", height: "200px", position: "absolute", bottom: "0", width: "calc(100% - 24px)"}}/> */}
                </div>
            </div>
            

            
        </div>
        <Modal show={show} onHide={handleClose} size='xl'

        className="modal_store_info" style={
                                    {
                                        direction: i18n.language === "ar" ? "rtl" : "ltr"
                                    }
                                }>
                                    <Modal.Header closeButton  style={{
                                            border: "none",
                                            padding: "0",
                                            width: "100%"
                                        }}>
                                    <Modal.Title>{t('esim.section1.popup.title')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='hero_pop mt-3'>
                                        {/* <div className='mydevice d-flex justify-content-between align-items-center'>
                                            <p className='m-0'>Iphone ({t('esim.section1.popup.your_device')})</p>
                                            <div className='d-flex align-items-center'>
                                                <p className='m-0 supported'>Supported</p>
                                            </div>
                                        </div>*/}
                                                    <div className="search text-start my-3">
                <i className="fas fa-search search__icon mx-2"/>
                <input 
                    type="text" 
                    placeholder="Search your device" 
                    className="search__input"
                    value={searchQuery} 
                    onChange={(e) => setSearchQuery(e.target.value)} // Update search query on change
                />
            </div>

                                        <div className="devices_list">
                                        {
                    filteredDevices.length > 0 ? (
                        filteredDevices.map((element, index) => (
                            <div key={index} className="device">
                                <p className="device__name">{element}</p>
                            </div>
                        ))
                    ) : (
                        <p>No devices found</p> // Message when no device matches the search query
                    )
                }
                                        </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
        </Animate>
    )
}

export default Hero
import { Link } from "react-router-dom";
import Header from "../../components/header/header";
import '../about/about.css'
import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

const Privacy = () => {
    const {t, i18n} = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isHeadless = searchParams.get('headless');


    return <div >
        <div className="container about mb-5" >
        {
        isHeadless !== 'true' &&
        <Header/>
    }
   
   <TermsInner/>
   
</div>
    <Footer/>
</div>

}

export const TermsInner = () => {
    const {t, i18n} = useTranslation();
    return  (
        i18n.language === "ar" ?
    <div style={{
        maxWidth: "900px",
        margin: "auto",
        marginTop: "50px",
    }} className="terms"
    >
    <div className="mb-3 mt-5">
        <h1>
            {t("footer.privacy")}
        </h1>
    </div>
      <p>عند استخدامك خدماتنا فآنك تعطينا الثقة الكاملة لبياناتك، حيث تهدف سياسة الخصوصية الخاصة بنا لمساعتدك على فهم ومعرفة ماهية البيانات التي نجمعها ونعالجها، وأسباب جمعها وماذا نعمل بها، وحدود مشاركتها وضوابط الإفصاح عنها، وطريقة تحديثها وحذفها، عند استخدامك التطبيق وكافة الخدمات المقدمة من خلاله وكافة الخدمات أو التطبيقات الأخرى التابعة لنا والتي تشاركنا تقديم الخدمات، وتعد جزء لا يتجزأ من الشروط والأحكام.</p>
<p>نحن ندرك حجم هذه المسئولية ونعمل بجدية على حماية بياناتك ولتقديم خدمة أفضل لك، ونمنحك التحكم فيها طبقًا لممارسات هذه السياسة.</p>
<p>في نطاق هذه السياسة، تشير كلمة &ldquo;التطبيق&rdquo; أو &ldquo;Tygo&rdquo; أو &ldquo;نحن&rdquo; أو &ldquo;نا الفاعلين&rdquo; إلى تطبيق &ldquo;Tygo&rdquo;. كما يشير &ldquo;أنت&rdquo; أو &ldquo;ضمائر المخاطب&rdquo; إلى أي شخص يقوم بزيارة أو استخدام التطبيق أو أي من خدماتها بأي شكل من الأشكال. يرجى قراءة هذه السياسة جيدًا قبل استخدام التطبيق، وتحتوي السياسة على البنود الآتية:&nbsp;</p>
<p>أولاً: البيانات التي نجمعها منك</p>
<p>عند استخدامك التطبيق يجوز لنا جمع العديد من المعلومات ومعالجتها واستخدامها بما في ذلك:</p>
<p>معلومات عضوية العميل عندما تقوم بإنشاء عضوية العميل بالتطبيق، والتي تشمل على سبيل المثال (الاسم، العنوان، البريد الإلكتروني، رقم الجوال).</p>
<p>بيانات الهوية الحكومية. البيانات التي نرى أنها ضرورية للتحقق من هويتك أو للتأكد من صحة وقانونية أي عمليات تقوم بها عبر التطبيق، بما في ذلك صورة من جواز سفرك، أو صورة من الهوية الوطنية الخاصة بك.</p>
<p>استخدام البيانات. البيانات المتعلقة بنشاطك، استخدامك لخدماتنا، والشخصية التي تقوم بتزويدنا بها أثناء الترويج للخدمات وتقديم العروض عبر التطبيق.</p>
<p>المعلومات الأخرى التي تقدمها إلينا مثل: الإبلاغ عن مشكلة متعلقة باستخدام التطبيق أو مع أحد مزودي الخدمة، أو الاتصال بنا أو عند الاتصال بالدعم الفني الخاص بنا، و عند إجاباتك على الأسئلة أو الاستبيانات عبر التطبيق.</p>
<p>أنت تعلم وتقر بالموافقة أننا قد نجمع بياناتك في سجلاتنا الإلكترونية أو سجلاتنا الورقية وفقًا لما نراه مناسبًا.&nbsp;</p>
<p>ثانيًا: ملفات تعريف الارتباط</p>
<p>ملفات تعريف الارتباط &ldquo;الكوكيز&rdquo; هي ملفات نصية صغيرة يتم تخزينها على جهازك بمجرد زيارتك للتطبيق، وهي لا تظهر في صورة برنامج ولا تحمل فيروسات أو تقنيات تجسس على الإطلاق، وتستخدم هذه الملفات للأغراض الآتية:</p>
<p>تستخدم الكوكيز لأغراض تقديم الخدمات، حيث تساعدنا على فهم طريقة استخدام الزوار والمستخدمين لتطبيقنا.</p>
<p>تساعدنا ملفات الارتباط على اكتشاف الأخطاء وإصلاحها، كما تساعدنا على تطوير تطبيقنا وتقديم محتوى أفضل يناسب المستخدم، وتقديم خدمة أفضل.</p>
<p>تساعدك ملفات الارتباط على إيجاد البيانات التي تحتاجها أثناء استخدامك للتطبيق.</p>
<p>تستطيع من خلال هاتفك الجوال التحكم في إعدادات الكوكيز بالطريقة التي تناسبك.</p>
<p>لا نملك أي سلطة على ملفات تعريف الارتباط التابعة لجهات أخرى، وأنت تتعهد بالاطلاع على سياسات الخصوصية واستخدام ملفات الكوكيز التابعة لهذه الجهات.&nbsp;</p>
<p>ثالثًا: حدود استخدامنا لبياناتك</p>
<p>نحن ملتزمون دائمًا بحماية خصوصيتك، وبالتالي فنحن نستخدم بياناتك الشخصية للأغراض الآتية:</p>
<p>لتمكينك من استخدام خدماتنا على النحو الأمثل، وتقديم الأفضل لك دائمًا.</p>
<p>لإنشاء حسابك أو ملفك الشخصي الذي تتواصل معنا من خلاله عبر التطبيق.</p>
<p>لمعالجة البيانات التي تقدمها عبر خدماتنا كالتأكد من أن بريدك الإلكتروني أو رقم هاتفك نشط وصالح للاستخدام ومملوك لك.</p>
<p>لتلقي الأسئلة والشكاوى والاستفسارات منك، والرد عليها.</p>
<p>لتلقي تعليقاتك وتقييمك للخدمة التي تلقيتها من خلال تطبيقنا، والرد عليها.</p>
<p>لمعالجة إجاباتك على الاستبيانات المطروحة عبر التطبيق والتي شاركت في الإجابة عليها.</p>
<p>لتزويدك بالمعلومات عن الخدمات التي تطلبها عبر تطبيقنا.</p>
<p>لتزويدك بالمعلومات عن الخدمات التي نعتقد أنها تثير اهتمامك بما في ذلك العروض الخاصة بنا.</p>
<p>لأغراض العمل الداخلية مثل تحسين خدماتنا.</p>
<p>لتخصيص المحتوى والتوصيات والإعلانات التي نقدمها نحن والأطراف الاخرى، سواء على الخدمات أو في أي مكان آخر على شبكة الإنترنت.</p>
<p>لإدارة ومعالجة المسابقات والعروض الترويجية.</p>
<p>للاتصال بك بشأن الاتصالات الحكومية، ووفقًا لتقديرنا الخاص، بخصوص التغييرات في سياسة الخصوصية أو شروط الاستخدام أو أي من سياستنا الأخرى.</p>
<p>للامتثال للالتزامات التنظيمية والقانونية.</p>
<p>للأغراض التي يتم الكشف عنها في الوقت الذي تقدم فيه معلوماتك، بموافقتك، وطبقًا لسياسة الخصوصية هذه.</p>
<p>نستخدم بياناتك لأغراض تنفيذ شروطنا وأحكامنا ودفع المبالغ المستحقة عليك.</p>
<p>نستخدم بياناتك لحل المشكلات ومنع الأنشطة غير القانونية بما في ذلك عمليات الاحتيال والقرصنة.</p>
<p>نستخدم بياناتك لتزويدك بكل جديد عن خدماتنا أو تعديلها أو تحسينها بما في ذلك التواصل معك إلكترونيًا عبر رسائل البريد الإلكتروني أو هاتفيًا عبر الاتصال برقم الجوال الخاص بك.&nbsp;</p>
<p>رابعًا: حماية البيانات</p>
<p>يتعهد التطبيق بالاحتفاظ ببياناتك الشخصية للمدة التي تقتضيها طبيعة المعاملة التي تقوم بها عبر التطبيق.</p>
<p>يتعهد التطبيق بعدم استخدام بياناتك الشخصية لأغراض غير مصرح أو مسموح بها بمقابل أو بدون مقابل مع أي جهة أخرى إلا إذا كان ذلك مطلوبًا منا أو مصرحًا به بموجب الأنظمة والتعليمات ذات العلاقة، أو بموافقة كتابية مسبقة من المستخدم الذي تتعلق به المعلومات.</p>
<p>يتعهد التطبيق بالحفاظ على السجلات التي تحتوي على البيانات الشخصية للمستخدم أو أي سجلات للاتصالات الإلكترونية، تكون في عهدتنا أو تحت سيطرتنا أو مع وكلائنا أو منسوبينا.</p>
<p>يتعهد التطبيق باتخاذ الخطوات المعقولة، لضمان أن البيانات الشخصية للمستخدم، والسجلات ذات الصلة، محمية بطريقة آمنة تناسب أهميتها.</p>
<p>نتعهد بحماية خصوصيتك في جميع الأوقات، وأننا لن نبيع أبدًا بياناتك الشخصية، أو نسمح بتداولها مع الغير.</p>
<p>خامسًا: مشاركة بياناتك</p>
<p>أنت تعلم وتوافق على أن الإنترنت ليس وسيلة آمنة، وأن سرية بياناتك الشخصية لا يمكن أن تكون مضمونة بشكل كامل.</p>
<p>أنت تمنحنا الحق في السماح للموظفين لدينا بالتعامل مع بياناتك الشخصية في حدود تقديم الخدمات.</p>
<p>يجوز لنا الكشف عن بيانات الشخصية لأي عضو في مجموعتنا؛ وهو ما يعني فروعنا ومؤسساتنا.</p>
<p>قد نكشف بياناتك الشخصية لأطراف ثالثة:</p>
<p>في حال قيامنا ببيع التطبيق أو أي أصول خاصة بنا، وسنقوم بالكشف عن بياناتك للمشتري الجديد.</p>
<p>إذا كنا مطالبين بالإفصاح عن بياناتك الشخصية أو مشاركتها من أجل الامتثال لأي التزام قانوني أو من أجل فرض أو تطبيق شروطنا وأحكامنا أو أي اتفاقية أخرى.</p>
<p>في حالة صدور حكم قضائي أو قرار من الجهات القضائية المختصة يلزمنا بذلك.</p>
<p>إذا كنا مطالبين بالإفصاح عن بياناتك الشخصية أو مشاركتها من أجل حماية الحقوق والملكية أو سلامة تطبيق &ldquo;Tygo&rdquo; أو موظفينا أو عملائنا أو الغير.</p>
<p>ويشمل هذا الإفصاح تبادل البيانات مع الشركات والهيئات الأخرى للحماية من مخاطر الائتمان والاحتيال.&nbsp;</p>
<p>سادسًا: التزامات الأطراف (العميل، مورد الخدمة)</p>
<p>يلتزم أطراف هذه الاتفاقية بالمحافظة على سرية وخصوصية بياناتهم الشخصية، ويقرون وعلى مسئوليتهم الشخصية بأن إفصاح أيًا منهم عن البيانات الشخصية للطرف الآخر يكون على مسئوليته دون أي تدخل منا، ودون أي مسئولية علينا.</p>
<p>يتعهد الأطراف بالمحافظة على أسرار التطبيق، ويُسأل كلاً منهم في مواجهتنا عن أي إخلال بسرية التطبيق وعملياتها.</p>
<p>تلتزم بالامتناع عن الإفصاح عن أية بيانات متعلقة بتشغيل التطبيق إلى الغير سواء بمقابل أو بدون مقابل.</p>
<p>تلتزم بالمحافظة على سرية كافة البيانات المتعلقة بتفاصيل المعاملات عبر التطبيق، وعدم استخدامها بأي شكل من الأشكال، وتعد مسئولاً مسئولية عقدية وقانونية عن أي استخدام غير مشروع أو غير مصرح به لهذه البيانات.</p>
<p>أنت تقر بتجنب إتباع أي وسيلة من شأنها أن تساعد على جمع البيانات عن المستخدمين الآخرين، بما في ذلك البريد الإلكتروني وأرقام الجوال ووسائل الاتصال الأخرى الخاصة بهم.</p>
<p>سابعًا: التحديثات</p>
<p>قد يتم تحديث سياسة الخصوصية الخاصة بنا في أي وقت على سبيل المثال التغيرات التي تطرأ على ممارستنا أو لأسباب تشغيلية أو قانونية أو تنظيمية أخرى، ويعد استمرارك في استخدام التطبيق بعد تحديث هذه السياسة موافقة صريحة منك على هذه التعديلات وقبولاً قانونيًا منك بالممارسات والأحكام الجديدة. يرجى مراجعة سياسة الخصوصية من حين لآخر للاطلاع على أحكامها وتحديثاتها، وفي حال كنت غير موافق على التعديلات والتحديثات الجديدة يجب عليك التوقف فورًا عن استخدام التطبيق.&nbsp;</p>
<p>ثامنًا: الاتصال بنا</p>
<p>لمزيد من المعلمات حول ممارسات الخصوصية لدينا، وإذا كان لديك أسئلة أو كنت ترغب في تقديم شكوى يرجى التواصل معنا عبر الوسائل المتاحة على التطبيق..</p>
<p>&nbsp;</p>
<p><br/></p>  
</div>:
<div style={{
        maxWidth: "900px",
        margin: "auto",
        marginTop: "50px",
        
    }} className="terms"
    >
         <div className="mb-3 mt-5">
        <h1>
            {t("footer.privacy")}
        </h1>
    </div>
        <p >When you use our services, you give us full confidence in your data, as our privacy policy aims to help you understand and know what data we collect and process, the reasons for collecting it, what we do with it, the limits of its sharing and disclosure controls, and the way to update and delete it, when you use the application and all services provided through it and all our other services or applications that share our services with you, and are an integral part of the Terms and Conditions.</p>
<p >We understand the extent of this responsibility and work diligently to protect your data and to provide you with a better service, and give you control over it in accordance with the practices of this policy.</p>
<p >Within the scope of this Policy, &quot;Application&quot;, &ldquo;Tygo&rdquo;, &quot;we&quot; or &quot;our &quot; refers to the &ldquo;Tygo&rdquo; application. Also, &quot;you&quot; or &ldquo;your&quot; refers to any person who visits or uses the Application or any of its services in any way. Please read this policy carefully before using the application. The policy contains the following terms:</p>
<h4 ><span >First: The data we collect from you</span></h4>
<p >When you use the Application, we may collect, process and use a variety of information including:</p>
<p >- Customer membership information when you create a customer membership in the application, which includes for example (name, address, email, mobile number).</p>
<p >- Government ID data: Data that we deem necessary to verify your identity or to ensure the validity and legality of any operations you perform through the Application, including a copy of your passport, or a copy of your national ID.</p>
<p >- Data usage: Data relating to your activity, your use of our Services, and the personal data you provide to us while promoting the Services and making offers through the App.</p>
<p >- Other information you provide to us such as: Reporting a problem with the use of the Application or with a service provider, or contacting us or when contacting our technical support, and when you answer questions or surveys through the Application.</p>
<p >-You know and agree that we may collect your data in our electronic records or paper records as we see fit.</p>
<h4 ><span >Secondly: Cookies</span></h4>
<p >&quot;Cookies&quot; are small text files that are stored on your device as soon as you visit the application, and they do not appear in the form of a program and do not carry viruses or spying technologies at all. These files are used for the following purposes:</p>
<p >- Cookies are used for the purposes of providing services, as they help us understand how visitors and users use our application.</p>
<p >- Cookies help us troubleshoot errors, help us develop our app, provide better user-friendly content, and provide a better service.</p>
<p >- Cookies help you find the data you need while you are using the application.</p>
<p >- Through your mobile phone, you can control the cookie settings in a way that suits you.</p>
<p >- We have no control over third-party cookies, and you undertake to read the privacy policies and use of third-party cookies.</p>
<h4 ><span >Third: Limitations of our use of your data</span></h4>
<p >We are always committed to protecting your privacy and therefore we use your personal data for the following purposes:</p>
<p >-To enable you to use our services optimally, and to always provide you with the best.</p>
<p >- To create your account or profile through which you communicate with us through the application.</p>
<p >- To process the data, you provide through our services, such as ensuring that your email or phone number is active, usable and owned by you.</p>
<p >-To receive and respond to questions, complaints and inquiries from you.</p>
<p >-To receive and respond to your comments and evaluation of the service you received through our application.</p>
<p >- To process your answers to the questionnaires submitted through the application and which you participated in answering.</p>
<p >-To provide you with information about the services you request through our application.</p>
<p >-To provide you with information about services we think will be of interest to you, including our special offers.</p>
<p >-For internal business purposes such as improving our services.</p>
<p >-To personalize the content, recommendations and advertisements that we and third parties provide, whether on the Services or elsewhere on the Internet.</p>
<p >- To administer and process contests and promotions.</p>
<p >-To contact you about government communications and, in our sole discretion, about changes to the Privacy Policy, Terms of Use, or any of our other policies.</p>
<p >-To comply with regulatory and legal obligations.</p>
<p >-For purposes disclosed at the time you provide your information, with your consent, and in accordance with this Privacy Policy.</p>
<p >-We use your data for the purposes of implementing our terms and conditions and paying the amounts you owe.</p>
<p >-We use your data to solve problems and prevent illegal activities including scams and hacking.</p>
<p >-We use your data to provide you with news, modify or improve our services, including by communicating with you electronically via emails or by telephone by calling your phone number.</p>
<h4 ><span >Fourthly: Data protection</span></h4>
<p >- The application undertakes to keep your personal data for the period required by the nature of the transaction you perform through the application.</p>
<p >- The application undertakes not to use your personal data for purposes that are not authorized or permitted for a fee or free of charge with any third party unless this is required or authorized by us under the relevant regulations and instructions, or with the prior written consent of the user to whom the information relates.</p>
<p >- The application undertakes to maintain records containing personal data of the user or any records of electronic communications, which are in our custody or under our control or with our agents or employees.</p>
<p >-The Application undertakes to take reasonable steps to ensure that the User&apos;s personal data, and related records, are protected in a secure manner appropriate to its importance.</p>
<p >-We pledge to protect your privacy at all times, and that we will never sell your personal data, or allow it to be shared with third parties.</p>
<h4 ><span >Fifth: Share your data</span></h4>
<p >1. You know and agree that the Internet is not a secure medium, and that the confidentiality of your personal data cannot be fully guaranteed.</p>
<p >2. You grant us the right to allow our employees to handle your personal data within the limits of providing the Services.</p>
<p >3. We may disclose Personal Data to any member of our group; Which means our subsidiaries and institutions.</p>
<p >4. We may disclose your personal data to third parties:</p>
<ul >
    <li ><span >If we sell the App or any of our assets, we will disclose your data to the new purchaser.</span></li>
    <li ><span >If we are required to disclose or share your personal data in order to comply with any legal obligation or in order to enforce or apply our terms and conditions or any other agreement.</span></li>
    <li ><span >In the event of a court ruling or a decision from the competent judicial authorities obligating us to do so.</span></li>
    <li ><span >If we are required to disclose or share your personal data in order to protect the rights, property, or safety of Tygo, our employees, customers, or third parties.</span></li>
    <li><span >This disclosure includes exchanging data with companies and other bodies to protect against credit and fraud risks.</span></li>
</ul>
<h4 ><span >Sixthly: Obligations of the parties (customer, service provider)</span></h4>
<p >1. The parties to this agreement are obligated to maintain the confidentiality and privacy of their personal data, and they acknowledge on their personal responsibility that the disclosure of personal data to the other party is at their own risk without any interference from us, and without any liability to us.</p>
<p >2. The parties undertake to maintain the secrets of the Application, and each of them shall be liable to us for any breach of the confidentiality of the Application and its operations.</p>
<p >3. You are obligated to refrain from disclosing any data related to the operation of the application to third parties, whether with or without a consideration.</p>
<p >4. You are committed to maintaining the confidentiality of all data related to the details of transactions through the application, and not to use it in any way, and you are responsible, contractually and legally, for any illegal or unauthorized use of this data.</p>
<p >5. You agree to avoid using any method that helps to collect data about other users, including their e-mail, mobile numbers and other means of communication.</p>
<h4 ><span >Seventh: Updates</span></h4>
<p >Our Privacy Policy may be updated at any time for example changes to our practice or other operational, legal or regulatory reasons, and your continued use of the Application after this Policy has been updated constitutes your express acceptance of such modifications and your legal acceptance of the new practices and provisions. Please review the Privacy Policy from time to time to see its provisions and updates, and if you do not agree to the new modifications and updates, you must immediately stop using the application.</p>
<h4 ><span >Eighth: Contact us</span></h4>
<p >For more information about our privacy practices, and if you have questions or would like to make a complaint, please contact us by 
    
{/* It@tygo.sa */}
{/* +966 55 570 1856 */}
    email: <a href="mailto: It@tygo.sa" >
    It@tygo.sa
</a> or by phone: <a href="tel: +966 55 570 1856" >
+966 55 570 1856

</a>.</p>
</div>
    )
}

export default Privacy;



//costum button component

import React, { ReactNode } from "react";
import "./Button.css";
interface ButtonProps {
    inner: ReactNode;
    color?: string;
    isDisabled?: boolean;
    isfullWidth?: boolean;
    isrounded?: boolean;
    isLoading?: boolean;
    onClick?: () => void;
    size?: "sm" | "md" | "lg";
}

const Button = (props: ButtonProps) => {
    return (
        <button
            className={`
            ${'btn-size-'+props.size}
            ${props.color} btn ${props.isfullWidth ? "full-width" : ""} ${props.isrounded ? "rounded" : ""}`}
            disabled={props.isDisabled || props.isLoading}

            onClick={props.onClick}

        >
            {props.isLoading? <img src="/media/gifs/loading.gif" alt="" height={20} /> : props.inner}
        </button>
    );
};

const defaultProps: ButtonProps = {
    inner: <div></div>,
    color: "primary",
    isDisabled: false,
    isfullWidth: false,
    isrounded: false,
    onClick: () => { },
};


Button.defaultProps = defaultProps;

const ButtonSquare = (props: ButtonProps) => {
    return (
        <button
            className={`${props.color} square-btnn me-3 btn ${props.isfullWidth ? "full-width" : ""} ${props.isrounded ? "rounded" : ""}`}
            disabled={props.isDisabled || props.isLoading}
            onClick={props.onClick}
        >
            {props.isLoading? <img src="/media/gifs/loading.gif" alt="" height={20} /> : props.inner}
        </button>
    );
};




export default Button;
export { ButtonSquare };

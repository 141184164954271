import Axios from "axios";
import { REACT_APP_API_PROVIDERS } from '../../config'

const getProviders = async (lang: string) => {
  const plans = await Axios.get(
    // "https://test.providers.tygo.net/api/provider/customer",
    REACT_APP_API_PROVIDERS + "/api/provider/customer",
    {
      headers: {
        accept: "text/plain",
        "Accept-Language": lang,
      }
    }

    
  );

  return plans.data;
}

// const provider = await axios.get
// (`https://test.providers.tygo.net/api/provider/customer`,
// {
//     params: {
//         providerId: providerId,
//         language: i18n.language
//     }
// })
// .then((a: any) => {
//     return a.data.returnData;
// })


const getProvider = async (providerId: number, lang: string) => {
  const provider = await Axios.get(
    REACT_APP_API_PROVIDERS + "/api/provider/customer",
    {
      params: {
        providerId: providerId,
        language: lang
      }
    }
  );
  return provider.data.returnData;
}

// "https: //providers .tygo.net/api/Country/customer/has-esim"

const getCountries = async (lang: string) => {
    const countries = await Axios.get(
      REACT_APP_API_PROVIDERS
      // "https://providers.tygo.net"
       + "/api/Country/customer/has-esim",
      {
        headers: {
          accept: "text/plain",
          "Accept-Language": lang,
        }
      }

    );
    return countries.data;
}
const getProviderById = async (id: number, lang: string) => {
  const provider = await Axios.get(
    REACT_APP_API_PROVIDERS + "/api/provider/" + id + "/customer",
    {
      headers: {
        accept: "text/plain",
        "Accept-Language": lang,
      }
    }
  );
  return provider.data;
}

const getCountryById = async (id: number, lang: string) => {
  const country = await Axios.get(
    REACT_APP_API_PROVIDERS + "/api/Country/" + id + "/customer",
    {
      headers: {
        accept: "text/plain",
        "Accept-Language": lang,
      }
    }
  );

  return country.data;
}


export {
    getProviders,
    getCountries,
    getProviderById,
    getCountryById,
    getProvider
};
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createAccount, loginAccount, verifyCode } from "../../services/accounts.service";
import Button from "../../components/design/buttons/Button";
import Footer from "../../components/footer/footer";
import { Modal } from "react-bootstrap";
import Header from "../../components/header/header";
import React from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const RegisterForm = ({setState, setMethod, method} : {setState: any, setMethod: any, method: string}) => {
    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [error, setError] = useState<string>("");

    const register = async () => {
        setLoading(true);
        await createAccount({
            email,
            phoneNumber:phone.toString().replace(/^0+/, ''),
            firstName:name.split(" ")[0],
            gander:"Male",
            countryCode: "966",
            registertionType: method,
        }).then((res) => {
            console.log(res);
            const user = {
                phoneNumber: phone,
                email,
                name,
                countryCode: "966",
            }
            localStorage.setItem("tygo_user", JSON.stringify(user));

            if(res.status === 200) {
                setState("otp");
            }
            
            setLoading(false);
        }
        ).catch(async (err) => {
            setError(err.response.data.Message);
            setLoading(true);
            await loginAccount({
                email,
                phoneNumber:phone.toString().replace(/^0+/, ''),
                countryCode: "966",
                logintType: method,
            })
            .then((res) => {
                console.log(res);
                const user = {
                    phoneNumber: phone,
                    email,
                    name,
                    countryCode: "966",
                }
                localStorage.setItem("tygo_user", JSON.stringify(user));
                if(res.status === 200) {
                    setState("otp");
                }
            }
            ).catch((err) => {
                setError(err.response.data.Message);
            });
            setLoading(false);
        }
        );
    }

    useEffect(() => {
        // const user = localStorage.getItem("tygo_user");
        // if(user) {
        //     setState("otp");
        // }
    }, []);



    return (
        <div className="card_form">
            <h1 className="my-4 d-none d-md-block">
                {t("store.register.title")}
            </h1>
            <h6 className=" d-block d-md-none">
                {t("store.register.title")}
            </h6>
            <div className="row payment_row my-3">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="">{t("store.register.name")}</label>
                        <input type="text" className="form-control" onChange={(e) => setName(e.target.value)} />
                    </div>
                </div>
                <div className="col-md-12">
                <div className="form-group">
                        <label htmlFor="">{t("store.register.email")}</label>
                        <input type="text" className="form-control" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>
                <div className="col-md-12">
                <div className="form-group">
                        <label htmlFor="">{t("store.register.phone")}</label>
                        <input type="text" className="form-control" onChange={(e) => setPhone(e.target.value)} />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="">{t("store.register.method")}</label>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="check_box my-2 d-flex align-items-center">
                                    <input type="radio" name="radio" 
                                    onChange={(e) => {setMethod("Email")}}
                                    value="Email"
                                    checked={method === "Email"}
                                    />
                                    <span className="mx-3">{t("store.register.method1")}</span>
                                </label>
                            </div>
                            <div className="col-md-6">
                                <label className="check_box my-2 d-flex align-items-center">
                                    <input type="radio" name="radio" 
                                    onChange={(e) => {setMethod("PhoneNumber")}}
                                    value="PhoneNumber"
                                    checked={method === "PhoneNumber"}
                                    />
                                    <span className="mx-3">{t("store.register.method2")}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <p className="mb-2 pb-3 d-block">{t("store.register.info")}</p>
            <p className="text-danger">{error}</p>
            <Button inner={t("store.register.submit")}
             color="primary mt-2" isfullWidth onClick={register} isLoading={loading} />
        </div>
    );
}

const Login = (
    ) => {
    
        const {t, i18n} = useTranslation();
        const [state, setState] = useState<any>( "register");
        const isMobile = window.innerWidth < 992;
   
       
    
        
       
        
        const [method, setMethod] = useState<string>("PhoneNumber");
    
    
        return (
            <>
            <div className="payment__section">
                <div className="container">
                <Header />
                        <div style={{maxWidth: "900px", margin: "50px auto auto"}}>
                            <div className="mt-md-5 pe-md-5 mt-3 mb-4">
                                {
                                    state === "register" || state === "otp" ? <RegisterForm setState={setState} setMethod={setMethod} method={method} />
                                
                                : <></>
                                }
                                <Modal show={state === "otp"} onHide={() => setState("register")} className="modal_store_info" style={
                                    {
                                        direction: i18n.language === "ar" ? "rtl" : "ltr"
                                    }
                                }>
                                    <Modal.Header closeButton  style={{
                                            border: "none",
                                            padding: "0",
                                            width: "100%"
                                        }}>
                                    <Modal.Title>{t('store.otp.title')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OtpForm setState={setState} method={method} />
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                </div>
            </div>
            <Footer />
            
            </>
        );
    }
    const OtpForm = ({setState, method} : {setState: any, method: string}) => {
        const {t, i18n} = useTranslation();
        const [error, setError] = useState<string>("");
        const [loading, setLoading] = useState<boolean>(false);
        const [otp, setOtp] = useState(["", "", "", ""]);
        const inputRefs = useRef([...Array(4)].map(() => React.createRef())) as any;
        const user = JSON.parse(localStorage.getItem('tygo_user') || '{}');
        const navigate = useNavigate();
        const [counter, setCounter] = useState<number>(60);
        const handleOtpChange = (index: number, value: string) => {
          if (value === "") {
            const newOtp = [...otp];
            newOtp[index] = "";
            setOtp(newOtp);
      
            if (index > 0) {
              inputRefs.current[index - 1].current.focus();
            }
          } else if (+value >= 0 && +value <= 9) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
      
            if (index < otp.length - 1) {
              inputRefs.current[index + 1].current.focus();
            }
          }
            if (index === otp.length - 1 && value !== "") {
                const otpValue = otp.join("");
                console.log(otpValue);
                const newOtp = [...otp];
                newOtp[index] = value;
             
                verify(newOtp.join(""));
            }
        };
        const [otptype, setOtpType] = useState<string>("PhoneNumber");
        const handleKeyDown = (index: number, e: any) => {
          if (e.key === "Backspace" && index > 0 && otp[index] === "") {
            e.preventDefault();
            setOtp((prevOtp) => {
              const newOtp = [...prevOtp];
              newOtp[index - 1] = "";
              return newOtp;
            });
            inputRefs.current[index - 1].current.focus();
          }
        };
        const verify = async (str: string) => {
    
            setLoading(true);
            await verifyCode(str, method
            ).then((res) => {
                console.log(res);
                if(res.status === 200) {
                    const user = JSON.parse(localStorage.getItem("tygo_user")!);
                    user.token = res.data.token;
                    user.userId = res.data.userId;
                    user.tokenExpiryDate = res.data.tokenExpiryDate;
                    localStorage.setItem("tygo_user", JSON.stringify(user));
            
                    // setState("card");
                    navigate('/');
                }
                setLoading(false);
            }
            ).catch((err) => {
                setError(err.response.data.Message);
                setLoading(false);
            }
            );
        }
        // Email
        const Resend = async (typw:string) => {
            const user = JSON.parse(localStorage.getItem("tygo_user")!);
            // createAccount({
            //     firstName:user.name.split(" ")[0],
            //     gander:"Male",
            //     countryCode: "966",
            //     email:user.email,
            //     phoneNumber:user.phoneNumber.toString().replace(/^0+/, ''),
            //     registertionType: typw,
            // })
            loginAccount({
                        email:user.email,
                        phoneNumber:user.phoneNumber.toString().replace(/^0+/, ''),
                        countryCode: "966",
                        logintType: typw,}).then(e=>{
                            toast.success(t("store.otp.message"));
                        })
                    
            
            .then(e=>{
                toast.success(t("store.otp.message"));
            })
            .catch(
                (err) => {
                    // loginAccount({
                    //     email:user.email,
                    //     phoneNumber:user.phoneNumber.toString().replace(/^0+/, ''),
                    //     countryCode: "966",
                    //     logintType: typw,}).then(e=>{
                    //         toast.success(t("Otp Has been sent"));
                    //     })
                    // })
                    // .catch((err) => {
                        setError(err.response.data.message);
                    }
                );
    
        }
    
        useEffect(() => {
            const interval = setInterval(() => {
                setCounter((prev) => {
                    if(prev === 0) {
                        clearInterval(interval);
                        return 60;
                    }
                    return prev - 1;
                });
            }, 1000);
            return () => clearInterval(interval);
        }, []);
    
    
        return (
            <div className="card_form">
                <div className="form-group">
                
                <p>{t("store.otp.info")} {method === "PhoneNumber" ? user.phoneNumber : user.email}</p>
                    <div className="my-3 otp_inputs">
                    {otp.map((digit, index) => (
            <input
            //   autoFocus={index === 0 ? true : undefined}
            autoFocus={index === 0 ? true : undefined}
              key={index}
              type="number"
              className="form-control"
              maxLength={1}
              value={digit}
              ref={inputRefs.current[index]}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onChange={(e) => handleOtpChange(index, e.target.value)}
            />
          ))}
                
                    </div>
                </div>
                <p className="text-danger">{error}</p>
                <div className="row my-4">
                    {/* <div className="col-md-6">
                        <Button inner={
                            (counter !== 60 ? counter.toString()+ " "+ t("store.otp.s_to")
                            : "")+ " "+ t("store.otp.resend")}
                             color="secondary" isfullWidth isDisabled={counter!==60}
                         onClick={()=>{
                            Resend("PhoneNumber");
                            const interval = setInterval(() => {
                                setCounter((prev) => {
                                    if(prev === 0) {
                                        clearInterval(interval);
                                        return 60;
                                    }
                                    return prev - 1;
                                });
                            }, 1000);
                            return () => clearInterval(interval);
                            }}
                            />
                    </div>
                    <div className="col-md-6">
                        <Button inner={t("store.otp.to_email")}
                         color="secondary" isfullWidth onClick={()=>{
                            setOtpType("Email");
                            Resend("Email");
                        }} />
                    </div> */}
                </div>
                <Button inner={t("store.otp.submit")}
                 color="primary" isfullWidth onClick={
                    () => {
                        const otpValue = otp.join("");
                        verify(otpValue);
                    }
                 }
                 isLoading={loading} />
            </div>
        );
    
    }
export default Login;
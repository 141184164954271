import { useTranslation } from 'react-i18next';
import './partners.css';

const Partners = () => {
    const {t, i18n} = useTranslation();
    return (
        <div className="partners mt-5 mt-lg-0">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                        <h5 className='c-primary py-1'>{t("landing.section5.title")}</h5>
                        <h2>{t("landing.section5.sub_title")}</h2>
                    </div>
                </div>
                    <div className='py-3 my-3 py-lg-3 my-lg-3' style={{maxWidth: 700, margin: "auto"}}>
                       <img src="./media/landing/partners/1.png" className='w-100' />
                    </div>
            </div>
        </div>
    )
}

export default Partners;
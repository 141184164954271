import Axios from "axios";
import { REACT_APP_API_ESIM } from '../../config'

const getOrders = async (lang: string) => {
    const user = JSON.parse(localStorage.getItem('tygo_user') || '{}');
    if (user) { 
        const orders = await Axios.get(
            REACT_APP_API_ESIM + "/api/Order/customer?offset=1&limit=300",
            {
            headers: {
                accept: "text/plain",
                "Accept-Language": lang,
                authorization: "Bearer " + user.token,
            }
            }
        );
        return orders;
    }
    else {
        return null;
    }
}

const createOrder = async (hyperUrl: any, lang: string) => {
    const user = JSON.parse(localStorage.getItem('tygo_user') || '{}');
    const data = {
        // userId: user.userId,
        hyperpayCheckoutId: hyperUrl,
        phoneNumber: user.phoneNumber+"",
        countryCode: user.countryCode+"",
        entityType: "VISA"
    }
    if (user) {
        const order = await Axios.post(
            REACT_APP_API_ESIM + "/api/Order/v2/multi-product",

            data,
            {
            headers: {
                accept: "text/plain",
                "Accept-Language": lang,
                authorization: "Bearer " + user.token,
            }
        }
        );
        return order;
    }
    else {
        return null;
    }
}

const getOrderById = async (id: number, lang: string) => {
    const user = JSON.parse(localStorage.getItem('tygo_user') || '{}');
    if (user) {
        const order = await Axios.get(
            REACT_APP_API_ESIM + "/api/Order/" + id + "/customer",
            {
            headers: {
                accept: "text/plain",
                "Accept-Language": lang,
                authorization: "Bearer " + user.token,
            }
            }
        );
        return order;
    }
    else {
        return null;
    }
}







export {
  getOrders,
  createOrder,
    getOrderById
};

import Axios from "axios";
import { REACT_APP_API_PROVIDERS } from '../../config'

const getFilterPlans = async (data: any, lang: string) => {
  const plans = await Axios.post(
    // "https://test.providers.tygo.net/api/plan/Web/pagination",
    REACT_APP_API_PROVIDERS + "/api/plan/Web/pagination",
    // "https://test.providers.tygo.net" + "/api/plan/Web/pagination",
    data,
    {
      headers: {
        accept: "text/plain",
        "Accept-Language": lang,
      }
    }
    
  );

  return plans.data;
}

const getPlans = async (data: any, lang: string) => {
  
  const plans = await Axios.get(
    REACT_APP_API_PROVIDERS + "/api/plan/customer/esim/pagination",
    // "https://test.providers.tygo.net" + "/api/plan/customer/esim/pagination",
    {
      params: data,
      headers: {
        accept: "text/plain",
        "Accept-Language": lang,
      }
    }
  );

  return plans.data;
}

const getPlanById = async (id: number, lang: string) => {
  const plan = await Axios.get(
    REACT_APP_API_PROVIDERS + "/api/plan/" + id + "/customer",
    // "https://test.providers.tygo.net" + "/api/plan/" + id + "/customer",
    {
      headers: {
        accept: "text/plain",
        "Accept-Language": lang,
      }
    }
  );
    
  return plan.data;
}





export {
  getFilterPlans,
  getPlans,
  getPlanById
};
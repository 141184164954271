import Header from "../../components/header/header";
import '../about/about.css'
import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import TermsAr from "./termsAr";
import TermsEn from "./termsEn";

const Terms = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isHeadless = searchParams.get('headless');

    return <div >
        <div className="container about mb-5" >
    {
                isHeadless !== 'true' &&
                <Header/>
        
    }
   <TermsInner/>
   
</div>
    <Footer/>
</div>

}

export const TermsInner = () => {
    const { i18n} = useTranslation();
    return  (
        i18n.language === "ar" ?
    <div style={{
        maxWidth: "900px",
        margin: "auto",
        marginTop: "50px",
    }} className="terms"
    >
        <div className="wpb_wrapper">
        <p>&nbsp;</p>
        <h1 style={{textAlign: "center"}}><b>مرحبًا بكم في تطبيق “تايقو”</b></h1>
        <p>&nbsp;</p>
        <TermsAr/>
		</div>
</div>:
<div style={{
        maxWidth: "900px",
        margin: "auto",
        marginTop: "50px",
        
    }} className="terms"
    >
        <div className="wpb_wrapper">
			<h2 style={{textAlign: "center"}}><span style={{fontWeight: "400"}}>Welcome to the</span><b> “<span style={{color: "#3eb6ae"}}>Tygo</span>” </b><span style={{fontWeight: "400"}}>Application</span></h2>
            <p>&nbsp;</p>
            <h2 style={{textAlign: "center"}}><span style={{fontWeight: "400"}}>Terms of Use</span></h2>
            
            <p>&nbsp;</p>
            <TermsEn/>
		</div>
</div>
    )
}

export default Terms;



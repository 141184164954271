import Header from '../../components/header/header';
import './about.css';
import './services.css';
import Animate from './Animate';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/footer/footer';
import { useLocation } from 'react-router-dom';

const Services = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isHeadless = searchParams.get('headless');

    const {t, i18n} = useTranslation();
    return (
        <>
        <div className="about">
            <div className="container">
{
            isHeadless !== 'true' &&
            <Header/>
    
}    </div>
    <div className="theApp-body about" style={{zIndex: 0, position: 'relative'}}>
        <div className="container">
			<div className="vc_row wpb_row vc_row-fluid"><div className="wpb_column vc_column_container vc_col-sm-12"><div className="vc_column-inner"><div className="wpb_wrapper">
            <Animate delay={0.1}>
            <section className="appSection sal-animate" data-sal="slide-up" data-sal-delay="100"><img className="appSection-bg" src="1-bg.1973415f.png" srcSet="./media/static/services/1-bg.1973415f.png" alt=""/>
                <div className="container">
                    <div className="align-items-center gx-lg row padabout">
                        <div className="col-lg-6">
                            <Animate delay={0.3}>
                            <h3 className="fs-xl mb-e5 sal-animate" data-sal="slide-up" data-sal-delay="300">{t("services.element1.title")}</h3>
                            </Animate>
                            <Animate delay={0.4}>
                            <p className="fs-md mb-0 sal-animate" data-sal="slide-up" data-sal-delay="400">{t("services.element1.sub_title")}</p>
                            </Animate>
                        </div>
                        <div className='col-6 order-lg'>

                        <Animate delay={0.2}>
                        <div className="sal-animate" id="picres" data-sal="slide-up" data-sal-delay="200"><img className="img-fluid w-100" src="1.2c48fca9.png" srcSet="./media/static/services/1@2x.fdd85713-1.png" alt=""/></div>
                        </Animate>
                        </div>
                    </div>
                </div>
            </section>
            </Animate>
            <Animate delay={0.1}>
            <section className="appSection pt-xxl sal-animate" data-sal="slide-up" data-sal-delay="100"><img className="appSection-bg" src="2-bg.08271159.png" srcSet="./media/static/services/2-bg.08271159.png" alt=""/>
                <div className="container">
                    <div className="align-items-center gx-lg row padabout">
                        <div className='col-6 order-lg'>
                        <Animate delay={0.2}>
                        <div className=" sal-animate" id="picres" data-sal="slide-up" data-sal-delay="200"><img className="img-fluid w-100" src="2.4382b7b1.png" srcSet="./media/static/services/2@2x.a26eed0f-1.png" alt=""/></div>
                        </Animate>
                        </div>
                        <div className="col-lg-6">
                            <Animate delay={0.3}>
                            <h3 className="fs-xl mb-e5 sal-animate" data-sal="slide-up" data-sal-delay="300">{t("services.element2.title")}</h3>
                            </Animate>
                            <Animate delay={0.4}>
                            <p className="fs-md mb-0 sal-animate" data-sal="slide-up" data-sal-delay="400">{t("services.element2.sub_title")}</p>
                            </Animate>
                        </div>
                    </div>
                </div>
            </section>
            </Animate>
            <Animate delay={0.1}>
            <section className="appSection pt-xxl sal-animate" data-sal="slide-up" data-sal-delay="100"><img className="appSection-bg" src="3-bg.56254665.png" srcSet="./media/static/services/3-bg.56254665.png" alt=""/>
                <div className="container">
                    <div className="align-items-center gx-lg row padabout">
                        <div className="col-lg-6">
                            <Animate delay={0.3}>
                            <h3 className="fs-xl mb-e5 sal-animate" data-sal="slide-up" data-sal-delay="300">{t("services.element3.title")}</h3>
                            </Animate>
                            <Animate delay={0.4}>
                            <p className="fs-md mb-0 sal-animate" data-sal="slide-up" data-sal-delay="400">{t("services.element3.sub_title")}</p>
                            </Animate>
                        </div>
                        <div className='col-6 order-lg'>
                        <Animate delay={0.2}>
                        <div className=" sal-animate" id="picres" data-sal="slide-up" data-sal-delay="200"><img className="img-fluid w-100" src="3.f1e12d5f.png" srcSet="./media/static/services/3@2x.403e8137-1.png" alt=""/></div>
                        </Animate>
                        </div>
                    </div>
                </div>
            </section>
            </Animate>
            <Animate delay={0.1}>
            <section className="appSection pt-xxl pb-xxl sal-animate" data-sal="slide-up" data-sal-delay="100"><img className="appSection-bg" src="4-bg.8e72bc32.png" srcSet="./media/static/services/4-bg.8e72bc32.png" alt=""/>
                <div className="container">
                    <div className="align-items-center gx-lg row padabout">
                        <div className='col-6 order-lg'>
                        <Animate delay={0.2}>
                        <div className=" sal-animate" id="picres" data-sal="slide-up" data-sal-delay="200"><img className="img-fluid w-100" src="4.60766adf.png" srcSet="./media/static/services/4@2x.8b1f0531-1.png" alt=""/></div>
                        </Animate>
                        </div>
                        <div className="col-lg-6">
                            <Animate delay={0.3}>
                            <h3 className="fs-xl mb-e5 sal-animate" data-sal="slide-up" data-sal-delay="300">{t("services.element4.title")}</h3>
                            </Animate>
                            <Animate delay={0.4}>
                            <p className="fs-md mb-0 sal-animate" data-sal="slide-up" data-sal-delay="400">{t("services.element4.sub_title")}</p>
                            </Animate>
                        </div>
                    </div>
                </div>
            </section>
            </Animate>
			</div></div></div></div>
            </div>

            <div className="vc_row wpb_row vc_row-fluid"><div className="wpb_column vc_column_container vc_col-sm-12"><div className="vc_column-inner"><div className="wpb_wrapper">
            <Animate delay={0.1}>
            <section className="intro sal-animate" data-sal="slide-down" data-sal-duration="1000"><img className="intro-bg" src="./media/static/services/1.5c87c69b.png" srcSet="./media/static/services/1.5c87c69b.png" alt=""/>
                <div className="container">
                    <div className="max-md mx-auto text-center">
                        <h1 className="fs-xxxl mb-e8 sal-animate" data-sal="slide-down" data-sal-delay="500">{t('services.title')}</h1>
                        <Animate delay={0.5}>
                        <div data-sal="slide-down" data-sal-delay="500" className="sal-animate">
                            <p className="fs-lg mb-md opacity-50">{t('services.sub_title')}</p>
                        </div>
                        </Animate>
                        <Animate delay={0.7}>
                        <ul className="appButtons sal-animate" data-sal="slide-down" data-sal-delay="700">
                            <li style={{zIndex: 99}}><a href="https://play.google.com/store/apps/details?id=app.alo.sa" target="blank" title="Google Play"><img src="./media/static/services/google-play.svg" alt="Google Play"/></a></li>
                            <li style={{zIndex: 99}}><a href="https://apps.apple.com/us/app/alo-sa/id1584809412" target="blank" title="App Store"><img src="./media/static/services/app-store.svg" alt="App Store"/></a></li>
                        </ul>
                        </Animate>
                    </div>
                </div>
            </section>
            </Animate>
			</div></div></div></div>
</div>
</div>
<Footer/>
        </>
    )
    }

export default Services;

import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CompareCardContext } from "../../../components/compare/CompareCard";
import Dragable from "../../../components/compare/Dragable";
import Button from "../../../components/design/buttons/Button";
import Card from "../../../components/design/cards/card";
import Footer from "../../../components/footer/app_footer";
import { getComparePlans } from "../../../services/compare.service";
import { getPlanbyId } from "../../../services/stepper.service";
import ProgressBar from "../../stepper/components/ProgressBar";
import PlanBar from "../components/planBar";
import PlanHeader from "../components/planHeader";
import './compare.css';

// HiOutlineUpload
import { HiOutlineUpload } from 'react-icons/hi';
// GrFormAdd
import { GrFormAdd } from 'react-icons/gr';
import { RiCloseCircleFill } from 'react-icons/ri';
import { RiCheckboxCircleFill} from 'react-icons/ri';
import { getProviders } from "../../../services/providers.service";
// HiGlobeAlt
import { FiGlobe } from 'react-icons/fi';
// FaRegEnvelope
import { FaRegEnvelope } from 'react-icons/fa';
// IoCallOutline
import { HiOutlinePhone } from 'react-icons/hi';
// HiOutlineFilter
import { HiOutlineFilter } from 'react-icons/hi';

// BiChevronDown
import { BiChevronDown } from 'react-icons/bi';

// IoClose
import { IoClose } from 'react-icons/io5';
import { toast } from "react-toastify";
import Header from "../../../components/header/header";
import DownloadSection from "../../landing/components/download";

const CompareScreen = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [lastdata, setLastData] = React.useState<any>([]);
    const [dataHeader, setDataHeader] = React.useState<any>(null);
    const id = useParams().id;
    const {addToCart,plans} = useContext(CompareCardContext);
    const [plansData, setPlansData] = React.useState<any>([]);
    const [plansRData, setPlansRData] = React.useState<any>([]);
    const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);
    const titles = [
        {
            icon: "/media/svgs/sim_.svg",
            title: t("compare.sim"),
            key: "type_"
        },
        {
            icon: "/media/svgs/phone_.svg",
            title: t("compare.calls_inside"),
            key: "calls"
        },
        {
            icon: "/media/svgs/phone_.svg",
            title: t("compare.calls_other"),
            key: "localCalls"
        },
        {
            icon: "/media/svgs/phone_.svg",
            title: t("compare.calls_after"),
            key: "callsAfterFreeMinutes"
        },
        {
            icon: "/media/svgs/globe_.svg",
            title: t("compare.data"),
            key: "data"
        },
        {
            icon: "/media/svgs/mail.svg",
            title: t("compare.messages"),
            key: "messages"
        },
        // {
        //     icon: "/media/svgs/mail.svg",
        //     title: t("compare.message_other"),
        //     key: "messagesOtherProvider"
        // },
        // {
        //     icon: "/media/svgs/mail.svg",
        //     title: t("compare.message_after"),
        //     key: "messagesAfterFreeMinutes"
        // },
        {
            icon: "/media/svgs/clock.svg",
            title: t("compare.validity"),
            key: "validityTimeInDays"
        },
        

    ]

    const titlesMobile = [
        {
            title: t("compare.name"),
            key: "name"
        },
        {
            title: t("compare.contract"),
            key: "contract"
        },
        {
            title: t("compare.sim"),
            key: "sim"
        },
        {
            title: t("compare.validity"),
            key: "validityTimeInDays"
        },
        {
            title: t("compare.calls_inside"),
            key: "calls"
        },
        {
            title: t("compare.calls_other"),
            key: "localCalls"
        },
        {
            title: t("compare.calls_after"),
            key: "callsAfterFreeMinutes"
        },
        {
            title: t("compare.data"),
            key: "data"
        },
        {
            title: t("compare.data_after"),
            key: "dataAfterFreeMinutes"
        },
        {
            title: t("compare.social"),
            key: "isSupportSocial"
        },
        {
            title: t("compare.five"),
            key: "isSupportFiveG"
        },
        {
            title: t("compare.message_inside"),
            key: "messages"
        },
        {
            title: t("compare.message_other"),
            key: "messagesOtherProvider"
        },
        {
            title: t("compare.message_after"),
            key: "messagesAfterFreeMinutes"
        },
        


    ]
    const copyToClipboard = (text: string) => {
        const output = plans.map((item:any) => item.id).join(".");
        navigator.clipboard.writeText(text+'/'+output);
        toast.success(t("global.copied_to_clipboard"));
    }

    const feachData = async () => {
        const response = await getComparePlans(
            id ?
            id.split(".").map((item:any) => parseInt(item))
            :
            plans.map((item:any) => item.id)
        , i18n.language);
        const providers = (await getProviders(i18n.language)).returnData;
        try {
            const tempPlans = response.returnData.selectedProviderPlan.map((item:any) => {
                return {
                    id: item.id,
                    name: item.name,
                    image: item.imageUrl,
                    title: item.name,
                    type: item.planTypeName,
                    contractType: item.contractTypeName,
                    type_: item.simTypeName,
                    price: item.price,
                    messages: item.smSForCurrentProvider  != undefined ? item.smSForCurrentProvider == -1 ? t("plans.unlimited") : item.smSForCurrentProvider + " "+ t("plans.messages") : t("plans.na"),
                    // messagesAfterFreeMinutes: item.smSAfterFreeMinutes != undefined ? item.smSAfterFreeMinutes == -1 ? t("plans.unlimited") : item.smSAfterFreeMinutes + " "+ t("plans.messages")  : t("plans.na"),
                    // messagesOtherProvider: item.smSForOtherProvider != undefined ? item.smSForOtherProvider == -1  ? t("plans.unlimited") : item.smSForOtherProvider + " "+ t("plans.messages")  : t("plans.na"),
                    localCalls: item.minutesInSideProvider != undefined ? item.minutesInSideProvider == -1 ? t("plans.unlimited") : item.minutesInSideProvider + " "+ t("plans.minutes"): t("plans.na"),
                    calls: item.minutesOutSideProvider != undefined ? item.minutesOutSideProvider == -1 ? t("plans.unlimited") : item.minutesOutSideProvider + " "+ t("plans.minutes"): t("plans.na"),
                    callsAfterFreeMinutes: item.localCallsAfterFreeMinutes != undefined ? item.localCallsAfterFreeMinutes == -1 ? t("plans.unlimited") : item.localCallsAfterFreeMinutes + " "+ t("plans.minutes"): t("plans.na"),
                    validityTimeInDays: item.validityTimeInDays != undefined ? item.validityTimeInDays == -1 ? t("plans.unlimited") : item.validityTimeInDays +   " " + t("plans.days") : t("plans.na"),
                    data: item.localInternt != undefined ? item.localInternt == -1 ? t("plans.unlimited") : item.localInternt/1000 + " "+ t("plans.gb") : t("plans.na"),
                    isSupportFiveG: item.isSupportFiveG ? <RiCloseCircleFill color="#F75555" size={24}  /> : <RiCheckboxCircleFill color="#3EB6AE" size={24}  />,
                    sim: item.simType ? item.simType : t("plans.na"),
                    contract: item.contractType ? item.contractType : t("plans.na"),
                    dataAfterFreeMinutes: item.localInterntAfterFreeMinutes != undefined ? item.localInterntAfterFreeMinutes == -1 ? t("plans.unlimited") : item.localInterntAfterFreeMinutes/1000 + " "+ t("plans.gb") : t("plans.na"),
                    isSupportSocial: item.isSupportSocial ? <RiCloseCircleFill color="#F75555" size={24} /> : <RiCheckboxCircleFill color="#3EB6AE" size={24} />,
                    color: providers.find((provider:any) => provider.id == item.providerId).primaryColor,
                }
            })
            console.log(tempPlans);
    
            const a = response.returnData.recommendedProviderPlan.map((plan: any) => {
                return {
                    id: plan.id,
                    name: plan.name,
                    image: plan.imageUrl,
                    title: plan.name,
                    type: plan.contractTypeName,
                    contractType: plan.contractTypeName,
                    price: plan.price.toLocaleString() + t("plans.sar"),
                    messages: plan.smSForCurrentProvider == -1 ? t("plans.unlimited")
                     : (plan.smSForCurrentProvider).toLocaleString() + " "+ t("plans.minutes"),
                    internet: plan.localInternt == -1 ? t("plans.unlimited") : (plan.localInternt/1000).toLocaleString() +" "+ t("plans.gb"),
                    calls: plan.minutesInSideProvider == -1 ? t("plans.unlimited") : plan.minutesInSideProvider  + " "+ t("plans.minutes"),
                }
            });
            
    
    
            if (response) {
                setPlansData(tempPlans);
                setPlansRData(a);
            }
        } catch (error) {
            console.log(error);
        }

        // get the container position and add padding to the recomended plans
        const container = document.getElementsByClassName("container")[0];
        if (container) {

            const containerPosition = container.getBoundingClientRect();
            const recomendedPlans = document.getElementById("recomendedPlans");
            const comparePlans = document.getElementById("comparePlans");
            if (recomendedPlans && comparePlans) {
                // .plans-recommendation>h5 {
                //     /* padding-left: 252px; */
                // }

                // .plans-recommendation>.d-flex {
                //     padding-left: 252px;
                //     overflow-x: scroll;
                // }
                
                //get the first h5 element and add padding to it
                const h4 = recomendedPlans.getElementsByTagName("h4")[0];

                if (h4 && i18n.language != "ar") 
                    h4.style.paddingLeft = containerPosition.left + "px";
                else if (h4 && i18n.language == "ar") {
                    h4.style.paddingRight = containerPosition.left + "px";
                }
                //get the first div element and add padding to it
                const div = recomendedPlans.getElementsByTagName("div")[0];
                
                if (div && i18n.language != "ar") {
                    div.style.paddingLeft = containerPosition.left + "px";
                    div.style.overflowX = "scroll";
                } else if (div && i18n.language == "ar") {
                    div.style.paddingRight = containerPosition.left + "px";
                    div.style.overflowX = "scroll";
                }
                // //get the first div element and add padding to it
                // const div2 = comparePlans.getElementsByTagName("div")[0];

                // if (div2) {
                //     div2.style.paddingLeft = containerPosition.left + "px";
                //     div2.style.overflowX = "scroll";
                // }

                // first-column

                // .first-column {
                //     padding-left: 
                // }

                const style = document.createElement('style');
                // style.innerHTML = `
                //     .first-column {
                //         min-width: ${containerPosition.left+ 32 + 326}px !important; 
                //     }
                // `;
                style.innerHTML =  `
                    .first-column {
                        min-width: ${containerPosition.left+ 32 + 326}px !important;
                    }
                `;

                style.innerHTML += i18n.language == "ar" ? `
                .plans-comparator .first-column span {
                    float: left;
                }`:
                `
                .plans-comparator .first-column span {
                    float: right;
                }
                `;
                
                
                document.head.appendChild(style);





            }
        
        }
                
            



    }

    useEffect(() => {
        feachData();
    }, [plans]);

    const removePlan = (id: number) => {
        const tempPlans = plansData.filter((plan: any) => plan.id != id);
        setPlansData(tempPlans);
    }



    if(!plansData) return (
    <div>
        {/* Loading... */}

    </div>
    )   
    if(isMobile)
        return (
            <div className="recommended-plans">
            <div className=" my-4">
                <div className=" my-4">
                    <div className="d-flex align-items-center">
                        <Button color="circle "   isfullWidth={false} onClick={()=>{
                        navigate(-1);
                    }} inner={
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: i18n.language == "ar" ? "rotate(180deg)" : ""}}>
                    <path d="M15.95 31.3669C16.2666 31.3669 16.5833 31.2502 16.8333 31.0002C17.3166 30.5169 17.3166 29.7169 16.8333 29.2335L7.59997 20.0002L16.8333 10.7669C17.3166 10.2835 17.3166 9.48353 16.8333 9.0002C16.35 8.51686 15.55 8.51686 15.0666 9.0002L4.94996 19.1169C4.46663 19.6002 4.46663 20.4002 4.94996 20.8835L15.0666 31.0002C15.3166 31.2502 15.6333 31.3669 15.95 31.3669Z" fill="#292D32"/>
                    <path d="M6.1165 21.25H34.1665C34.8498 21.25 35.4165 20.6833 35.4165 20C35.4165 19.3167 34.8498 18.75 34.1665 18.75H6.1165C5.43317 18.75 4.8665 19.3167 4.8665 20C4.8665 20.6833 5.43317 21.25 6.1165 21.25Z" fill="#292D32"/>
                    </svg>
                    }/>
                        <strong className="mx-3">{t("global.back")} </strong>
                    </div>
                   <div className="p-4">
                   <div className="mb-3">
                    <Button inner=
                {
                    <div className="d-flex align-items-center justify-content-center">
                        {/* <i className="fas fa-share-alt"></i> */}
                        <HiOutlineUpload style={{fontSize: "1.5rem"}} />
                        <p className="mx-3 my-0">{t("details.share")}</p>
                    </div>
                } onClick={ ()=> {
                    copyToClipboard(window.location.href.split('compare')[0]+"compare");
                    } } color="outline" isfullWidth={true} />
                    </div>
                    <Button inner={
                         <div className="m-3 link d-flex align-items-center justify-content-center" 
                          style={{cursor: "pointer" , color: "#3EB6AE"}} >
                            {t("compare.advanced_comparison")} <div className="mx-2"></div>
                         <i className="fas fa-chevron-right"
                        style={{transform: i18n.language == "ar" ? "rotate(180deg)" : ""}}></i>
                       </div>
                    }
                    onClick={()=>{
                    navigate("/compare_");
                }} color="outline" isfullWidth={true} />

                  
                    
                   </div>

                </div>
            
                <div className="plans-comparator" id="comparePlans">
                    
                    <table className="table_comp">
                    <thead>
                        <tr>
                        {/* <th>Header 1</th>
                        <th>Header 2</th>
                        <th>Header 3</th>
                        <th>Header 4</th>
                        <th>Header 5</th> */}
                        <th>
                        <Link to="/plans" style={{textDecoration: "none", color: "inherit"}}>
                        <div className=" d-flex align-items-center justify-content-center flex-column">
                            <GrFormAdd size="2rem" color="#3EB6AE" />
                            <p className="text-center mt-3" >{t("compare.add_new_pakage")}</p>
                        </div>
                        </Link>
                        </th>
                        {plansData.map((plan: any, index: number) => {
                            return (
                                // card component
                                <th>
                                    <Card inner={
                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                            <IoClose size="1.5rem"  onClick={() => {
                                                removePlan(plan.id);
                                            }} style={{position: "absolute", top: "8px", left: "5px", cursor: "pointer"}} />
                                            <img src={plan.image} alt="" width="50" height="50" style={{borderRadius: "12px"}} className="mt-3" />
                                            <strong className="mt-3 mb-0" style={{color: "#3EB6AE"}}>
                                                {plan.price} {t("global.sar")}
                                            </strong>
                                        </div>
                                    } className={"px-0 py-3 position-relative"} />
                                </th>


                            )
                        })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="group-row">
                        <td className="group-label ps-3" colSpan={1}>{t("compare.info")}</td>
                        <td className="" colSpan={plansData.length}></td>
                        </tr>
                        {titlesMobile.slice(0, 4).map((title: any, index: number) => {
                            return  (
                            <tr>
                                <td className="ps-3">{title.title}</td>
                                {
                                    plansData.map((plan: any, index: number) => {
                                        return (
                                            <td style={{textAlign: "center", color: plan['color']}
                                        }>{plan[title.key]}</td>
                                        )
                                    })

                                }
                            </tr>
                            )}
                        )}
                        <tr className="group-row">
                        <td className="group-label ps-3" colSpan={1}>{t("compare.calls")}</td>
                        <td className="" colSpan={plansData.length}></td>
                        </tr>
                        {titlesMobile.slice(4, 7).map((title: any, index: number) => {
                            return  (
                            <tr>
                                <td className="ps-3">{title.title}</td>
                                {
                                    plansData.map((plan: any, index: number) => {
                                        return (
                                            <td style={{textAlign: "center", color: plan['color']}}>{plan[title.key]}</td>
                                        )
                                    })

                                }
                            </tr>
                            )}
                        )}
                        <tr className="group-row">
                        <td className="group-label ps-3" colSpan={1}>{t("compare.data")}</td>
                        <td className="" colSpan={plansData.length}></td>
                        </tr>
                        {titlesMobile.slice(7, 11).map((title: any, index: number) => {
                            return  (
                            <tr>
                                <td className="ps-3">{title.title}</td>
                                {
                                    plansData.map((plan: any, index: number) => {
                                        return (
                                            <td style={{textAlign: "center", color: plan['color']}}>{plan[title.key]}</td>
                                        )
                                    })

                                }
                            </tr>
                            )}
                        )}

                        <tr className="group-row">
                        <td className="group-label ps-3" colSpan={1}>{t("compare.messages")}</td>
                        <td className="" colSpan={plansData.length}></td>
                        </tr>
                        {titlesMobile.slice(11, 14).map((title: any, index: number) => {
                            return  (
                            <tr>
                                <td className="ps-3">{title.title}</td>
                                {
                                    plansData.map((plan: any, index: number) => {
                                        return (
                                            <td style={{textAlign: "center", color: plan['color']}}>{plan[title.key]}</td>
                                        )
                                    })
                                    
                                }
                            </tr>
                            )}
                        )}



                    </tbody>
                    </table>



                    
                </div>
                <div className="p-4" id="recomendedPlans">
                    <span style={{fontWeight:"500", fontSize:"1.2rem"}}>{t("compare.recommended_plans")} </span>
                    <div className="mt-3">
                        {
                            plansRData.map((plan: any, index: number) => {
                                return (
                                    <div className="" key={index} id={"plan" + plan.id}>
                                        <Dragable plan={plan} >
                                    <Card
                                        inner={
                                            <div className="plan-card">
                                                <div className="d-flex justify-content-between mt-3" style={{minHeight: '90px'}}>
                                                    <div className="d-flex ">
                                                        <img className="" src={plan.image} alt={plan.name} width="44" height="44" style={{borderRadius: '6px'}} />
        
                                                        <div className="mx-3">
                                                            <strong>{plan.title}</strong>
                                                            <p>{plan.contractType}</p>
                                                        </div>
                                                    </div>
                                                    <strong style={{color: '#3EB6AE'}}>{plan.price} {plan.currency}</strong>
                                                </div>
                                                <div className="d-flex d-lg-block">
                                                        <div className="d-flex align-items-center my-3">
                                                            {/* <i className="fas fa-phone fa-1x mx-3" style={{color: '#3EB6AE'}}></i> */}
                                                            <HiOutlinePhone className="mx-3" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                                                            <div>
                                                                <p className="font-weight-light  m-0">{plan.calls}</p>
                                                                <p className="font-weight-light text-muted m-0">{t("plans.calls")}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-none d-lg-flex
                                                         align-items-center my-3">
                                                            {/* <i className="fas fa-comment fa-1x mx-3" style={{color: '#3EB6AE'}}></i> */}
                                                            <FaRegEnvelope className="mx-3" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                                                            <div>
                                                                <p className="font-weight-light m-0">{plan.messages}</p>
                                                                <p className="font-weight-light text-muted m-0">{t("plans.messages")}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center my-3">
                                                            {/* <i className="fas fa-globe fa-1x mx-3" style={{color: '#3EB6AE'}}></i> */}
                                                            <FiGlobe className="mx-3" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                                                            <div>
                                                                <p className="font-weight-light  m-0">{plan.internet}</p>
                                                                <p className="font-weight-light text-muted m-0">{t("plans.internet")}</p>
                                                            </div>
                                                        </div>
        
                                                </div>
                                                <div className="d-flex mt-4 d-lg-none"> 
                                                    <Button inner={t("plans.details")}
                                                    onClick={
                                                        () => navigate(`/plans/${plan.id}` )
                                                        // () => setSelectedPlan(plan)
                                                    } color="primary px-4" isrounded={true}/>
                                                    <div className="mx-1"></div>
                                                    <Button inner={t("plans.compare")}
                                                     onClick={function (): void {
                                                            addToCart(plan, 'plan'+plan.id);
                                                        } } color="secondary px-4" isrounded={true}/>
                                                </div>
                                                <div className="d-none d-lg-block">
                                                    <div className="row mt-4">
                                                            <div className="col-6 col-md-6 mb-1">
                                                                <Button inner={t("plans.details")}
                                                                    onClick={
                                                                    () => navigate(`/plans/${plan.id}` )
                                                                   // () => setSelectedPlan(plan)
                                                                } color="primary" isfullWidth={true} isrounded={true}/>
                                                            </div>
                                                            <div className="col-6 col-md-6 mb-1">
                                                                <Button inner={t("plans.compare")}
                                                                    onClick={function (): void {
                                                                        addToCart(plan, 'plan'+plan.id);
                                                                    } } color="secondary" isfullWidth={true} isrounded={true}/>
                                                            </div>
                                                    </div>
                                                    </div>
                                                </div>
                                        }
                                                    />
                                    </Dragable>
                                    </div>
                                )
                        }
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        )
    return (

        <div className="recommended-plans">
            
            <div className="container">
            <Header />
            </div>
            <div className=" my-4">
                <div className="d-flex container align-items-center justify-content-between my-4">
                    
                        
                    <div className="d-flex align-items-center">
                        <Button color="circle "   isfullWidth={false} onClick={()=>{
                        navigate(-1);
                    }} inner={
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: i18n.language == "ar" ? "rotate(180deg)" : ""}}>
                    <path d="M15.95 31.3669C16.2666 31.3669 16.5833 31.2502 16.8333 31.0002C17.3166 30.5169 17.3166 29.7169 16.8333 29.2335L7.59997 20.0002L16.8333 10.7669C17.3166 10.2835 17.3166 9.48353 16.8333 9.0002C16.35 8.51686 15.55 8.51686 15.0666 9.0002L4.94996 19.1169C4.46663 19.6002 4.46663 20.4002 4.94996 20.8835L15.0666 31.0002C15.3166 31.2502 15.6333 31.3669 15.95 31.3669Z" fill="#292D32"/>
                    <path d="M6.1165 21.25H34.1665C34.8498 21.25 35.4165 20.6833 35.4165 20C35.4165 19.3167 34.8498 18.75 34.1665 18.75H6.1165C5.43317 18.75 4.8665 19.3167 4.8665 20C4.8665 20.6833 5.43317 21.25 6.1165 21.25Z" fill="#292D32"/>
                    </svg>
                    } />
                        <strong className=" ms-3">{t("global.back")} </strong>
                    </div>
                   <div className="d-flex align-items-center justify-content-center">
                   {/* <strong className="m-3 link " style={{cursor: "pointer" , color: "#3EB6AE"}} 
                     onClick={()=>{
                        navigate("/compare_");
                    }}>{t("compare.advanced_comparison")} <i className="fas fa-chevron-right"
                     style={{transform: i18n.language == "ar" ? "rotate(180deg)" : ""}}></i>
                    </strong> */}
                    <div>
                    <Button inner=
                {
                    <div className="d-flex align-items-center justify-content-center">
                        {/* <i className="fas fa-share-alt"></i> */}
                        <HiOutlineUpload style={{fontSize: "1.5rem"}} />
                        <p className="mx-3 my-0">{t("details.share")}</p>
                    </div>
                } onClick={
                    () => {
                        copyToClipboard(window.location.href.split('compare')[0]+"compare");
                }} color="outline" isfullWidth={true} />
                    </div>
                   </div>

                </div>
            
                <div className="plans-comparator" id="comparePlans">
                    <div className="over_ h-100" style={{position: "absolute", background:i18n.language == "ar"? "linear-gradient(90deg, rgba(252,250,253,0) 0%, rgba(252,250,253,1) 23%)" : "linear-gradient(270deg, rgba(252,250,253,0) 0%, rgba(252,250,253,1) 23%)"}}>
                    <div className="first-row d-flex h-100">
                        <div className="element-plan column-title first-column h-100">
                            <div className="d-flex align-items-center h-100" style={{float: i18n.language == "ar" ? "left" : "right",
                             minWidth: "326px"}}>
                            <i className="fas fa-plus"></i>
                            <strong className="m-3"
                            onClick={()=>{
                                navigate("/plans");
                            }}
                            style={{cursor: "pointer"}}
                            >{t("compare.add_new_pakage")} </strong>
                            </div>
                        </div>
                        
                    </div>
                    </div>
                    <div className="first-row d-flex">
                        <div className="element-plan column-title first-column ">
                            <div className="d-flex align-items-center " style={{float: i18n.language == "ar" ? "left" : "right",
                             minWidth: "326px"}}>
                            <i className="fas fa-plus"></i>
                            <strong className="m-3"
                            onClick={()=>{
                                navigate("/plans");
                            }}
                            style={{cursor: "pointer"}}
                            >{t("compare.add_new_pakage")} </strong>
                            </div>
                        </div>
                        {plansData.map((plan: any, index: number) => {
                            return (
                                <div className="element-plan column-title" key={index}>
                                    <Card inner={
                                    <div className="d-flex align-items-start">
                                        <img src={plan.image
                                        } alt="" width="50" height="50" style={{borderRadius: "8px"}} />
                                        <div className="d-flex flex-column mx-3">
                                            <strong className="">{plan.title}</strong>
                                            <p className="text-muted m-0">{plan.contractType} | <span className="price">{plan.price} {t("plans.sar")} </span></p>
                                        </div>
                                    </div> } noBorder={true} />
                                </div>
                            )
                        })}
                    </div>
                    
                    
                    <div className="over_" style={{position: "absolute", background: i18n.language == "ar"?"linear-gradient(90deg, rgba(252,250,253,0) 0%, rgba(252,250,253,1) 23%)":"linear-gradient(270deg, rgba(252,250,253,0) 0%, rgba(252,250,253,1) 23%)"}}>
                        
                        {
                            titles.map((title: any, index: number) => {
                                return (
                                    <div className="next-row d-flex" key={index}>
                                        <div className="element-plan column-title first-column">
                                            <span className="d-flex align-items-center" style={{minHeight: "78px"}}>
                                                <img src={title.icon} />
                                                <span className="m-3">{title.title}</span>

                                            </span>
                                        </div>
                                    </div>
                                )
                            }
                            )
                        }
                    </div>
                    {titles.map((title: any, index_: number) => {
                        return (
                            <div className="next-row d-flex" key={index_}>
                                <div className="element-plan column-title first-column">
                                    {/* <span className="d-flex align-items-center" style={{minHeight: "78px"}}>
                                        <img src={title.icon} />
                                        <span className="m-3">{title.title}</span>

                                    </span> */}
                                </div>
                                {plansData.map((plan: any, index: number) => {
                                    return (
                                        // index%2 == 0 ? column-primary : column-secondary
                                        <div className={"element-plan" + (index_%2 === 0 ? " column-primary" : " column-secondary")} key={index}>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <p className="font-weight-light text-muted m-0">{plan[title.key]}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>

                <div className="plans-recommendation mt-5" id="recomendedPlans">
                    <h4 className="py-3"><strong >{t("compare.recommended_plans")} </strong> </h4>
                    <div className="d-flex">
                        {
                            plansRData.map((plan: any, index: number) => {
                                return (
                                    <div className="pe-3" key={index} id={"plan" + plan.id}>
                                        <Dragable plan={plan} >
                                    <Card
                                       inner={
                                        <div className="plan-card" style={{width: "300px"}}>
                                            <div className="d-flex justify-content-between mt-3" style={{minHeight: '90px'}}>
                                                <div className="d-flex ">
                                                    <img className="" src={plan.image} alt={plan.name} width="44" height="44" style={{borderRadius: '6px'}} />
    
                                                    <div className="mx-3">
                                                        <strong className="plan_title">{plan.title}</strong>
                                                        <p className="font-weight-light text-muted">{plan.type}</p>
                                                    </div>
                                                </div>
                                                <strong style={{color: '#3EB6AE', whiteSpace:"nowrap"}}>{plan.price} {plan.currency}</strong>
                                            </div>
                                            <div className="d-flex d-lg-block justify-content-between" style={{maxWidth: '300px'}}>
                                                    <div className="d-flex align-items-center my-3 ">
                                                        {/* <i className="fas fa-phone fa-1x mx-3" style={{color: '#3EB6AE'}}></i> */}
                                                        {/* <HiOutlinePhone className="mx-3" style={{color: '#3EB6AE', fontSize: '1.5rem'}} /> */}
                                                        <div className="me-3">                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z" stroke="#2F1952" stroke-width="1.5" stroke-miterlimit="10"/>
                                                            </svg>
                                                            </div>
    
                                                        <div>
                                                            <p className="font-weight-light  m-0">{plan.calls}</p>
                                                            <p className="font-weight-light text-muted m-0">{t("plans.calls")}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-none d-lg-flex
                                                     align-items-center my-3">
                                                        {/* <i className="fas fa-comment fa-1x mx-3" style={{color: '#3EB6AE'}}></i> */}
                                                        {/* <FaRegEnvelope className="mx-3" style={{color: '#3EB6AE', fontSize: '1.5rem'}} /> */}
                                                        
                                                        <div className="me-3">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#2F1952" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#2F1952" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                                    </div>
    
                                                        <div>
                                                            <p className="font-weight-light m-0">{plan.messages}</p>
                                                            <p className="font-weight-light text-muted m-0">{t("plans.messages")}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center my-3">
                                                        {/* <i className="fas fa-globe fa-1x mx-3" style={{color: '#3EB6AE'}}></i> */}
                                                        {/* <FiGlobe className="mx-3" style={{color: '#3EB6AE', fontSize: '1.5rem'}} /> */}
                                                        <div className="me-3">
    
                                                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M7.99998 3H8.99998C7.04998 8.84 7.04998 15.16 8.99998 21H7.99998" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M15 3C16.95 8.84 16.95 15.16 15 21" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M3 9.0001C8.84 7.0501 15.16 7.0501 21 9.0001" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                        </div>
    
                                                        <div>
                                                            <p className="font-weight-light  m-0">{plan.internet}</p>
                                                            <p className="font-weight-light text-muted m-0">{t("plans.internet")}</p>
                                                        </div>
                                                    </div>
    
                                            </div>
                                            <div className="d-flex mt-4 d-lg-none"> 
                                                <Button inner={t("plans.details")}
                                                onClick={
                                                    () => navigate(`/plans/${plan.id}` )
                                                    // () => setSelectedPlan(plan)
                                                } color="primary px-4" isrounded={false}/>
                                                <div className="mx-1"></div>
                                                <Button inner={t("plans.compare")}
                                                 onClick={function (): void {
                                                        addToCart(plan, 'plan'+plan.id);
                                                    } } color="primary-outline-primary px-4" isrounded={false}/>
                                            </div>
                                            <div className="d-none d-lg-block">
                                                <div className="row mt-4">
                                                        <div className="col-6 col-md-6 mb-1">
                                                            <Button inner={t("plans.details")}
                                                                onClick={
                                                                () => navigate(`/plans/${plan.id}` )
                                                               // () => setSelectedPlan(plan)
                                                            } color="primary" isfullWidth={true} isrounded={false} size="sm"/>
                                                        </div>
                                                        <div className="col-6 col-md-6 mb-1">
                                                            <Button inner={t("plans.compare")}
                                                                onClick={function (): void {
                                                                    addToCart(plan, 'plan'+plan.id);
                                                                } } color="primary-outline-primary"
                                                                isfullWidth={true} isrounded={false} size="sm"/>
                                                        </div>
                                                </div>
                                                </div>
                                            </div>
                                    }
                                                    />
                                    </Dragable>
                                    </div>
                                )
                        }
                        )}
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
            <DownloadSection />
        </div>
    );
    
};

export default CompareScreen;


import './error.css'

const Error = () => {
    return (
        <div id="notfound">
            <div className="notfound">
                <div className="notfound-404">
                    <h1>Oops!</h1>
                    <h2>We can't seem to find What you're looking for.</h2>
                </div>
                <a href="/">Please Try Again</a>
            </div>
        </div>
    )
}

export default Error;
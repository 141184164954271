import React, { useEffect } from "react";
import { useState } from "react";
import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Button, { ButtonSquare } from "../../../components/design/buttons/Button";
import './topics.css';
import Animate from "../../about/Animate";
import { use } from "i18next";
import { useNavigate } from "react-router-dom";
const Topics = () => {
    const {t, i18n} = useTranslation();

    const [topics, setTopics] = useState<any>([
       

    ])

    useEffect(() => {
        setTopics([
            {
                title: t("landing.section2.item1.title"),
                description: t("landing.section2.item1.sub_title"),
                icon: "icon-1",
                image: "6"
            },
            {
                title: t("landing.section2.item2.title"),
                description: t("landing.section2.item2.sub_title"),
                icon: "icon-2",
                image: "2"
            },
            {
                title: t("landing.section2.item3.title"),
                description: t("landing.section2.item3.sub_title"),
                icon: "icon-3",
                image: "5"
            },
            {
                title: t("landing.section2.item4.title"),
                description: t("landing.section2.item4.sub_title"),
                icon: "icon-4",
                image: "4"
            },
            {
                title: t("landing.section2.item5.title"),
                description: t("landing.section2.item5.sub_title"),
                icon: "icon-5",
                image: "3"
            },
            {
                title: t("landing.section2.item6.title"),
                description: t("landing.section2.item6.sub_title"),
                icon: "icon-6",
                image: "1"
            }
        ])
    }, [i18n.language])


    const [activeTopic, setActiveTopic] = useState(0);
    const [index, setIndex] = React.useState(0);
    const navigate = useNavigate();
    const handleSelect = (selectedIndex: any, e: any) => {
      setIndex(selectedIndex);
    };
    return (
        <>
        <Animate delay={0.1} >
            <div className="topics mb-5 mb-lg-5 my-2 py-2 my-lg-5 py-lg-5">
                <div className="text-center my-2 my-lg-5 mx-auto" style={{maxWidth: "920px"}}>
                    <Animate delay={0.2} >
                    <h5 className="c-primary">{t("landing.section2.title")}</h5>
                    </Animate>
                    <Animate delay={0.3} >
                    <h2>{t("landing.section2.sub_title")}</h2>
                    </Animate>
                </div>
                <div className="row mt-2 pt-2 mt-lg-5 pt-lg-5">
                    <div className="col-xl-6 col-lg-6  col-sm-12 position-relative d-none d-lg-block">
                        <Animate delay={0.4} >
                        {/* <img src={`media/landing/topics/bg.svg`} alt="bg" className="position-absolute" style={{top: "0", left: "-80px", zIndex: -1}} /> */}
                    <Carousel activeIndex={activeTopic} onSelect={handleSelect} controls={false} interval={null} indicators={false}>
                        {topics.map((inner: any, index: any) => {
                        return (
                            <Carousel.Item>
                                <img className="image_topic" src={`media/landing/topics/${inner.image + (i18n.language === "ar" ? "ar" : "")}.png`
                            } alt="bg" style={{margin: "auto", display: "block", width: "100%"}} />
                            </Carousel.Item>
                        )})}
                        </Carousel>
                        </Animate>
                    </div>
                    <div className="col-xl-6 col-lg-6  col-sm-12 d-none d-lg-block">
                        <div className="row">
                            {
                                topics.map((topic: any, index: any) => {
                                    return (
                                        <div className="col-xl-6 col-lg-6  col-6" key={index} onClick={() => setActiveTopic(index)} >
                                            <Animate delay={0.1} >
                                            <div className={`topic-box ${activeTopic === index ? "active" : ""}`}>
                                                <Animate delay={0.2} >
                                                <img src={`media/landing/topics/${topic.icon}.${activeTopic === index ? "gif" : "png"}`}
                                                 className="gif" alt="icon" width="32px" />
                                                </Animate>
                                                <Animate delay={0.3} >
                                                <strong className="mt-1 mb-2 d-block">{topic.title}</strong>
                                                </Animate>
                                                <Animate delay={0.4} >
                                                <p className="text-muted m-0" style={{fontSize: "16px"}}>
                                                    {topic.description}</p>
                                                </Animate>
                                            </div>
                                        </Animate>
                                        </div>
                                    )
                                })
                            }
                            <Animate delay={0.5} >
                            <div className="row my-4">
                                <div className="col-xl-6 col-lg-6  col-sm-12">
                                    <ButtonSquare inner={t("header.services")} color="primary" isfullWidth={true} isrounded={true} onClick={() => {
                                        navigate("/services")
                                    }} />
                                </div>
                            </div>
                            </Animate>
                        </div>

                    </div>
                    <div className="col-xl-6 col-lg-6  col-sm-12 d-block d-lg-none">
                        <div className="row p-3">
                            {
                                topics.map((topic: any, index: any) => {
                                    return (
                                        <div className="col-xl-6 col-lg-6  col-6 p-1" key={index} onClick={() => setActiveTopic(index)} >
                                            <div className={`topic-box active m-0`}>
                                                <img src={`media/landing/topics/${topic.icon}.${activeTopic === index ? "gif" : "png"}`} className="gif" alt="icon" width="32px" />
                                                <strong>{topic.title}</strong>
                                                <p>{topic.description}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                </div>
            </div>
        </Animate>
        </>
    )
}

export default  Topics;
import { useTranslation } from 'react-i18next';
import './midle.css'
import Animate from '../../about/Animate';

const Midle = () => {
    const {t, i18n} = useTranslation();
    return (
        <Animate delay={0.1}>
        <div className="midle_bg midle">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 d-flex align-items-center order-2 order-md-1 text-center text-md-start">
                        <div className="midle__content">
                            <Animate delay={0.1}>
                            <h2 className="midle__title">{t("esim.section3.title")}</h2>
                            </Animate>
                            <Animate delay={0.2}>
                            <p>{t("esim.section3.sub_title")}</p>
                            </Animate>
                            <Animate delay={0.3}>
                            <div className='devider'></div>
                            </Animate>
                            <Animate delay={0.4}>
                            <div className="d-flex download_btns">
                                <div className='m-2'> 

                                <a href="https://apps.apple.com/qa/app/tygo/id1619973559" target="_blank">
                            <img src="./media/buttons/apple.svg" width={144} />
                            </a>
                            </div>

                            <div className='m-2'>
                            <a href="https://play.google.com/store/apps/details?id=app.tygo.sa" target="_blank">
                            <img src="./media/buttons/google.svg" width={144} />
                            </a>                                </div>
                            </div>
                            </Animate>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 order-1 order-md-2">
                        <div className="midle__image">
                            <Animate delay={0.6}>
                            <img src="./media/midle/1.svg" width="100%" />
                            </Animate>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </Animate>
    );
};

export default Midle;
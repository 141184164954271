import Axios from "axios";
import { REACT_APP_API_ACCOUNTS } from '../../config'

const createAccount = async (data: any) =>{
  
  const account = await Axios.post(
    REACT_APP_API_ACCOUNTS + "/api/AuthControllers/Register",
    // "https://test.accounts.tygo.net/api/AuthControllers/Register",
    data,
  );
  return account;
}

const loginAccount = async (data: any) =>{
  const account = await Axios.post(
    REACT_APP_API_ACCOUNTS + "/api/AuthControllers/Login",
    // "https://test.accounts.tygo.net/api/AuthControllers/Login",
    data,
  );
  return account;
}

const verifyCode = async (number: any, otpType:string)=>{
  const user = JSON.parse(localStorage.getItem('tygo_user') || '{}');
  const account = await Axios.post(
    REACT_APP_API_ACCOUNTS + "/api/AuthControllers/VerifyCode",
    // "https://test.accounts.tygo.net/api/AuthControllers/VerifyCode",
    {
      "otpCode": number,
      "email": user.email,
      "phoneNumber": user.phoneNumber,
      "countryCode": user.countryCode,
      "deviceToken": "string",
      "otpType": otpType,
      "deviceType": "Web"
    }
  );
  return account;
}

const Logout = async (navigation: any) => {
  localStorage.removeItem('tygo_user');
  navigation('/login');
}


export {
  createAccount,
  loginAccount,
  verifyCode,
  Logout
};
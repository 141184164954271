import { useTranslation } from 'react-i18next';
import './card.css';

const Card = ({ inner,noBorder,className, noEdge, fullheight }: any) => {
    const {t, i18n} = useTranslation();
    
    return (
        <div className={"details-card-out mb-4" + (fullheight ? ' full-height' : '')}
         style={{backgroundImage: 
            i18n.language === 'en' ?
        'url(/media/top.svg)' : 'url(/media/top-ar.svg)'
        }}>
            <div className={(noEdge ? ' noEdge ' : (noBorder ? ' no-border ' : '')) + "details-card-in "  + (className ? ' ' + className : '') + (fullheight ? ' full-height' : '')}>
                {inner}
            </div>
        </div>
    );
};

export default Card;

import { useTranslation } from "react-i18next";
import Header from "../../components/header/header";
import './404.css';
const PageNotFound = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <Header />
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404">
                        <h1>{t("404.title")}</h1>
                        <h2>{t("404.description")}</h2>
                    </div>
                    <a href="/">{t("404.button")}</a>
                </div>
            </div>
        </div>
    );
    }

const ItemNotFound = ({title, description, button, onClick}: any) => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div id="notfound">
                <div className="notfound_ notfound">
                    <div className="notfound-404">
                        <h1>{title}</h1>
                        <h2>{description}</h2>
                    </div>
                    <a onClick={onClick}>{button}</a>
                </div>
            </div>
        </div>
    );
    }




export {ItemNotFound};

export default PageNotFound;

import Axios from "axios";
import { REACT_APP_API_AUCTIONS } from '../../config'

const GetAuctions = async (lang: string) => {
    const auctions = await Axios.get(
        REACT_APP_API_AUCTIONS + "/api/Items/GetGuestAuctions",
        {
        headers: {
            accept: "text/plain",
            "Accept-Language": lang,
        }
        }
    );
    return auctions;
}

const GetAuctionDetails = async (id: string, lang: string) => {
    const user = JSON.parse(localStorage.getItem('tygo_user') || '{}');
    const auction = await Axios.get(
        REACT_APP_API_AUCTIONS + "/api/Items/GetAuction?id=" + id,
        {
        headers: {
            accept: "text/plain",
            "Accept-Language": lang,
            authorization: "Bearer " + user.token,
        }
        }
    );
    return auction;
}

const GetAuctionBids = async (id: string, lang: string) => {
    const user = JSON.parse(localStorage.getItem('tygo_user') || '{}');
    const bids = await Axios.get(
        // GetAuctionBids?auctionId

        REACT_APP_API_AUCTIONS + "/api/Bids/GetAuctionBids?auctionId=" + id,
        {
        headers: {
            accept: "text/plain",
            "Accept-Language": lang,
            authorization: "Bearer " + user.token
        }
        }
    );
    return bids;
}

export {
    GetAuctions,GetAuctionDetails,GetAuctionBids
};

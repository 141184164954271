import Axios from "axios";
import { REACT_APP_API_PROVIDERS } from '../../config'


const getStepperData =  async (lang:string) => {
    const plans = await Axios.get(
      REACT_APP_API_PROVIDERS + "/api/provider/customer",
    {
      headers: {
        accept: "text/plain",
        "Accept-Language": lang,

    }
    }
    );

    const a = (await Axios.get(
      REACT_APP_API_PROVIDERS + "/api/Survey",
    //lang == "ar" ? { headers: { "Accept-Language": "ar" } } : {}
    {
      headers: {
        accept: "text/plain",
        "Accept-Language": lang,
      }
    }
    )).data.returnData.map((item:any) => {
      if(item.type === "api") {
        item.items = plans.data.returnData;
      }
      return item;
    });
    // a.push(
    //   {
    //     //input "What's your phone number?"
    //     title: lang == "ar" ? "ما هو رقم هاتفك؟" : "What's your phone number?",
    //     // hint: lang == "ar" ? "هذا هو التلميح للسؤال." : "This is the hint for the question.",
    //     type: "input",
    //     items: []
    //   }
    // );

        
    a.push(
      {
        // Thanks, we're finding the most matching plan for you..
        title: lang === "ar" ? "شكراً، نحن نجد الخطة المناسبة لك.." :
        "Thanks, we're finding the most popular choice for your answers..",
        hint: lang === "ar" ? "هذا هو التلميح للسؤال." : "This is the hint for the question.",
        type: "infolist",
        items: lang !== "ar" ? [
          ["Thanks, we'll find the most popular choice for your answers..", 400],
          ["We researched the best options for your Behaviors & Preferences..", 700],
          ["We researched the best options for your Calls...", 900],
          ["We researched the best options for the Internet..", 300],
          ["We're done, here is the best plan for you..", 700],
        ] : [
          ["شكرًا لك، من خلال أجوبتك سنبحث لك عن أفضل الخيارات..", 400],
          ["تم البحث عن أفضل الباقات لتفضيلاتك وسلوكياتك..",700] ,
          ["تم البحث عن أفضل الخيارات لمكالماتك ..", 900],
          ["تم البحث عن أفضل الخيارات للانترنت ", 300],
          ["انتهينا، سيتم عرض أفضل الباقات بالنسبة لك..",700]
        ]
          
      }
    );
    return a;

    
}

const sendDAta = (data:any,lang:string) => {
  // {
  //   "answers": [
  //     {
  //       "qustionKey": 0,
  //       "choices": [
  //         0
  //       ]
  //     }
  //   ],
  //   "notAccpteCompanies": [
  //     0
  //   ],
  //   "sortType": 0
  // }
  // answers = data - 2 last elements
  // notAccpteCompanies = pre last element
  // sortType = last element
  console.table(data);
  const h = data.map((d:any, i:number) => {
    if( d.value.length > 0 &&
      typeof(d.value[0]) === "string")
    return {
      "questionRefrence": d.questionRefrence,
      "choices": [0],
      "txtValue": d.value[0]
    }

    return {
      "questionRefrence": d.questionRefrence,
      "choices": d.value
    }
  })
  console.log(h);
  // const a = {
  //   "answers": h.slice(0, h.length - 2),
  //   "accpteCompanies": h[h.length - 2].choices,
  //   "sortType": h[h.length - 1].choices[0]
  // }

  return Axios.post(
    REACT_APP_API_PROVIDERS + "/api/Survey/answers"
    , h, {
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': lang
    }
  });

}

const getPlanbyId = (id:any,lang:string) => {
  return Axios.get(
    REACT_APP_API_PROVIDERS + `/api/plan/${id}/customer`,
     {
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': lang
    }
  });
      
}

export { getStepperData, sendDAta , getPlanbyId};
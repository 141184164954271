import './packs.css';

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/design/cards/card';
import Button, { ButtonSquare } from '../../../components/design/buttons/Button';
import Dragable from '../../../components/compare/Dragable';
import { CompareCardContext } from '../../../components/compare/CompareCard';
import { FiGlobe } from 'react-icons/fi';
import { FaRegEnvelope } from 'react-icons/fa';
import { HiOutlinePhone } from 'react-icons/hi';
import { HiOutlineFilter } from 'react-icons/hi';
import { BiChevronDown } from 'react-icons/bi';
import { getFilterPlans } from '../../../services/plans.service';
import Animate from '../../about/Animate';
import { Carousel } from 'react-bootstrap';
const Packs = () => {
    const [plans, setPlans] = useState([]);
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const fetchPlans = async () => {
            
            await getFilterPlans({
                "limit": 6,
                "callsMin": [],
                "mobileDate":[],
                "name": "",
                "priceFrom":0,
                "priceTo": 10000,
                "providerId": [],
                "contractTypeId": [],
                "orderBy": "",
                "offset": 1
              }, i18n.language).then((res) => {
                console.log(res);
                const data = res.returnData.map((plan: any) => { 
                    return {
                        id: plan.id,
                        name: plan.name,
                        image: plan.imageUrl,
                        title: plan.name,
                        type: plan.planTypeName,
                        price: plan.price.toFixed(1).toLocaleString(),
                        messages: plan.smSForCurrentProvider == -1 ? t("plans.unlimited") : (plan.smSForCurrentProvider).toLocaleString() + " " + t("plans.message"),
                        internet: plan.localInternt == -1 ? t("plans.unlimited") : (plan.localInternt/1000).toLocaleString() + " " + t("plans.gb"),
                        calls: plan.minutesInSideProvider == -1 ? t("plans.unlimited") : plan.minutesInSideProvider + " " + t("plans.minutes"),
                        contractType:plan.contractTypeName,
                        //two digit after decimal
                        newPrice: plan.discountRate === 0 ? plan.price : (plan.price - (plan.price * plan.discountRate)).toFixed(2),
                        hasDiscount: plan.discountRate === 0 ? false : true,
                        discount: '-' + plan.discountRate * 100 + '%',

                      }
                });


                setPlans(data);
            }
            );
        }
    

            


    useEffect(() => {
        fetchPlans();
    }, [i18n.language])
  return (
    <Animate delay={0.1} >
    <div className="packs my-0 py-0 my-lg-5 py-lg-5">
        <div className='container px-4'>
          
        <div className="row ">
        <div className="col-xl-6 col-lg-6 col-sm-12 col-12">   
              <div className=" my-5 mx-auto" style={{maxWidth: "920px"}}>
                  <Animate delay={0.2} >

                  <h5 className='c-primary' style={{fontSize:"1rem"}}
                    >{t("landing.section4.title")}</h5>
                  </Animate>
                  <Animate delay={0.3} >
                  <h1 className='my-3' style={{fontSize:"2rem", lineHeight:"2.8rem"}}>{t("landing.section4.sub_title")}</h1>
                  </Animate>
                  <Animate delay={0.4} >
                  <p>{t("landing.section4.sub_title2")}</p>
                  </Animate>
                  <div className="d-none d-lg-block">
                    <Animate delay={0.5} >
                  {/* <List plans={plans} /> */}
                  <Slider plans={
                    plans.length === 0 ? [[], [], []] :
                    [[plans[0], plans[1]], [plans[2], plans[3]], [plans[4], plans[5]]]
                  }  />
                  </Animate>
                  <Animate delay={0.6} >
                  <div className="d-flex justify-content-center mt-3">
                              
                                  <ButtonSquare inner={t("landing.section4.button")} color="primary px-5 m-0" isrounded={true} isfullWidth={true} onClick={() => navigate("/plans")}/>
                                  <ButtonSquare inner={t("landing.section4.sub_button")} color="primary-outline-primary square-btnn  btn full-width rounded px-5 m-0" isrounded={true} isfullWidth={true} onClick={() => navigate("/plans")}/>
                              </div>
                  </Animate>
                  </div>

              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12 col-12 d-block d-lg-none mt-5">
                <img className="w-100 mx-auto d-block" style={{maxWidth:"420px"}} src=
                // "./media/packs/rplace.png"
                {i18n.language === "ar" ? "./media/packs/rplace_ar.png" : "./media/packs/rplace.png"}
                />
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12 col-12 d-none d-lg-flex align-items-center justify-content-center"> 
            <img className="w-100 mx-auto d-block" src=
            // "./media/packs/rplace.png"
            {i18n.language === "ar" ? "./media/packs/rplace_ar.png" : "./media/packs/rplace.png"}
            />

            <div className="group-20464-LKw d-none" >
    <div className="frame-185134-Jg5">
      <div className="circle-progress-bar-f2m">
        <img className="ellipse-1-6ds" src="./media/landing/packs/assets/ratio.svg"/>
        {/* <img className="ellipse-2-zjF" src="./media/landing/packs/assets/ellipse-2.png"/> */}
        <div className="frame-184967-XUH">
          <div className="frame-184965-RpZ">
            {/* <img className="icon-outline-cloud-uUq" src="./media/landing/packs/assets/icon-outline-cloud.png"/> */}
            <i className="icon-outline-cloud-uUq  fas fa-cloud"></i>
            <div className="data-xT7">Data</div>
          </div>
          <div className="frame-184966-HnD">
            <div className="gb-Dvm">12.70 GB</div>
            <div className="of-40-gb-left-vqB">of 40 GB left</div>
          </div>
        </div>
      </div>
      <div className="cardv3-Cnh">
        <div className="frame-184971-WHb">
          <div className="frame-RvM">
            {/* <img className="icon-outline-clock-WS1" src="./media/landing/packs/assets/icon-outline-clock.png"/> */}
            <i className="icon-outline-clock-WS1 validity-pSh fas fa-clock"></i>
            <div className="validity-pSh">Validity</div>
          </div>
          <div className="frame-184970-WaR">
            <div className="days-rPP">14 days</div>
            <div className="remaining-97b">Remaining</div>
          </div>
        </div>
        <div className="rectangle-progress-bar-Dt9">
          <div className="rectangle-ZBK">
          </div>
        </div>
        <div className="rectangle-cvH">
        </div>
      </div>
    </div>

    <div className="contract-signed-TR7">
      <div className="overview-list-GNZ">
        <img className="task-square-nrh" src="./media/landing/packs/assets/task-square-YU5.png"/>
        <p className="usage-screen-ghB">Usage screen</p>
      </div>
      <div className="rectangle-aXf">
      </div>
      <div className="rectangle-WAR">
      </div>
    </div>
    <div className="contract-signed-yZo">
      <div className="overview-list-Dyw">
        <img className="task-square-ZH7" src="./media/landing/packs/assets/task-square.png"/>
        <p className="more-10-providers-sob">
          <span className="more-10-providers-sob-sub-0">More 10 </span>
          <span className="more-10-providers-sob-sub-1">providers</span>
        </p>
      </div>
      <div className="rectangle-ir9">
      </div>
      <div className="rectangle-FLH">
      </div>
    </div>
    <div className="total-freeelance-uvd">
      <div className="overview-list-nUd">
        <img className="group-20453-u3T" src="./media/landing/packs/assets/group-20453.png"/>
        <div className="employees-number-1cH">
          <p className="recharge-your-plan-7fK">Recharge your plan</p>
          <div className="rectangle-oHF">
          </div>
        </div>
      </div>
      <div className="metric-number-vMs">
        <p className="g-rmK">55G</p>
        <div className="number-badge-AGD">
          <p className="expires-on--VpH">Expires on:</p>
          <div className="rectangle-CCu">
          </div>
        </div>
      </div>
    </div>
  </div>
            </div>
           
    </div>
    </div>
    <div className="d-lg-none d-block ">
    {/* <List plans={plans} /> */}
    {/* <Slider plans={[]}  /> */}

    <div className="d-flex justify-content-center mt-3">
                        <ButtonSquare inner={t("landing.section4.button")} color="primary px-5 mx-3" isrounded={true} isfullWidth={true} onClick={() => navigate("/plans")}/>
                        <ButtonSquare inner={t("landing.section4.sub_button")} color="secondary px-5 mx-3" isrounded={true} isfullWidth={true} onClick={() => navigate("/stepper/1")}/>

                    </div>
    </div>
    </div>
    </Animate>
    );  

};

const List = ({plans}: any) =>
{
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const {addToCart} = useContext(CompareCardContext);

    return(
      <>
      <div className="packs-list mt-5">
                    {
                            plans.map((plan: any, index: number) => {
                                return (
                                    <div className={i18n.language === "ar" ? "pe-4 pe-lg-0 ps-lg-4" :
                                      "ps-4 ps-lg-0 pe-lg-4"}
                                     key={index} id={"plan" + plan.id}>
                                        <Dragable plan={plan} >
                                        <Card
                                inner={
                                    <div className="plan-card" style={{minWidth: '260px'}}>
                                      <div className="d-flex align-items-start">
                                        <img src={plan.image
                                        } alt="" width="50" height="50" style={{borderRadius: "8px"}} />
                                        <div className="d-flex flex-column mx-3" style={{minHeight: "72px"}}>
                                            <strong className="">{plan.title}</strong>
                                            <p className="text-muted m-0">{plan.contractType} | <span className="price">{plan.price} </span></p>
                                        </div>
                                    </div>
                                        {/* <div className="d-flex justify-content-between mt-3" style={{minHeight: '90px'}}>
                                            <div className="d-flex ">
                                                <img className="" src={plan.image} alt={plan.name} width="44" height="44" style={{borderRadius: '6px'}} />

                                                <div className="mx-3" style={{minHeight: '90px'}}>
                                                    <strong>{plan.title.length > 20 ? plan.title.substring(0, 20) + "..." : plan.title
                                                    }</strong>
                                                    <p className="font-weight-light m-0 text-muted">{plan.type}</p>
                                                </div>
                                            </div>
                                            <strong style={{color: '#3EB6AE', whiteSpace:"nowrap"}}>{plan.price} {plan.currency}</strong>
                                        </div> */}
                                        <div className="d-flex d-lg-block justify-content-between" style={{maxWidth: '300px'}}>
                                                <div className="d-flex align-items-center my-3 ">
                                                    {/* <i className="fas fa-phone fa-1x mx-3" style={{color: '#3EB6AE'}}></i> */}
                                                    {/* <HiOutlinePhone className="mx-3" style={{color: '#3EB6AE', fontSize: '1.5rem'}} /> */}
                                                    <div className="me-3">                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z" stroke="#2F1952" stroke-width="1.5" stroke-miterlimit="10"/>
                                                        </svg>
                                                        </div>

                                                    <div>
                                                        <p className="font-weight-light  m-0">{plan.calls}</p>
                                                        <p className="font-weight-light text-muted m-0">{t("plans.calls")}</p>
                                                    </div>
                                                </div>
                                                <div className="d-none d-lg-flex
                                                 align-items-center my-3">
                                                    {/* <i className="fas fa-comment fa-1x mx-3" style={{color: '#3EB6AE'}}></i> */}
                                                    {/* <FaRegEnvelope className="mx-3" style={{color: '#3EB6AE', fontSize: '1.5rem'}} /> */}
                                                    
                                                    <div className="me-3">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#2F1952" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                                <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#2F1952" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                                </div>

                                                    <div>
                                                        <p className="font-weight-light m-0">{plan.messages}</p>
                                                        <p className="font-weight-light text-muted m-0">{t("plans.messages")}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center my-3">
                                                    {/* <i className="fas fa-globe fa-1x mx-3" style={{color: '#3EB6AE'}}></i> */}
                                                    {/* <FiGlobe className="mx-3" style={{color: '#3EB6AE', fontSize: '1.5rem'}} /> */}
                                                    <div className="me-3">

                                                                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M7.99998 3H8.99998C7.04998 8.84 7.04998 15.16 8.99998 21H7.99998" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M15 3C16.95 8.84 16.95 15.16 15 21" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M3 9.0001C8.84 7.0501 15.16 7.0501 21 9.0001" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                    </div>

                                                    <div>
                                                        <p className="font-weight-light  m-0">{plan.internet}</p>
                                                        <p className="font-weight-light text-muted m-0">{t("plans.internet")}</p>
                                                    </div>
                                                </div>

                                        </div>
                                        <div className="d-flex mt-4 d-lg-none"> 
                                            <Button inner={t("plans.details")}
                                            onClick={
                                                () => navigate(`/plans/${plan.id}` )
                                                // () => setSelectedPlan(plan)
                                            } color="primary px-4" isrounded={false}/>
                                            <div className="mx-1"></div>
                                            <Button inner={t("plans.compare")}
                                             onClick={function (): void {
                                                    addToCart(plan, 'plan'+plan.id);
                                                } } color="primary-outline-primary px-4" isrounded={false}/>
                                        </div>
                                        <div className="d-none d-lg-block">
                                            <div className="row mt-4">
                                                    <div className="col-6 col-md-6 mb-1">
                                                        <Button inner={t("plans.details")}
                                                            onClick={
                                                            () => navigate(`/plans/${plan.id}` )
                                                           // () => setSelectedPlan(plan)
                                                        } color="primary" isfullWidth={true} isrounded={false} size="sm"/>
                                                    </div>
                                                    <div className="col-6 col-md-6 mb-1">
                                                        <Button inner={t("plans.compare")}
                                                            onClick={function (): void {
                                                                addToCart(plan, 'plan'+plan.id);
                                                            } } color="primary-outline-primary"
                                                            isfullWidth={true} isrounded={false} size="sm"/>
                                                    </div>
                                            </div>
                                            </div>
                                        </div>
                                }
                            />
                                    </Dragable>
                                    </div>
                                )
                        }
                        )}
                    </div>
                    
                    </>
    );
}

const Slider =(
  {plans}: any
)=> {
  const [index, setIndex] = React.useState(0);
  const [mobile, setMobile] = React.useState(false);
  const {t, i18n} = useTranslation();
const handleSelect = (selectedIndex: any, e: any) => {
  setIndex(selectedIndex);
};

const navigate = useNavigate();
const {addToCart} = useContext(CompareCardContext);

  React.useEffect(() => { 
      if (window.innerWidth < 992) {
          setMobile(true);
      }
  }, [])
  if(plans.length === 0) {
    return (<></>)
  }

  return (
          <Animate delay={0.1}>
          <div className=''>
                      
                      <Animate delay={0.3}>
                      <Carousel activeIndex={index} onSelect={handleSelect} controls={false} interval={null} indicators={false}>
                      {/* {props.inners[active].map((inner, index) => {
                          return (
                              <Carousel.Item>
                                  </Carousel.Item>
                          )
                      })} */}
                      <Animate delay={0.4}>
                     
                      </Animate>                                   
                      </Carousel>
                      </Animate>
                      <div className='d-block'>
                      <Carousel activeIndex={index} onSelect={handleSelect} interval={null} controls={false} indicators={false}>
                      
                      {plans.map((plans_: any, index: number) => {
                        
                          return (         
                              <Carousel.Item>
                                  <div className="row">
                                  {
                                    plans_.map((plan: any, index: number) => {
                                  return (
                                    <div className="col-12 col-lg-6" style={{position: 'relative'}}>
                                   <div className={i18n.language === "ar" ? "pe-3 pe-lg-0 ps-lg-3" :
                                      "ps-3 ps-lg-0 pe-lg-3"}
                                     key={index} id={"plan" + plan.id}>
                                        <Card
                                inner={
                                    <div className="plan-card" style={{minWidth: '260px'}}>
                                      <div className="d-flex align-items-start">
                                        <img src={plan.image
                                        } alt="" width="50" height="50" style={{borderRadius: "8px"}} />
                                        <div className="d-flex flex-column mx-3" style={{minHeight: "72px"}}>
                                        
                                            <strong className="">{plan.title}</strong>
                                            <p className="text-muted m-0"
                                        style={{
                                            display: 'flex',
                                            gap: '6px',
                                            flexDirection: 'row',
                                            width: 'max-content',
                                        }}>
                                            <span>{plan.contractType
                                            .toLowerCase().split('/')[0]}</span> 
                                            {
                                                plan.hasDiscount ?
                                            <span className=""> | <span style={{textDecoration: 'line-through'}}>{plan.price}</span></span>
                                                : <> | </> } <span className="price"> {plan.newPrice} {t("plans.sar")}</span>
                                        </p>   
                                        {
                                            plan.hasDiscount ?
                                            <div className="discount" style={
                                              i18n.language === "en" ? {
                                              
                                            position: 'absolute',
                                            top: '0',
                                            left: '12px',
                                            padding: '6px 10px',
                                            background: '#D4ECEB',
                                            color: '#3EB6AE',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            
                                            borderRadius: '19px 0px 8px 0px',
                                              } : {
                                                position: 'absolute',
                                                top: '0',
                                                right: '12px',
                                                padding: '6px 10px',
                                                background: '#D4ECEB',
                                                color: '#3EB6AE',
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                borderRadius: '0px 19px 0px 8px',
                                              }
                                            }>
                                                   {plan.discount}
                                                </div> : <></>
}                                     </div>
                                    </div>
                                      
                                        <div className="d-flex d-lg-block justify-content-between" style={{maxWidth: '300px'}}>
                                                <div className="d-flex align-items-center my-3 ">
                                                    {/* <i className="fas fa-phone fa-1x mx-3" style={{color: '#3EB6AE'}}></i> */}
                                                    {/* <HiOutlinePhone className="mx-3" style={{color: '#3EB6AE', fontSize: '1.5rem'}} /> */}
                                                    <div className="me-3">                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z" stroke="#2F1952" stroke-width="1.5" stroke-miterlimit="10"/>
                                                        </svg>
                                                        </div>

                                                    <div>
                                                        <p className="font-weight-light  m-0">{plan.calls}</p>
                                                        <p className="font-weight-light text-muted m-0">{t("plans.calls")}</p>
                                                    </div>
                                                </div>
                                                <div className="d-none d-lg-flex
                                                 align-items-center my-3">
                                                    {/* <i className="fas fa-comment fa-1x mx-3" style={{color: '#3EB6AE'}}></i> */}
                                                    {/* <FaRegEnvelope className="mx-3" style={{color: '#3EB6AE', fontSize: '1.5rem'}} /> */}
                                                    
                                                    <div className="me-3">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#2F1952" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                                <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#2F1952" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                                </div>

                                                    <div>
                                                        <p className="font-weight-light m-0">{plan.messages}</p>
                                                        <p className="font-weight-light text-muted m-0">{t("plans.messages")}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center my-3">
                                                    {/* <i className="fas fa-globe fa-1x mx-3" style={{color: '#3EB6AE'}}></i> */}
                                                    {/* <FiGlobe className="mx-3" style={{color: '#3EB6AE', fontSize: '1.5rem'}} /> */}
                                                    <div className="me-3">

                                                                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M7.99998 3H8.99998C7.04998 8.84 7.04998 15.16 8.99998 21H7.99998" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M15 3C16.95 8.84 16.95 15.16 15 21" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M3 9.0001C8.84 7.0501 15.16 7.0501 21 9.0001" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                    </div>

                                                    <div>
                                                        <p className="font-weight-light  m-0">{plan.internet}</p>
                                                        <p className="font-weight-light text-muted m-0">{t("plans.internet")}</p>
                                                    </div>
                                                </div>

                                        </div>
                                        <div className="d-flex mt-4 d-lg-none"> 
                                            <Button inner={t("plans.details")}
                                            onClick={
                                                () => navigate(`/plans/${plan.id}` )
                                                // () => setSelectedPlan(plan)
                                            } color="primary px-4" isrounded={false}/>
                                            <div className="mx-1"></div>
                                            <Button inner={t("plans.compare")}
                                             onClick={function (): void {
                                                    addToCart(plan, 'plan'+plan.id);
                                                } } color="primary-outline-primary px-4" isrounded={false}/>
                                        </div>
                                        <div className="d-none d-lg-block">
                                            <div className="row mt-4">
                                                    <div className="col-6 col-md-6 mb-1">
                                                        <Button inner={t("plans.details")}
                                                            onClick={
                                                            () => navigate(`/plans/${plan.id}` )
                                                           // () => setSelectedPlan(plan)
                                                        } color="primary" isfullWidth={true} isrounded={false} size="sm"/>
                                                    </div>
                                                    <div className="col-6 col-md-6 mb-1">
                                                        <Button inner={t("plans.compare")}
                                                            onClick={function (): void {
                                                                addToCart(plan, 'plan'+plan.id);
                                                            } } color="primary-outline-primary"
                                                            isfullWidth={true} isrounded={false} size="sm"/>
                                                    </div>
                                            </div>
                                            </div>
                                        </div>
                                }
                            />
                                    </div>
                                    </div>
                                  )
                                  })
                                }
                                  </div>
                                      
                              </Carousel.Item>
                          )
                      })}
                  </Carousel>
                      </div>
                      
                      
                      <Animate delay={0.5}>
                      <div className='d-flex my-4'>
                          {/* {Array.from({length: props.inners[0].length}).map((_, index_) => { */}
                          {Array.from({length: plans.length}).map((_, index_) => {
                              return (
                                      <div className='me-3' onClick={() => setIndex(index_)} style={{cursor: "pointer", width: "32px", height: "6px", borderRadius: "3px", backgroundColor: index === index_ ? "#3EB6AE" : "#FFFFFF"}}></div>
                              )
                          })}
                      </div>    
                      </Animate>     
                  
          </div>
          </Animate>
  )

}

export default Packs;
            
import React from 'react';
import { Carousel, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import Button, { ButtonSquare } from '../../../components/design/buttons/Button';
import { getProviders } from '../../../services/providers.service';
import { toAbsoluteUrl } from '../../../tools/AssetHelpers';
import './hero.css';
import parse from 'html-react-parser'

import { useNavigate } from 'react-router-dom';
import Animate from '../../about/Animate';

const Hero = () => {
    const [active, setActive] = React.useState(0);
    const [index, setIndex] = React.useState(0);
    const {t,i18n}= useTranslation();
    const navigator = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const handleSelect = (selectedIndex: any, e: any) => {
      setIndex(selectedIndex);
    };

    const [providers, setProviders] = React.useState<any>([]);
    const fetchProviders = async () => {
        const response = await getProviders(i18n.language);
        // make the id 9 in place of 16 and 16 in place of 9
        console.log(response.returnData);
        const temp = response.returnData[7];
        console.log(temp);
        response.returnData[7] = response.returnData[2];
        response.returnData[2] = temp;
        console.log(response.returnData);
        setProviders(response.returnData.slice(0, 10));
        setLoading(false);
    }
    const [inners, setInners] = React.useState<any>(
        [
            
        ]
    )


    React.useEffect(() => {
        fetchProviders();
        setInners([
                
            <div className='w-100 py-3 py-lg-5 m-0'>
                <div className='p-0'>

                    <Animate delay={0.3} >
                <h1>{
                parse(t("landing.section1.title"))
                }</h1>
                </Animate>
                <Animate delay={0.4} >
                <p className='my-3 my-lg-5'>{
                    t("landing.section1.sub_title")
                }</p>
                </Animate>
                </div>
                <div className='row'>
                <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4'>
                    <Animate delay={0.5} >
                    <ButtonSquare inner={t("landing.section1.button")} color="primary" isfullWidth={true} isrounded={true} onClick={() => {
                        navigator('/stepper/1')
                    }}/>
                    </Animate>
                </div>
                <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb'>
                    <Animate delay={0.6} >
                        <ButtonSquare inner={t("landing.section1.sub_button")} color="primary-outline-primary" isfullWidth={true} isrounded={true} onClick={() => {
                            navigator('/plans')
                        }}/>
                    </Animate>
                </div>
                </div>
               
                <div className='d-flex'>
                    <img src="/media/svgs/cst.svg"/>        
                    <span style={{textAlign:"start", fontWeight:"300", padding:"16px"}} className=" ">{t("landing.partner")}</span>
                </div>
            </div>,
        ])
    }, [i18n.language])
    return (
        <>
        
        <div className="hero-container my-3 py-3 py-lg-5 my-lg-4">
            <Animate delay={0.1} >
            <div className="row hero_landin_page justify-content-center align-items-center m-0">
            <div className='col-lg-6  col-12 order-2 order-lg-1'>
                        <Animate delay={0.2} >
                        <Carousel activeIndex={index} onSelect={handleSelect} controls={false} interval={null} indicators={false}>
                        {inners.map((inner: any, index_: number) => {
                        return (
                            <Carousel.Item>
                            {inner}
                            </Carousel.Item>
                        )})}
                        </Carousel>
                        </Animate>
                        {/* <strong className='d-none d-lg-block'>
                            <h1>0{index+1}</h1> / 0{inners.length}
                        </strong> */}
                        {/* <div className='d-flex px-3 my-4 w-100 justify-content-center justify-content-lg-start'>
                            {Array.from({length: inners.length}).map((_, index_) => {
                                return (
                                        <div className='me-3' onClick={() => setIndex(index_)} style={{cursor: "pointer", width: "32px", height: "6px", borderRadius: "3px", backgroundColor: index === index_ ? "#3EB6AE" : "#2F1952"}}></div>
                                )
                            })}
                        </div> */}
                    </div>
                    <div className='col-12 col-lg-6 order-1 order-lg-2'>
                    <Animate delay={0.5} >
                <div className="position-relative bgas"
                 style={{backgroundImage:'url("./media/landing/hero/bg.svg")', backgroundSize: '130% 126%',
                 backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                {
                    !loading &&
                    <div className="container_circles">
                    {
                        providers.map((provider: { imagePathSmall: string; }) => {
                            return (
                                <div style={{backgroundImage: "url("+toAbsoluteUrl(provider.imagePathSmall)+")"}}>
                      
                                </div>
                            )
                        })
                    }
                    <div  style={{backgroundImage: "url(./media/logos/default-dark.svg)", backgroundSize: "70%", backgroundRepeat: "no-repeat"}}></div>
                    
                </div>

                }
                </div>
                </Animate>
                </div>

            </div>
            

            
        </Animate>
        </div>
        
        </>
    )
}

export default Hero
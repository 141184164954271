import { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import './faq.css';
import { useTranslation } from 'react-i18next';
import Animate from '../../about/Animate';
import parse from 'html-react-parser'

const Faq = () => {
    const [onen, setOnen] = useState(0);
    const {t, i18n} = useTranslation();
    

    return (
      <Animate delay={0.1}>
        <div className="row my-5">
          <div className="col-lg-6 col-md-6 col-sm-12 d-flex order-2 order-md-1 mt-5 mt-md-0"
           style={{minHeight: "400px"}}>
            <div style={{backgroundImage: "url('./media/faq/1_.png')"}} className="faq-image"/>
            <div style={{backgroundImage: "url('./media/faq/2_.png')"}} className="faq-image"/>
            <div style={{backgroundImage: "url('./media/faq/3_.png')"}} className="faq-image"/>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 order-1 order-md-2">
          <Animate delay={0.1}>
            <div className="dis-1">{t("esim.section6.faq")}</div>
            </Animate>
              <Questions />
          </div>
        </div>
      </Animate>
    );
};

const Questions = () => {
  const {t, i18n} = useTranslation();
  const [onen, setOnen] = useState(0);

  return(<>
    
            <div className="frame-185317-A21">
              <Animate delay={0.1}>
              <div className="frame-185316-5uf">
                <div className="d-flex justify-content-between py-4" onClick={() => onen === 0 ? setOnen(-1) : setOnen(0)} style={{cursor:"pointer"}}>
                  <strong className={"can-bitcoin-be-regulated-6Jy"  + (onen === 0 ? " c-primary" : "")}>{t("esim.section6.element1.title")}</strong>
                  <i className={onen === 0 ? "fas fa-chevron-down" : i18n.language ==="en" ? "fas fa-chevron-right": "fas fa-chevron-left"} style={{color: "#000000", fontSize: "16px"}}/>
                </div>
              </div>
              </Animate>
              <Animate delay={0.2}>
              <Collapse in={onen === 0}>
                <div id="example-collapse-text">
                {t("esim.section6.element1.sub_title")}
                </div>
            </Collapse>
            </Animate>
              <Animate delay={0.3}>
              <div className="d-flex justify-content-between py-4" onClick={() =>  onen === 1 ? setOnen(-1) : setOnen(1)} style={{cursor:"pointer"}}>
                <strong className={"is-bitcoin-useful-for-illegal-activities-4BF" + (onen === 1 ? " c-primary" : "")}
                >{t("esim.section6.element2.title")}</strong>
                <i className={onen === 1 ? "fas fa-chevron-down" : i18n.language ==="en" ? "fas fa-chevron-right": "fas fa-chevron-left"} style={{color: "#000000", fontSize: "16px"}}/>
              </div>
              </Animate>
                <Animate delay={0.4}>
                <Collapse in={onen === 1}>
                <div id="example-collapse-text">
                  {
                    /* render html */
                    parse(t("esim.section6.element2.sub_title"))
                  }
                </div>
            </Collapse>
            </Animate>
            <Animate delay={0.5}>

              <div className="d-flex justify-content-between py-4" onClick={() => onen === 2 ? setOnen(-1) : setOnen(2)} style={{cursor:"pointer"}}>
                <strong className={"is-bitcoin-useful-for-illegal-activities-4BF" + (onen === 2 ? " c-primary" : "")}
                >{t("esim.section6.element3.title")}</strong>
                <i className={onen === 2 ? "fas fa-chevron-down" : i18n.language ==="en" ? "fas fa-chevron-right": "fas fa-chevron-left"} style={{color: "#000000", fontSize: "16px"}}/>
              </div>
              </Animate>
            <Animate delay={0.6}>
                <Collapse in={onen === 2}>
                <div id="example-collapse-text">
{                    parse(t("esim.section6.element3.sub_title"))
}                </div>
            </Collapse>
            </Animate>
            <Animate delay={0.7}>
              <div className="d-flex justify-content-between py-4" onClick={() => onen === 3 ? setOnen(-1) : setOnen(3)} style={{cursor:"pointer"}}>
                <strong className={"is-bitcoin-useful-for-illegal-activities-4BF" + (onen === 3 ? " c-primary" : "")}
                >{t("esim.section6.element4.title")}</strong>
                <i className={onen === 3 ? "fas fa-chevron-down" : i18n.language ==="en" ? "fas fa-chevron-right": "fas fa-chevron-left"} style={{color: "#000000", fontSize: "16px"}}/>
              </div>
              </Animate>
            <Animate delay={0.8}>

                <Collapse in={onen === 3}>
                <div id="example-collapse-text">
                  {
                    parse(t("esim.section6.element4.sub_title"))
                  }
                </div>
            </Collapse>
            </Animate>
            

              
            </div></>
  )
}

export {Questions}

export default Faq;
    


import './sideInfo.css';

// const ImageForTs = Image as any;

const SideInfo = ({elements, background, noBg,is_bg}: {elements: any[], background: string, noBg: number[], is_bg: boolean}) => {
    return (
    <div className="side_info" >
        <div className="side_info_bg" style={{backgroundImage: `url(${background})`,
            backgroundPosition: "center",
            backgroundSize: is_bg?"": "auto 60%",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#3db6ae",
    }} >
        {/* <ImageForTs
            src={background}
            width={140} height={140}
            style={{ objectFit: 'cover' }}
        /> */}
        </div>
        {
            elements.map((element: any) => {
                return (
                    <div className={`side_info_item ${noBg.find((item: number) => item === elements.indexOf(element)) !== undefined ? 'no_bg' : ''}`} key={elements.indexOf(element)}>
                        {
                            element
                        }
                    </div>
                )
            }
            )
        }
    </div>
    )
}

export default SideInfo;


import { useTranslation } from 'react-i18next'
import './advantages.css'
import { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import Animate from '../../about/Animate'
const CardAdvantages = (
    {
        title,
        image,
        description
    } : {
        title: string,
        image: string,
        description: string
    }
) => {
    return (
        <div className="card-advantages">
            <img className="social-media-user-2-kLd" src={image}  />
            <div className="frame-35-EWh">
                <strong className="it-can-be-activated-at-any-time-vuK">{title}</strong>
                <p className="recharge-your-number-and-enjoy-the-best-prepaid-packages-and-services-1A5">{description}</p>
            </div>
        </div>
    )
}

export  const Advantages = ({hasDescription}: {hasDescription?: boolean}) => {
    const {t, i18n} = useTranslation();
    const [advantages, setAdvantages] = useState<any[]>([
       
    ]);
    
    useEffect(() => {
        setAdvantages([
            {
                title: t("esim.section4.element1.title"),
                image: "/media/advantages/1.png",
                description: t("esim.section4.element1.sub_title")
            },
            {
                title: t("esim.section4.element2.title"),
                image: "/media/advantages/2.png",
                description: t("esim.section4.element2.sub_title")
            },
            {
                title: t("esim.section4.element3.title"),
                image: "/media/advantages/3.png",
                description: t("esim.section4.element3.sub_title")
            },
            {
                title: t("esim.section4.element4.title"),
                image: "/media/advantages/4.png",
                description: t("esim.section4.element4.sub_title")
            }
        ])
    }, [i18n.language])

        
    return (
        <div className="my-5">
            <div className="text-center p-3 p-md-5 " style={{maxWidth: "1000px", margin: "0 auto"}}>
                <Animate delay={0.1} >
                <h1 className="advantages-esim-H1o">
                    {parse(t("esim.section4.title"))}
                </h1>
                </Animate>
                {
                    hasDescription&& <p className="advantages-esim-p-1">
                    {parse(t("esim.section4.sub_title"))}
                </p>}
            </div>
            <div className="row row_adv">
                {advantages.map((advantage, index) => (

                    <div className="col-6 col-md-6 col-lg-3 text-center mb-4">
                             <Animate delay={index * 0.1} className="h-100" >
                    <CardAdvantages
                        key={index}
                        title={advantage.title}
                        image={advantage.image}
                        description={advantage.description}
                    />
                    </Animate>
                    </div>
                ))}
            </div>
        </div>
    )
}


import { useTranslation } from "react-i18next";
import Header from "../../components/header/header";
import './regulations.css';
import Footer from "../../components/footer/footer";
import Animate from "../about/Animate";
import { ButtonSquare } from "../../components/design/buttons/Button";
import { Carousel, Collapse } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import parse from 'html-react-parser'

const Regulations = () => {
    const {t, i18n} = useTranslation();

    const [topics, setTopics] = useState<any>([
       

    ])
    useEffect(() => {
        setTopics([
            {
                title: t("regulations.topics.item1.title"),
                image: "6",
                elements: [
                   
                ]
            },
            {
                title: t("regulations.topics.item2.title"),
                image: "2",
                elements: [
                    
                ]
            },
            {
                title: t("regulations.topics.item3.title"),
                image: "5",
                elements: [
                    {
                        title: t("regulations.topics.item3.element1"),
                        link: "localhost:3000/regulations"
                    },
                    {
                        title: t("regulations.topics.item3.element2"),
                        link: "localhost:3000/regulations"
                    },
                    {
                        title: t("regulations.topics.item3.element3"),
                        link: "localhost:3000/regulations"
                    }
                ]
            },
            {
                title: t("regulations.topics.item4.title"),
                image: "3",
                elements: [
                  
                ]
            }
        ])
    }, [i18n.language])
    const [onen, setOnen] = useState(0);


    const [activeTopic, setActiveTopic] = useState(0);
    const [index, setIndex] = useState(0);
    const navigate = useNavigate();
    const handleSelect = (selectedIndex: any, e: any) => {
      setIndex(selectedIndex);
    };
    return (
        <>
           <div >
            <div className="container">
            <Header />
            <Animate delay={0.1} >
            <div className="topics mb-5 mb-lg-5 my-2 py-2 my-lg-5 py-lg-5">
                <div className="text-center mx-auto" style={{maxWidth: "920px"}}>
                <Animate delay={0.3} >
                    <h2>{t("regulations.title")}</h2>
                    </Animate>
                </div>
                <div className="row mt-2 pt-2 mt-lg-5 pt-lg-5">
                    <div className="col-xl-6 col-lg-6  col-sm-12 position-relative d-none d-lg-block">
                        <Animate delay={0.4} >
                        {/* <img src={`media/landing/topics/bg.svg`} alt="bg" className="position-absolute" style={{top: "0", left: "-80px", zIndex: -1}} /> */}
                    <Carousel activeIndex={activeTopic} onSelect={handleSelect} controls={false} interval={null} indicators={false}>
                        {topics.map((inner: any, index: any) => {
                        return (
                            <Carousel.Item>
                                <img className="image_topic" src={`media/landing/topics/${inner.image}.png`} alt="bg" style={{margin: "auto", display: "block", width: "100%"}} />
                            </Carousel.Item>
                        )})}
                        </Carousel>
                        </Animate>
                    </div>
                    <div className="col-xl-6 col-lg-6  col-sm-12 d-none d-lg-block">
                        <div className="row">
                        
                            {
                                topics.map((topic: any, index: any) => {
                                    return (
                                        <div className="" key={index} onClick={() => {setActiveTopic(index); onen === index ? setOnen(-1) : setOnen(index)}} >
                                            <Animate delay={0.1}>
                        <div className="regulation-box px-4 mt-4">
                            <div className="d-flex justify-content-between py-4"  style={{cursor:"pointer"}}>
                            <strong className={"can-bitcoin-be-regulated-6Jy"}>{topic.title}</strong>
                            <i className={onen === index ? "fas fa-chevron-down" : i18n.language ==="en" ? "fas fa-chevron-right": "fas fa-chevron-left"} style={{color: "#000000", fontSize: "16px"}}/>
                            </div>
                        </div>
                        </Animate>
                        <Animate delay={0.2}>
                        <Collapse in={onen === index}>
                         { 
                         topic.elements.length > 0 ?
                         <div className="elements_regulation">
                          {
                                
                                topic.elements.map((element: any, index: any) => {
                                    return (
                                        <span className="element_regulation p-3" key={index} onClick={() => navigate(element.link)}>
                                        {parse(element.title)}
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="8" fill="#3EB6AE"/>
<path d="M14 15.3335V19.3335L15.3333 18.0002" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 19.3333L12.6667 18" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.6666 14.6668V18.0002C22.6666 21.3335 21.3333 22.6668 18 22.6668H14C10.6666 22.6668 9.33331 21.3335 9.33331 18.0002V14.0002C9.33331 10.6668 10.6666 9.3335 14 9.3335H17.3333" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.6666 14.6668H20C18 14.6668 17.3333 14.0002 17.3333 12.0002V9.3335L22.6666 14.6668Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                                        </span>
                                    )
                                })
                            }
                          </div> : <div></div>
                                }
                        </Collapse>
                        </Animate>
                                        </div>
                                    )
                                })
                            }
                           
                        </div>

                    </div>
                    <div className="col-xl-6 col-lg-6  col-sm-12 d-block d-lg-none">
                        <div className="row p-3">
                            {
                                topics.map((topic: any, index: any) => {
                                    return (
                                        <div className="col-xl-6 col-lg-6  col-6 p-1" key={index} onClick={() => setActiveTopic(index)} >
                                            <div className={`topic-box active m-0`}>
                                                <img src={`media/landing/topics/${topic.icon}.${activeTopic === index ? "gif" : "png"}`} className="gif" alt="icon" width="32px" />
                                                <strong>{topic.title}</strong>
                                                <p>{topic.description}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                </div>
            </div>
        </Animate>
            </div>
            <Footer />
        </div>
        
        </>
    )
   
    }

    
export default Regulations;

import './footer.css'
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const {t,i18n} = useTranslation();

    return (
        <footer>
            <div className="container">
                <div className="row text-center text-lg-start">
                    <div className="col-lg-4">
                        
                        <img src="/media/footer/screen.png" alt="footer" />
                    </div>
                    <div className="col-lg-8">
                        <h1 className='mb-2 mt-2 mt-lg-3 mb-lg-5
                        '>{t("footer.title")}</h1>
                        <p className='my-2 my-lg-5'>
                            {t("footer.sub_title")}
                        </p>
                        <div className="d-flex justify-content-center justify-content-lg-start">
          <div className='m-2'> 
          <a href="https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone](https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone)" target="_blank">
          <img src="/media/buttons/apple.svg" width={124} />
          </a>
          </div>
          <div className='m-2'>
          <a href='https://play.google.com/store/apps/details?id=app.tygo.sa' target="_blank">
          <img src="/media/buttons/google.svg" width={124} />
          </a>
          </div>
          </div>
          </div>
                </div>
            </div>

        </footer>
    )
    }

export default Footer;
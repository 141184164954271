import { useTranslation } from 'react-i18next';
import './download.css'
import Animate from '../../about/Animate';

const DownloadSection = () => {
const {t, i18n} = useTranslation();

    return (
        <Animate delay={0.1}>
        <div className="download-section py-0 pt-lg-5" >


    
            <div className="container">
                <div className="row  justify-content-center">
                    <div className="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12 d-flex flex-column  justify-content-center">
                        <Animate delay={0.2}>
                        <h1 className='py-1' style={{  lineHeight: "1.6"}}>{t("landing.section7.title")}</h1>
                        </Animate>
                        <Animate delay={0.3}>
                        <p className='py-1'>{t("landing.section7.sub_title")}</p>
                        </Animate>
                        <Animate delay={0.4}>
                        <div className="d-flex gap-3">
                                <div className=''> 
                                <a href="https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone](https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone)" target="_blank">

                                <img src="/media/buttons/apple.svg" width={154} />
                                </a>
                                </div>
                                <div className=''>
                                <a href='https://play.google.com/store/apps/details?id=app.tygo.sa' target="_blank">

                                <img src="/media/buttons/google.svg" width={154} />
                                </a>
                                </div>
                            </div>
                        </Animate>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex flex-column align-items-center text-center my-2">
                    <Animate delay={0.5}>
                        {/* <div className="row justify-content-center">
                            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 d-flex flex-column align-items-center text-center align-self-center">
                        </div> */}
                        <img 
                        src={
                            i18n.language === 'ar' ? "/media/landing/download/4_ar.png" : "/media/landing/download/4.png"
                        }
                        // src="/media/landing/download/4.png"
                         className='w-100' />
                        {/* <div className="col-xl-3 col-lg-3 col-sm-12 col-12 d-flex flex-column align-items-center text-center align-self-center mb-4">
                            <img src="/media/landing/download/2.png" className='w-100' style={{maxWidth: 280}} />
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 d-flex flex-column align-items-center text-center align-self-center d-none d-lg-flex">
                            <img src="/media/landing/download/3.png" className='w-100' style={{maxWidth: 220}}/>
                        </div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 d-flex flex-column align-items-center text-center align-self-center">
                        </div>
                        </div> */}
                    </Animate>
                    </div>
                </div>

                        {/* <img src="/media/partner.svg" className="img-fluid m-auto d-block mt-5" width={500} />
                        <span style={{textAlign:"center", margin:"auto", maxWidth:"400px",display:"block",fontSize:"26px", fontWeight:"300", padding:"16px"}} className=" ">{t("landing.partner")}</span> */}
            </div>
        </div>
        </Animate>
    )   
}

export default DownloadSection;
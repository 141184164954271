import { Link } from "react-router-dom";
import Header from "../../components/header/header";
import '../about/about.css'
import Footer from "../../components/footer/footer";

const Blog = () => {
    return <div style={{backgroundImage: "url(./media/blog_bg.png)"}}>
        <div className="container about mb-5" >
    <Header/>
    <div className="max-md mb-md mx-auto mt-5 text-center">
                        <h3 className="fs-xxl sal-animate" data-sal="slide-up" data-sal-delay="100">Blogs</h3>
                        <div data-sal="slide-up" data-sal-delay="200" className="sal-animate">
                            <p className="fs-sm opacity-50">But I must explain to you how all this mistaken idea of denouncing</p>
                        </div>
                    </div>
    <div className="row">
						
					
														<div className="col-lg-4 mb-col sal-animate" data-sal="slide-up" data-sal-delay="200">
									



                           <div className="appCard">
                                <div className="appCard-header"><Link className="appCard-thumb" to={"/post"} title="How do you keep your information private?">


										<div className=" inViewJs is-3-2"
                                         
                                        >
														<img 
                                                        style={{
                                                            width: "100%",
                                                            
                                                         }}
                                                        alt="How do you keep your information private?" data-src="./media/blog/2.fa0644a3.jpg" src="./media/blog/2.fa0644a3.jpg"/>
													</div>

                                    </Link></div>
                                <div className="appCard-body">
                                    <h4 className="appCard-title"><Link to={"/post"} title="How do you keep your information private?">How do you keep your information private?</Link></h4>
                                    <p className="appCard-desc fs-xxxs mb-0 opacity-50 pt-e6">
                                        With the increasing use of the Internet, the constant presence on social media platforms, and the continuous sharing of personal information in this open space, the issue of information privacy has		
                                                            </p>
                                </div>
                            </div>								</div>

															<div className="col-lg-4 mb-col sal-animate" data-sal="slide-up" data-sal-delay="200">
									



                           <div className="appCard">
                                <div className="appCard-header"><a className="appCard-thumb" href="https://20.233.30.48/5-tips-for-managing-data-consumption/" title="5 Tips for managing data consumption">


										<div className=" inViewJs is-3-2">
														<img 
                                                         style={{
                                                            width: "100%",
                                                            
                                                         }}
                                                        alt="5 Tips for managing data consumption" data-src="./media/blog/1.15d8bd61.jpg" src="./media/blog/1.15d8bd61.jpg"/>
													</div>

                                    </a></div>
                                <div className="appCard-body">
                                    <h4 className="appCard-title"><a href="https://20.233.30.48/5-tips-for-managing-data-consumption/" title="5 Tips for managing data consumption">5 Tips for managing data consumption</a></h4>
                                    <p className="appCard-desc fs-xxxs mb-0 opacity-50 pt-e6">
                                        With our permanent connection to the Internet, the use of social media platforms and constantly communicating with family, friends, and work, our consumption of data increases dramatically, and it		
                                                            </p>
                                </div>
                            </div>								</div>

															<div className="col-lg-4 mb-col sal-animate" data-sal="slide-up" data-sal-delay="200">
									



                           <div className="appCard">
                                <div className="appCard-header"><a className="appCard-thumb" href="https://20.233.30.48/how-to-choose-a-suitable-internet-and-communication-package/" title="How to choose a suitable internet and communication package?">


										<div className=" inViewJs is-3-2">
														<img
                                                         style={{
                                                            width: "100%",
                                                            
                                                         }}
                                                        alt="How to choose a suitable internet and communication package?" data-src="./media/blog/2.fa0644a3.jpg" src="./media/blog/2.fa0644a3.jpg"/>
													</div>

                                    </a></div>
                                <div className="appCard-body">
                                    <h4 className="appCard-title"><a href="https://20.233.30.48/how-to-choose-a-suitable-internet-and-communication-package/" title="How to choose a suitable internet and communication package?">How to choose a suitable internet and communication package?</a></h4>
                                    <p className="appCard-desc fs-xxxs mb-0 opacity-50 pt-e6">
                                        If you thought of subscribing to one of the communication and internet packages, you certainly felt confused by the multiplicity of services		
                                                            </p>
                                </div>
                            </div>								</div>

							
						

                    
                    
						
                    </div>
</div>
    <Footer/>
</div>

}

export default Blog;
import React from 'react';
const TermsAr = () => {
    return (

      <div >
        <p style={{textAlign: 'center'}}>
          <strong><span >الشروط والأحكام</span></strong>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >هذه الشروط والأحكام (يشار إليها فيما بعد بـ“</span><strong><span >الاتفاقية</span></strong><span >”) تحدد وتحكم استخدامك لتطبيق</span><span ><span /> “</span><span ><span />تايقو</span><span ><span />”</span><span ><span />، </span><span >ومن المهم قراءتها وفهمها قبل استخدامك "تايقو"</span><strong><span > </span></strong><span >حتى تكون على عِلم بحقوقك النظامية والتزاماتك تجاه</span><strong><span > </span></strong><span >"تايقو"، وبالنقر على كلمة “</span><strong><span >أوافق</span></strong><span > - </span><span ><span ><span />&nbsp;</span><span >“</span><strong><span >AGREE</span></strong></span><strong><span ><span /></span></strong> <span >فإنك توافق على تطبيق هذه الاتفاقية في حالة ما إذا اخترت الوصول إلى خدمة </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> </span><span >أو استخدامها. إذا كنت لا توافق على هذه الاتفاقية، فلا يسمح لك باستخدام "تايقو".</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >أولاً: التعريفات</span></u></strong>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >يقصد بالعبارات والمصطلحات التالية – أينما وردت في هذه الاتفاقية – المعاني الموضحة أمام كل منها، ما لم يقتض السياق غير ذلك</span><span ><span />:</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> </span><span >أو </span><span ><span />“</span><span ><span />التطبيق</span><span ><span />”</span><span ><span /> </span><span >أو </span><span ><span />“</span><span ><span />الموقع</span><span ><span />”</span><span ><span /> </span><span >أو </span><span ><span />“</span><span ><span />نحن</span><span ><span />”</span><span ><span /> </span><span >أو </span><span ><span />“</span><span ><span />ضمير المتكلم</span><span ><span />”</span><span ><span /> </span><span >أو </span><span ><span />“</span><span ><span />ضمير الملكية</span><span >": يشير إلى </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> </span><span >وشركة مستقبل التواصل</span><span >&nbsp;</span><span >بالمملكة العربية السعودية</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >“</span><span ><span />المستخدم</span><span ><span />”</span><span ><span /> </span><span >أو </span><span ><span />“</span><span ><span />أنت</span><span ><span />”</span><span ><span /> </span><span >أو </span><span ><span />“</span><span ><span />ضمير الملكية </span><span >للمخاطب</span><span ><span /> “</span><span ><span />: </span><span >يعني الشخص الذي يزور التطبيق، أو يستخدمه، أو يُسجل عضوية بالتطبيق</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >“</span><span ><span />الخدمات</span><span ><span />”</span><span ><span /> </span><span >تشير إلى الخدمات المعروضة عبر التطبيق والتي تشمل (شرائح تقليدية، شرائح إلكترونية، باقات انترنت، باقات الهاتف المتنقل، وشحن الرصيد، وغيرها)</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >“</span><span ><span />المحتوى</span><span ><span />”</span><span ><span /> </span><span >يشير إلى محتوى التطبيق والذي يشمل على سبيل المثال لا الحصر (الصور والنصوص والفيديو والمعلومات والبيانات والأسعار وقوائم الخدمات ووصف الخدمات والإعلانات وكافة الأيقونات والرموز والحروف والأرقام)</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >“</span><span ><span />الاتفاقية</span><span ><span />”</span><span ><span /> </span><span >تشير إلى هذه الشروط والأحكام وسياسة الخصوصية وكافة صفحات التطبيق الرئيسية والفرعية ووصف الخدمات وكافة المعلومات ومحتوى الخدمات وكافة التعليمات والاتفاقيات الخاصة التي تتم بين التطبيق من ناحية وأي من مستخدمي التطبيق من ناحية أخرى، وكذلك العقود المرتبطة بالشروط والأحكام</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >“</span><span ><span />أطراف الاتفاقية</span><span ><span />”</span><span ><span /> </span><span >يشير إلى التطبيق من ناحية كـ (طرف أول في العلاقة التعاقدية)، وأي شخص يزور التطبيق أو يستخدمه أو يسجل حساب بالتطبيق أو يقدم أي من خدمات التطبيق أو يستفيد من خدماتنا بأي شكل من الأشكال من ناحية أخرى كـ (طرف ثان في العلاقة التعاقدية)</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >&nbsp;</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >ثانيًا: من نحن؟</span></u></strong>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >تطبيق "تايقو" هو تطبيق مملوك ومدار من شركة مستقبل التواصل، شركة مؤسسة وقائمة بموجب أنظمة المملكة العربية السعودية</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >ثالثًا: خدماتنا</span></u></strong>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            تطبيق<span ><span /> “</span><span >تايقو</span><span ><span />”</span><span > </span>هو وسيط إلكتروني خاص باستعراض معلومات الأسعار والمميزات والأحكام والشروط لخدمات الاتصالات المقدمة من مزودي الخدمة في المملكة العربية السعودية وغيرها، ومنها الخدمات المتصلة بالشرائح التقليدية والشرائح الإلكترونية، وباقات الانترنت، وباقات الهاتف المتنقل، وشحن الرصيد.
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.75pt', paddingRight: '4.65pt'}}>
            <span >من خلال تطبيق</span><span ><span /> “</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> </span><span >تستطيع استعراض جميع تلك الخدمات، والمقارنة بينها، واختيار الباقة والخدمة المناسبة لك، وشراؤها بطرق دفع إلكترونية آمنة، وذلك بعد تسجيل عضوية بالتطبيق للاستفادة من خدماتنا بشكل كامل</span><span ><span />.</span>
          </li>
        </ol>
        <p style={{textAlign: 'justify'}}>
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >رابعًا: الحدود القانونية لخدماتنا</span></u></strong>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يعمل التطبيق كمنصة إلكترونية لعرض الخدمات، فهو بمثابة الأداة الإعلانية الخاصة بنا، التي ننشر من خلالها كافة المعلومات الخاصة بالخدمات المعروضة لمنح العميل استعراض ومقارنة العروض المقدمة من المشغلين لشركة الاتصالات في المملكة العربية السعودية وغيرها<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            تعد الإعلانات عن الخدمات المنشورة عبر التطبيق بمثابة “دعوة للتعاقد” وليس “إيجابًا ملزمًا<span ><span />”</span><span >.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            أنت تعلم وتوافق على أن دور التطبيق يقتصر على تسويق الخدمات الخاصة بمزودي الخدمات، بينما يتم تقديم الخدمة وتفعيلها من جانب مزودي الخدمات، وبالتالي فالتطبيق لا يعد مقدم خدمة وإنما وسيط إلكتروني<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            لا يعد تطبيق<span ><span /> “</span><span >تايقو</span><span ><span />”</span><span > </span>مزود خدمة انترنت، ولا موفر محتوى معلوماتي، ولا يعامل كناشر لأي محتوى يتم نشره عبر التطبيق أو من خلال أي خاصية متاحة للتواصل عبر التطبيق<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            أنت تعلم وتقر بالموافقة على إعفاء تطبيق <span ><span />“</span><span >تايقو</span><span ><span />”</span><span > </span>من أي مسؤولية تقع عن عمل الغير، ولا يسأل تطبيق <span ><span />“</span><span >تايقو</span><span ><span />”</span><span > عن أي أفعال أو تصرفات قانونية أو غير قانونية من الغير.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            تُقدم جميع خدمات تطبيق <span ><span />“</span><span >تايقو</span><span ><span />”</span><span > وفقًا للأنظمة المعمول بها في المملكة العربية السعودية.</span>
          </li>
        </ol>
        <p >
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >خامسًا: الطبيعة القانونية للاتفاقية</span></u></strong>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            تعد هذه الاتفاقية الاتفاق الكامل والنهائي بين تطبيق <span ><span />“</span><span >تايقو</span><span ><span />”</span><span > والمستخدم، فيما يتعلق بالخدمات.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            تعد هذه الاتفاقية عقدًا صحيحًا مكتملاً الشروط والأركان القانونية، نافذًا في مواجهة الأطراف المذكورة، وأحكامه والتزاماته ملزمة لهم جميعًا، ولا يجوز لأحد التحلل منها أو إنهائها طالما أنتجت آثارها القانونية<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يقر جميع الأطراف أن هذه الاتفاقية تشكل كامل الاتفاق بينهم، وقد اتفقوا على أنهم لم يعتمدوا على أي تأكيدات، شفوية كانت أم كتابية، في الموافقة على هذه الاتفاقية بخلاف الأحكام المبينة فيها<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يعد وصف الخدمات والصفحات الفرعية للتطبيق التي تم إعدادها من قِبل تطبيق <span ><span />“</span><span >تايقو</span><span ><span />”</span><span > </span>جزءًا لا يتجزأ من هذه الاتفاقية<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            تعتبر العقود المكملة لهذه الاتفاقية جزءًا لا يتجزأ منها، ويسري عليها ما يسري على هذه الاتفاقية من شروط وأحكام، وترتبط بهذه الاتفاقية ارتباطًا لا يقبل التجزئة وجودًا وعدمًا<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يكون للتعديلات الواردة على هذه الاتفاقية نفس الحكم والأثر القانوني لهذه الاتفاقية<span ><span />.</span>
          </li>
        </ol>
        <p >
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >سادسًا: الموافقة والأهلية القانونية</span></u></strong>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >تقر بأن لديك الأهلية القانونية اللازمة للإبرام والموافقة على هذه الاتفاقية، وأن لديك الصلاحيات القانونية الكاملة غير المقيدة طبقًا للشروط التالية</span><span ><span />:</span>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            أهلية وموافقة الشخص الطبيعي. يشترط في مُستخدِم تطبيق<span ><span /> “</span><span >تايقو</span><span ><span />” </span><span >أن يكون بلغ من العمر 18 عامًا فأكثر وأن يتوفر فيه الأهلية القانونية اللازمة لإبرام العقود، ونحن غير مس</span>ؤولين عن التحقق من أهلية أيًا من مستخدمي التطبيق، وباستخدام خدمات تطبيق<span ><span /> “</span><span >تايقو</span><span ><span />“</span><span >، فأنت توافق على هذه الاتفاقية، وتقر بأنك ملزمًا قانونًا بالشروط والأحكام المنصوص عليها في هذه الوثيقة أو تعديلاتها</span><span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            أهلية وموافقة القاصر (من هم أقل من 18 عام). إذا كنت تحت سن 18 عامًا، يمكنك استخدام خدمات تطبيق<span ><span /> “</span><span >تايقو</span><span ><span />” </span><span >فقط تحت إشراف أحد الوالدين أو الوصي، ومع عدم الإخلال بأي حقوق أخرى لتطبيق</span><span ><span /> “</span><span >تايقو</span><span ><span />” </span><span >بموجب هذه الاتفاقية أو القانون، يحتفظ التطبيق بالحق في تقييد وصولك إلى التطبيق أو إلغاء عضويتك إذا رأى أنك لم تبلغ سن 18 عامًا</span><span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            الصفة القانونية وموافقة المنشآت التجارية. إذا كنت تسجل كمنشأة تجارية، فأنت تقر بأن لديك سلطة إلزام هذه المنشأة باتفاقية المستخدم هذه، وأنك والمنشأة التجارية التي تمثلها سوف تخضعون لجميع القوانين السارية المتعلقة بالتداول عبر شبكة الإنترنت<span ><span />.</span>
          </li>
        </ol>
        <p style={{marginRight: '0.4pt', textAlign: 'justify'}}>
          <span >وفي جميع الأحوال، فأنت توافق على أنك المسؤول الوحيد عن الأفعال أو الإهمال المرتبط بوصولك إلى خدمات تطبيق </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span />.</span>
        </p>
        <p style={{marginRight: '0.4pt', textAlign: 'justify'}}>
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >سابعًا: التسجيل والعضوية</span></u></strong>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يجب أن تفتح حسابًا لدى <span ><span />“</span><span >تايقو</span><span ><span />“</span><span > للاستفادة من خدمات التطبيق.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يشترط أن يُسجل الشخص الطبيعي في التطبيق باسمه الحقيقي، ويُمنع استخدام أي أسماء مستعارة أو غير حقيقية أو مضللة، وبمجرد قيامك بالتسجيل كشخص طبيعي فأنت تمثل نفسك فقط، فالحساب قائم على الاعتبار الشخصي<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يشترط أن يُسجل الشخص المعنوي في التطبيق باسمه التجاري، ويُمنع استخدام أي أسماء مجهولة أو غير حقيقية أو مضللة. وبمجرد قيامك بالتسجيل كشخص معنوي فأنت تقر بأنك الممثل القانوني لهذا الشخص المعنوي<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يجب على المستخدم تزويدنا بمعلوماته عند تسجيل العضوية، والتي قد تشمل على سبيل المثال لا الحصر:
          </li>
        </ol>
        <p className="ListParagraph" style={{marginRight: '36.4pt', marginBottom: '0pt', textIndent: '-18pt', textAlign: 'justify'}}>
          <span >-</span><span style={{width: '13.6pt', font: '7pt "Times New Roman"', display: 'inline-block'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span >اسم المستخدم بالكامل.</span>
        </p>
        <p className="ListParagraph" style={{marginRight: '36.4pt', marginBottom: '0pt', textIndent: '-18pt', textAlign: 'justify'}}>
          <span >-</span><span style={{width: '13.6pt', font: '7pt "Times New Roman"', display: 'inline-block'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span >عنوان المستخدم.</span>
        </p>
        <p className="ListParagraph" style={{marginRight: '36.4pt', marginBottom: '0pt', textIndent: '-18pt', textAlign: 'justify'}}>
          <span >-</span><span style={{width: '13.6pt', font: '7pt "Times New Roman"', display: 'inline-block'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span >رقم تواصل للمستخدم.</span>
        </p>
        <p className="ListParagraph" style={{marginRight: '36.4pt', marginBottom: '0pt', textIndent: '-18pt', textAlign: 'justify'}}>
          <span >-</span><span style={{width: '13.6pt', font: '7pt "Times New Roman"', display: 'inline-block'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span >البريد الإلكتروني للمستخدم.</span>
        </p>
        <p className="ListParagraph" style={{marginRight: '36.4pt', marginBottom: '0pt', textIndent: '-18pt', textAlign: 'justify'}}>
          <span >-</span><span style={{width: '13.6pt', font: '7pt "Times New Roman"', display: 'inline-block'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span >أية بيانات أو معلومات أخرى يطلبها التطبيق.</span>
        </p>
        <ol start={5} style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            العضوية بالتطبيق مجانية ومباشرة للمستخدمين ولا تحتاج إلى مراجعة من جانب إدارة التطبيق<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يتعهد المستخدم بالمحافظة على المعلومات السرية الخاصة بحسابه، مثل اسم المستخدم وكلمة المرور، وأنه مسؤول عن أي إفصاح للغير عن هذه المعلومات، كما أنه مسؤول عن أي استخدام يقع من أي شخص قام بالإفصاح له عن هذه المعلومات السرية<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يتعهد المستخدم بإبلاغ التطبيق فورًا في حالة اختراق أو سرقة الحساب أو اكتشاف أي استخدام غير قانوني لحسابه بالتطبيق، وذلك حتى نستطيع اتخاذ الإجراءات التقنية اللازمة للمحافظة على الحساب<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            لا يجوز لك استخدام حساب شخص آخر في أي وقت دون الحصول على موافقة صريحة من تطبيق<span ><span /> “</span><span >تايقو</span><span ><span />“.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            نحتفظ بالحق في إلغاء الحسابات التي لم يتم تأكيدها أو الغير نشطة لفترة طويلة أو أي حسابات أخرى حسب تقدير <span ><span />“</span><span >تايقو</span><span ><span />“</span><span >.</span>
          </li>
        </ol>
        <p >
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >ثامنًا: التوقيع الرقمي</span></u></strong>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            في الخدمات التي تتطلب التسجيل: فإنه من خلال تسجيلك للحصول على حساب بالتطبيق، أو عن طريق النقر لقبول شروط الخدمة عند المطالبة بذلك على التطبيق، يعتبر أنك قد نفذت هذه الاتفاقية وشروط الخدمة الأخرى إلكترونيًا، وتعد نافذة المفعول قانونًا في مواجهتك من تاريخ تسجيل حسابك أو من تاريخ النقر لقبول شروط الخدمة<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            في الخدمات التي لا تتطلب التسجيل: يعد استخدامك لهذه الخدمات موافقة صريحة منك على الشروط والأحكام المنصوص عليها في هذه الوثيقة كافة السياسات الأخرى، وتعد ملزمًا قانونًا بها من تاريخ هذا الاستخدام<span ><span />.</span>
          </li>
        </ol>
        <p >
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >تاسعًا: سياسة الاستخدام المقبول</span></u></strong>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يقر المستخدم بأنه بموجب موافقته على إبرام هذه الاتفاقية فإنه لن يستند أو يعتمد على أي تعهدات أو ضمانات أو تأكيدات أخرى بواسطة أو بالنيابة عن تطبيق<span ><span /> “</span><span >تايقو</span><span ><span />” </span><span >باستثناء ما هو منصوص عليه في هذه الاتفاقية</span><span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            لا يشمل هذا الترخيص إعادة بيع أو أي استخدام تجاري لأي من خدماتنا أو محتوياتها، كما لا يشمل أي نسخ للمعلومات المتوفرة عن الحساب لصالح الغير، أو أي استخدام لوسائل استخراج البيانات أو أي استخدام لأدوات جمع واستخلاص البيانات المماثلة<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            لا يجوز لك إعادة إنتاج أو عمل نسخة مطابقة لهذا التطبيق، أو نسخ أو بيع أو إعادة بيع أي جزء منه، أو استخدامه بصورة مغايرة في أغراض الاستغلال التجاري أو غير التجاري له دون الحصول على موافقة كتابية صريحة من تطبيق<span ><span /> “</span><span >تايقو</span><span ><span />“.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            لا يجوز لك استخدام أي علامات وصفية أو أي “نص مخفي” آخر يستغل اسم<span ><span /> “</span><span >تايقو</span><span ><span />” </span><span >أو علاماتها التجارية بدون موافقة كتابية صريحة من التطبيق</span><span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يجب أن تكون جميع المعلومات التي تفصح عنها حقيقية ومحدثة وصحيحة وتعبر عنك وتوافق ما هو مطلوب في استمارة التسجيل لدينا<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يتعهد المستخدم بتحري الدقة عند إدخال المعلومات المطلوب إدخالها في التطبيق، ويكون مسؤولاً عن مراجعة تلك المعلومات بشكل دوري بغرض تصحيحها أو تعديلها أو تجديدها كلما توفرت لديك معلومات جديدة بشأنها، على أن تحتفظ بكافة البيانات والمستندات الدالة على ذلك<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            إذا قدمت معلومات غير صحيحة أو غير دقيقة أو غير متزامنة أو غير كاملة، أو إذا كان لدينا أسباب معقولة للاشتباه في أن هذه المعلومات غير صحيحة أو غير دقيقة أو غير متزامنة أو غير كاملة أو لا تتوافق مع اتفاقية الاستخدام هذه، ودون الإخلال بأي حقوق أخرى بموجب هذه الاتفاقية أو القانون، سوف نقوم بتعليق عضويتك أو إلغائها<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            غير مرخص لك بأي شكل من الأشكال نشر أي روابط لمواقع أو تطبيقات أخرى عبر التطبيق أو من خلال أي خواص متاحة داخل التطبيق<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            أنت توافق على عدم استخدام تطبيق<span ><span /> “</span><span >تايقو</span><span ><span />” </span><span >أو أي خدمة تقدم من خلاله بطريقة غير قانونية أو احتيالية أو معادية للمجتمع على النحو الذي نقدره</span><span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '18.4pt', marginBottom: '0pt', textAlign: 'justify', fontFamily: '"DIN Next LT Arabic"'}}>
            يتحمل المستخدمون كافة المسئوليات القانونية في حالة قيامهم بانتهاك أي حقوق ملكية شخصية أو ملكية فكرية على أي من المحتوى الذي نقوم بنشره عبر التطبيق<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '18.4pt', marginBottom: '0pt', textAlign: 'justify', fontFamily: '"DIN Next LT Arabic"'}}>
            نحن غير مسئولين عن قيام المستخدم بانتهاك أي حقوق خاصة بالغير وهي مسئولية المستخدم وحده<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '18.4pt', marginBottom: '0pt', textAlign: 'justify', fontFamily: '"DIN Next LT Arabic"'}}>
            التطبيق لا يتحمل أي مسؤولية بخصوص سوء استخدام المحتوى، وهي مسئولية المستخدم الذي يُسئ استخدام المحتوى، ويعد المستخدم في هذه الحالة مسئولاً في مواجهتنا عن استخدامه للمحتوى الخاص بالتطبيق<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '18.4pt', marginBottom: '0pt', textAlign: 'justify', fontFamily: '"DIN Next LT Arabic"'}}>
            أنت مسؤول عن المحافظة على استخدام التطبيق بكل جدية ومصداقية<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '18.4pt', marginBottom: '0pt', textAlign: 'justify', fontFamily: '"DIN Next LT Arabic"'}}>
            يجوز لك استخدام خدماتنا بالشكل المسموح به قانونًا فقط، ووفقًا لشروط هذه الاتفاقية<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '18.4pt', marginBottom: '0pt', textAlign: 'justify', fontFamily: '"DIN Next LT Arabic"'}}>
            لا يجوز لك إساءة استخدام خدماتنا بأي شكل من الأشكال<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '18.4pt', marginBottom: '0pt', textAlign: 'justify', fontFamily: '"DIN Next LT Arabic"'}}>
            يلتزم المستخدم بإخطارنا في حالة اكتشافه لأي استخدام غير مشروع للتطبيق<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '18.4pt', marginBottom: '0pt', textAlign: 'justify', fontFamily: '"DIN Next LT Arabic"'}}>
            أنت تقر بأنك سوف تقوم بإخطارنا في حالة وجود أي منشورات أو مواد أو معاملات يبدو أنها تنتهك اتفاقية الاستخدام<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '18.4pt', marginBottom: '0pt', textAlign: 'justify', fontFamily: '"DIN Next LT Arabic"'}}>
            قد يقترن استخدام تطبيق<span ><span /> “</span><span >تايقو</span><span ><span />” </span><span >بتوفير خدمات أو محتوى جهات أخرى، والتي لا تخضع لسيطرتنا، وبالتالي فأنت تقر بسريان شروط وأحكام وسياسات خصوصية أخرى على استخدامك لخدمات ومحتوى الجهات غير التابعة لنا</span><span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '18.4pt', marginBottom: '0pt', textAlign: 'justify', fontFamily: '"DIN Next LT Arabic"'}}>
            خدمات التطبيق متوفرة داخل المملكة العربية السعودية وخارجها، وتستطيع الوصول إلى التطبيق من أي مكان في العالم<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '18.4pt', marginBottom: '0pt', textAlign: 'justify', fontFamily: '"DIN Next LT Arabic"'}}>
            تتعهد بتوفير المعلومات والمستندات اللازمة التي يطلبها منك التطبيق في أي وقت؛ سواء أثناء الاشتراك معنا وتسجيل حسابك، أو أثناء تقديم الخدمات لك بعد تسجيل حسابك<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '18.4pt', marginBottom: '0pt', textAlign: 'justify', fontFamily: '"DIN Next LT Arabic"'}}>
            جميع الحقوق غير الممنوحة لك صراحة في شروط الاستخدام هذه أو أي شروط خدمة أخرى يحتفظ بها تطبيق<span ><span /> “</span><span >تايقو</span><span ><span />“.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '18.4pt', textAlign: 'justify', fontFamily: '"DIN Next LT Arabic"'}}>
            تنتهي التراخيص الممنوحة من قِبلنا إذا لم تلتزم بشروط الاستخدام هذه أو أي شروط خدمة أخرى<span ><span />.</span>
          </li>
        </ol>
        <p >
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >عاشرًا: سياسة توفير الخدمات</span></u></strong>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >يحرص تطبيق </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> </span><span >على تقديم كافة التعهدات والضمانات القانونية للمستخدم، طبقًا للأنظمة المعمول بها في المملكة العربية السعودية، وذلك على النحو الآتي</span><span ><span />:</span>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يعرض التطبيق خدمات الاتصالات والإنترنت الخاصة بالشركات المختلفة داخل المملكة العربية السعودية، ويستطيع العميل الدخول على التطبيق وفلترة الخدمات وفقًا لنوعها أو وفقًا للشركة التي تقدمها<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يعلم المستخدم ويوافق على أن كل مزود خدمة له سياسة شروط وأحكام مستقلة به يلتزم المستخدم بها قبل استخدام خدماته المتاحة من خلال التطبيق، وقد يفرض مزود الخدمة شروط خاصة بكل خدمة يقدمها، وقد تختلف شروط الخدمات المتماثلة من مزود خدمة إلى مزود خدمة آخر<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يضمن مزودي الخدمات امتلاكهم لكافة الحقوق والصلاحيات القانونية لبيع الخدمات، كما يضمنوا حصولهم على كافة التصاريح اللازمة لبيع الخدمة<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يعرض <span ><span />“</span><span >تايقو</span><span ><span />”</span><span > </span>أسعار وتفاصيل الخدمات في التطبيق وفقًا لما يوفره مزودي الخدمات، مع مراعاة التحقق من صحة هذه المعلومات، وبموجبه فإنك تقر بموافقتك على بيانات الخدمة بعد عرضها عليك وقيامك بالضغط على خيار (موافق <span ><span />AGREE</span><span >)</span><span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            سيقوم مقدم الخدمة بتوصيل بطاقات الاتصال التي قمت بشرائها خلال مدة لا تتجاوز (24) ساعة من تاريخ إتمام الطلب للمناطق الحضرية، وخلال مدة لا تتجاوز <span ><span />5</span><span >أيام في المناطق النائية. ومع ذلك، يمكن لتطبيق "تايقو" أن يتولى عملية توصيل بطاقات الاتصال في حال الاتفاق على ذلك مع مقدم الخدمة، وخلال المدة سالفة البيان، وسيتم اشعارك في حال قيام تطبيق "تايقو" بتولي عملية التوصيل. </span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يتعهد التطبيق ببذل أقصى الجهود الممكنة للحصول على ضمانات من شركات تشغيل الخدمات، إن وجدت، ويتنازل بموجب هذه الاتفاقية عن تلك الضمانات إلى المستخدم وفقًا للمدة المسموح به<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يحتفظ التطبيق بملكية المحتوى الخاص بالخدمات وكافة الصور والملفات المرئية، ويتمتع بحقوق نشرها في أي وقت<span ><span />.</span>
          </li>
        </ol>
        <p style={{textAlign: 'justify'}}>
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >حادي عشر: سياسة الإلغاء والاسترداد:</span></u></strong>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >عند شرائك للخدمة من مزودي الخدمة، وعدم رضاك التام عنها، فتستطيع تقديم طلب استرجاع الخدمة واسترداد المدفوعات خلال مدة لا تتجاوز (3) ثلاثة أيام من تاريخ الشراء</span><span ><span /> </span><span ><span />في حال عدم تفعيل الخدمة لصالح العميل</span><span >، وفقًا للسياسة التالية:</span>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            خدمات (الشرائح الدولية):
          </li>
        </ol>
        <p className="ListParagraph" style={{marginRight: '36.4pt', marginBottom: '0pt', textIndent: '-18pt'}}>
          <span >-</span><span style={{width: '13.6pt', font: '7pt "Times New Roman"', display: 'inline-block'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span >في حال لم يتم تثبيت الشريحة بالجهاز يمكن إلغاء الشريحة واسترداد المدفوعات بالكامل.</span>
        </p>
        <p className="ListParagraph" style={{marginRight: '36.4pt', marginBottom: '0pt', textIndent: '-18pt'}}>
          <span >-</span><span style={{width: '13.6pt', font: '7pt "Times New Roman"', display: 'inline-block'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span >في حال تم تثبيت الشريحة بالجهاز لا يمكن إلغاء الشريحة ولا يمكن استرداد المدفوعات.</span>
        </p>
        <ol start={2} style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            الشرائح المحلية:
          </li>
        </ol>
        <p className="ListParagraph" style={{marginRight: '36.4pt', marginBottom: '0pt', textIndent: '-18pt'}}>
          <span >-</span><span style={{width: '13.6pt', font: '7pt "Times New Roman"', display: 'inline-block'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span >تخضع لسياسة الإلغاء والاسترداد المطبقة لدى مزود الخدمة (ننصحك بمراجعتها قبل شراء الخدمة).</span>
        </p>
        <ol start={3} style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            خدمة إعادة الشحن:
          </li>
        </ol>
        <p className="ListParagraph" style={{marginRight: '18.4pt', marginBottom: '0pt'}}>
          <span >لا يمكن إلغاء او استرداد المدفوعات بأي حال من الأحوال.</span>
        </p>
        <ol start={4} style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            مدة إعادة المدفوعات:
          </li>
        </ol>
        <p className="ListParagraph" style={{marginRight: '18.4pt', marginBottom: '0pt', textAlign: 'justify'}}>
          <span >سيتم إعادة المدفوعات إلى نفس بطاقة العميل الائتمانية أو الوسيلة التي تم بها الشراء خلال مدة تتراوح بين يوم إلى أربعة عشر يوم عمل.</span>
        </p>
        <ol start={5} style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            التعويضات:
          </li>
        </ol>
        <p className="ListParagraph" style={{marginRight: '18.4pt'}}>
          <span >في حال ثبوت خلل بالخدمة المقدمة لك، يتم تعويضك بخدمة أخرى مماثلة أو إعادة المدفوعات للخدمة المشتراة.</span>
        </p>
        <p >
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >ثاني عشر: سياسة التقييمات</span></u></strong>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >يقدم التطبيق خدمة التقييم وإضافة التعليقات للمستخدمين، وذلك بهدف تقديم خدمة أفضل لجميع عملائنا، ويشترط في إضافة التعليقات الآتي</span><span ><span />:</span>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يجب أن تكون التعليقات صادقة وقانونية ومعبرة عن الخدمة التي تم تقديمها للمستخدم.
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.75pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.65pt'}}>
            <span >يحظر استخدام كلمات أو عبارات أو ألفاظ غير قانونية أثناء عملية التقييم</span><span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يحظر استخدام شعارات أو أسماء مواقع أو تطبيقات أخرى أو علامات تجارية مشهورة أو غير مشهورة أثناء قيامك بعملية التقييم<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يحظر عمل مقارنات بين خدماتنا وخدمات منافسينا بما يسيء لنا أو للمنافسين<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يحظر الإساءة إلى الشعوب أو الدول أو الثقافات أو المجتمع أو أي قيم إنسانية أو اجتماعية أو دينية أثناء عملية التقييم<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يحظر نشر أي إعلانات أو ترويج لسلع أو خدمات من خلال خدمة التقييم الخاصة بالتطبيق الخاص بنا<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يحتفظ التطبيق بالحق في عدم نشر التعليقات إذا كانت غير قانونية أو غير صادقة من وجهة نظرنا<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يحتفظ التطبيق بالحق في حذف أي تعليقات تم نشرها إذا كانت تخالف الأحكام السابقة بصفة خاصة أو أحكام وشروط هذه الاتفاقية بصفة عامة.
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يحتفظ التطبيق بحقه القانوني في اللجوء إلى القضاء في حالة الإساءة إلينا من خلال خدمة التعليقات<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '18.4pt', textAlign: 'justify'}}>
            <span >يحق للغير ممن تم الإساءة إليه من خلال خدمة التعليقات اللجوء إلى القضاء ضد المستخدم الذي قام بإضافة التعليقات غير القانونية وذلك دون الرجوع علينا</span><span ><span />.</span>
          </li>
        </ol>
        <p >
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >ثالث عشر: حقوق التأليف والنشر</span></u></strong>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            التطبيق والأفكار المُعبَر عنها داخله، هي حقوق ملكية فكرية خالصة لنا، وأي تقليد أو اقتباس للتطبيق أو بعض خدماته (بما في ذلك الأفكار والنصوص والرموز والبرمجيات) يعد انتهاكًا لحقوق التأليف والنشر الخاصة بنا، وسنقوم معه باتخاذ كافة الإجراءات القانونية ضد مرتكب الانتهاكات المذكورة<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            جميع المحتويات المضمنة أو المتاحة ضمن خدمات تطبيق <span ><span />“</span><span >تايقو</span><span ><span />”</span><span > مثل (النصوص والشعارات والصور والرسوم البيانية والتسجيلات الصوتية وأيقونات الأزرار والمحتويات الرقمية والمواد التي يتم إعادة تحميلها والبرمجيات وتجميع البيانات) هي علامة مسجلة ومملوكة لشركة مستقبل التواصل</span>&nbsp;وهي محمية من قِبل قوانين المملكة العربية السعودية وقوانين حقوق التأليف والنشر الدولية.
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            تجميع كافة البيانات المدرجة في خدمة<span ><span /> “</span><span >تايقو</span><span ><span />” </span><span >أو إتاحتها من قِبل أيًا من خدماتنا هو ملك حصري وخاص بتطبيق</span><span ><span /> “</span><span >تايقو</span><span ><span />” </span><span >ومحمية بموجب قوانين حقوق الطبع والنشر السعودية والدولية وكذلك محمية بموجب الاتفاقيات الدولية السارية، مثل اتفاقية برن، واتفاقية تريبس</span><span ><span />.</span>
          </li>
        </ol>
        <p >
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >رابع عشر: العلامات التجارية</span></u></strong>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            <span >“</span><span >تايقو</span><span ><span />”</span><span > والشعارات المرتبطة به هي علامات تجارية و/أو علامات خدمة خاصة بنا</span><span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            الصور والشعارات ورؤوس الصفحات وأيقونات الأزرار والنصوص والأسماء الخدمية هي علامات تجارية وتصميمات تجارية خاصة بتطبيق <span ><span />“</span><span >تايقو</span><span ><span />“.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            العلامات التجارية والخدمات المقدمة المملوكة لشركات الاتصالات هي مملوكة لمزودي الخدمة الأساسيين ويعتبر<span ><span />“</span><span >تايقو</span><span ><span />”</span><span > وسيط لإعادة تقديم وعرض هذه الخدمات والعلامات التجارية</span><span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يحظر إعادة إنتاج العلامات أو التصميمات التجارية الخاصة بتطبيق <span ><span />“</span><span >تايقو</span><span ><span />”</span><span > في أي وسيلة إعلامية أو إعلانية دون إذن كتابي منا</span><span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            لا يجوز استخدام العلامات والتصميمات التجارية الخاصة بتطبيق <span ><span />“</span><span >تايقو</span><span ><span />”</span><span > في اتصال بأي منتج أو خدمة ليست تابعة لنا، حتى لا تتسبب في النيل من قدر ومصداقية تطبيق </span><span ><span />“</span><span >تايقو</span><span ><span />”</span><span > أو النيل من عملائها</span><span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            بشكل مطلق، لا يجوز لك تكييف أو استخدام أي اسم، أو علامة، أو شعار مطابق ومشابه بشكل مربك للعلامات التجارية وعلامات الخدمات والشعارات الخاصة بـ <span ><span />“</span><span >تايقو</span><span ><span />”</span><span > و</span>مزودي الخدمات الآخرين.
          </li>
        </ol>
        <p >
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >خامس عشر: الاتصالات</span></u></strong>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            أنت توافق على تلقي الاتصالات منا، وسوف نتواصل معك عن طريق البريد الإلكتروني أو رقم الجوال أو تطبيقات التواصل الحديثة (على سبيل المثال واتساب)، أو عن طريق نشر إشعارات عبر التطبيق أو من خلال خدمات تطبيق<span ><span /> “</span><span >تايقو</span><span ><span />” </span><span >الأخرى</span><span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            أنت توافق على أن جميع الاتفاقات والإشعارات والإفصاحات وغيرها من الاتصالات التي نقدمها لك إلكترونيًا تستوفي كافة الشروط القانونية كما لو كانت هذه الاتصالات خطية، وتقوم مقامها في إنتاج آثارها القانونية<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يتطلب تطبيق<span ><span /> “</span><span >تايقو</span><span ><span />” </span><span >موافقتك أثناء عملية التسجيل على قيامنا بإرسال رسائل على بريدك الإلكتروني أو هاتفك الجوال أو عبر التطبيق لأغراض ترويجية، وذلك لإعلامك بأي تغييرات أو ميزات أو أنشطة جديدة تضاف إلى التطبيق</span><span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.75pt', textAlign: 'justify', paddingRight: '4.65pt'}}>
            <span >إذا قررت في أي وقت أنك لا ترغب في تلقي رسائل ترويجية، يمكنك تعطيل تلقي هذه الرسائل عن طريق مراسلتنا، ولكن في هذه الحالة لا نضمن تمتعك بخدماتنا بشكل كامل</span><span ><span />.</span>
          </li>
        </ol>
        <p >
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >سادس عشر: الإشعارات</span></u></strong>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            أي إشعارات تود إرسالها لتطبيق<span ><span /> “</span><span >تايقو</span><span ><span />” </span><span >يجب أن يتم إرسالها عبر الوسائل المحددة داخل التطبيق، ولا يعتد بأي إشعارات يتم إرسالها خارج التطبيق مع إمكانية تلقي التواصل على المنصات الرسمية المملوكة لـ</span><span ><span /> “</span><span >تايقو</span><span ><span />“.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            أي إشعارات يود تطبيق<span ><span /> “</span><span >تايقو</span><span ><span />” </span><span >إرسالها إليك، تكون إما عن طريق إعلانها على التطبيق أو عن طريق إرسالها إليك عبر رقم الجوال أو البريد الإلكتروني الذي زودتنا بهم خلال عملية التسجيل، ويفترض علمك بالإشعار بمجرد الإعلان على التطبيق، أو بمجرد مرور 24 ساعة من وقت إرسال الرسالة إليك</span><span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.75pt', textAlign: 'justify', paddingRight: '4.65pt'}}>
            <span style={{fontFamily: 'Arial'}}>في حال رفض طلبك من مزود الخدمة أو في حال طلب مستندات إضافية منك، فسنقوم بإشعارك عبر بريدك الإلكتروني بالإضافة إلى إرسال رسالة نصية على رقم جوالك، موضحًا بها رفض الطلب أو المستندات الإضافية المطلوبة منك، ووسيلة تقديمها عبر التطبيق</span>.
          </li>
        </ol>
        <p style={{textAlign: 'justify'}}>
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >سابع عشر: تحويل الحقوق والالتزامات</span></u></strong>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            يسمح لتطبيق <span ><span />“</span><span >تايقو</span><span ><span />”</span><span > نقل أعماله المتعلقة بالخدمات أو أي جزء منها إلى شركة تابعة أو طرف ثالث، بما في ذلك على سبيل المثال لا الحصر، نقلها عن طريق الاندماج أو بيع حقوق الملكية أو بيع لجميع الأصول أو بشكل جوهري أو أي تغيير آخر في المعاملات التي تنطوي على مثل هذه الأعمال.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            بموجبه، يحق لتطبيق <span ><span />“</span><span >تايقو</span><span ><span />”</span><span > تحويل كافة حقوقه الواردة في هذه الاتفاقية للغير</span>، ونقل التفاصيل والمعلومات الخاصة بك إلى الجهة المحال ليها، دون أي اعتراض من المستخدم، ويلتزم الجميع بتنفيذ كافة التزاماتهم الواردة في هذه الاتفاقية قِبل المحال إليه فور إخطارهم بالحوالة، ويحق للمحال إليه اتخاذ كافة الإجراءات القانونية قِبل المتقاعس عن تنفيذ التزاماته بموجب هذه الاتفاقية<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.75pt', textAlign: 'justify', paddingRight: '4.65pt'}}>
            <span >لا يجوز لك التنازل عن التزاماتك وحقوقك بموجب هذه الاتفاقية، أو أن تعهد بإدارة حسابك بالتطبيق إلى طرف ثالث إلا بعد الحصول على موافقة خطية منا</span><span ><span />.</span>
          </li>
        </ol>
        <p >
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >ثامن عشر: المسؤولية القانونية</span></u></strong>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >يجب عليك الالتزام بجميع القوانين واللوائح والقواعد المعمول بها داخل الدولة التي تستخدم التطبيق من خلالها، وذلك فيما يتعلق باستخدامك للتطبيق، وتتحمل كافة المسؤوليات الناشئة في حالة إخلالك بهذه القوانين أو اللوائح أو القواعد، كما تلتزم بجميع الشروط والأحكام المنصوص عليها في هذه الاتفاقية</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >في حالة مخالفة المستخدم لأي من شروط أو أحكام هذه الاتفاقية فيكون من حق تطبيق</span><span ><span /> “</span><span ><span />تايقو</span><span ><span />” </span><span ><span />اتخاذ إجراء إداري داخل التطبيق يتمثل في وقف العضوية لفترة من الزمن أو عمل حظر دائم للمستخدم المخالف، ولا يحق له في هذه الحالة التسجيل في التطبيق مرة أخرى إلا بموافقة صريحة من تطبيق</span><span ><span /> “</span><span ><span />تايقو</span><span ><span />“.</span>
        </p>
        <p >
          <span >يحق لكل طرف اتخاذ كافة الإجراءات القانونية التي يراها مناسبة وفقًا لتقديره دون الرجوع علينا بأي شكل من الأشكال ودون أدنى مسئولية على التطبيق</span><span ><span />.</span>
        </p>
        <p >
          <span >يُسأل العميل بموجب هذه الاتفاقية وبموجب القانون. ويلتزم بتعويضنا في حالة قيامه بتقديم طلبات شراء وهمية أو غير جدية أو غرضها إزعاجنا أو تضييع وقتنا، كما يكون مسئول قانونًا أمام شركات الشحن في هذه الحالة</span><span ><span />.</span>
        </p>
        <p >
          <span >إذا قمت بانتهاك هذه الاتفاقية، فإن تطبيق</span><span ><span /> “</span><span ><span />تايقو</span><span ><span />” </span><span ><span />يحتفظ بحقه في استعادة أية مبالغ مستحقة عليك، وأي خسائر أو أضرار تسببت فيها وللتطبيق الحق في اتخاذ الإجراءات القانونية أو اللجوء للمحاكم المختصة لرفع دعاوى مدنية أو جنائية ضدك</span><span ><span />.</span>
        </p>
        <p >
          <span >تطبيق</span><span ><span /> “</span><span ><span />تايقو</span><span ><span />” </span><span ><span />لا يضمن اتخاذه إجراءات ضد كل الانتهاكات التي قد تحدث لاتفاقية الاستخدام هذه، ولا يعني عدم اتخاذنا للإجراءات القانونية في أي من حالات الانتهاك تنازلاً عن حقنا في اتخاذ هذه الإجراءات في أي وقت نراه مناسبًا</span><span ><span />.</span>
        </p>
        <p >
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >تاسع عشر: حدود مسؤوليتنا</span></u></strong>
        </p>
        <p >
          <span >أنت توافق صراحة على أنك تستخدم تطبيق</span><span ><span /> “</span><span ><span />تايقو</span><span ><span />” </span><span ><span />على مس</span><span >ؤوليتك الشخصية</span><span ><span />.</span>
        </p>
        <p >
          <span >نقدم خدماتنا “كما هي متوفرة”، دون أي تعهدات أو ضمانات من أي نوع، سواء صريحة أو ضمنية، تتعلق باستخدام هذا التطبيق أو محتواه أو الخدمات المقدمة عليه</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >إلى أقصى حد يسمح به النظام/القانون المعمول به، لن يتحمّل تطبيق </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> ولا أي من مسؤوليه أو مديريه أو مساهميه أو موظفيه أو الشركاء أو الوكلاء،</span><span > مسؤولية الخسائر أو الأضرار سواء المباشرة أو غير المباشرة أو التبعية أو العرضية التي تنشأ عن أو بسبب</span><span ><span />:</span>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            المعلومات الخاطئة في الخدمات أو التوصيات التي تتعلق بالخدمات<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            أيّ تعاملات تتم بين مقدمي الخدمات والعملاء خارج نطاق التطبيق<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            جودة الخدمات التي يقدمها مقدمي الخدمات<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            خسارة الأرباح أو المبيعات أو الأعمال أو الإيرادات أو توقف الأعمال أو ما شابه<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            فقدان فرص العمل، أو الشهرة، أو السمعة أو أي خسارة أو ضرر غير مباشر أو لاحق<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            أيّ إفصاح لبيانات العملاء من جانب مقدمي الخدمات<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            تقصير المستخدم في الحفاظ على أمان وسرية وخصوصية بيانات الحساب<span ><span />.</span>
          </li>
        </ol>
        <p style={{textAlign: 'justify'}}>
          <span >التطبيق لا يضمن أن العيوب التقنية سيتم إصلاحها، أو أن التطبيق أو خوادمه خالية من الفيروسات أو أي شيء آخر يكون ضارًا أو مدمرًا، وأنت تعلم أن المواقع على شبكة الإنترنت دائمًا ما تكون عرضة لفساد البيانات أو عدم توفرها أو تأخر ظهورها، وأنت تقبل ذلك</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >التطبيق قد يكون غير متوفر من وقت لآخر بسبب أعمال الإصلاح والصيانة أو التطوير، وأنت توافق على أن التطبيق غير مُلزم بتقديم الدعم الفني في أي وقت من الأوقات</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >تقر بأن تطبيق </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> </span><span >عبارة عن خدمة قائمة على الإنترنت، وأنه على الرغم من بذلنا أقصى جهد للحفاظ على المعلومات وإبقائها آمنة، إلا أننا لا نستطيع أن نضمن أن تكون المعلومات التي يتلقاها المستخدم أو يقوم ببثها أثناء استخدامه للتطبيق آمنة في كل الأوقات</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >لا يقدم تطبيق </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> </span><span >أي ضمان ولا يتحمل أي مسئولية فيما يتعلق بحداثة وشيوع ودقة وجودة المعلومات التي يتلقاها المستخدم أو يتوصل إليها عبر التطبيق</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >تقع مسؤولية استخدام المعلومات التي يتلقاها أو يتوصل إليها المستخدم من خلال خدمات تطبيق </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> </span><span >أو الاعتماد عليها على عاتق هذا المستخدم بشكل كامل</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >تطبيق </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> </span><span >لا يقدم أي ضمانات بخلو هذا التطبيق أو الخوادم الخاصة به أو الرسائل المُرسلة من الفيروسات أو غيرها من المكونات الضارة</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >تطبيق </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> </span><span >لا يستطيع أن يؤكد أن كل مستخدم للتطبيق هو فعلاً الشخص الذي يدعيه</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >لن يكون التطبيق مسؤولاً في جميع الأحوال عن أي خسارة مباشرة أو غير مباشرة أو تبعية أو خسارة في الأرباح والشهرة أو ضرر أيًا كان، نتيجة الإفصاح عن اسم المستخدم الخاص بك و/أو كلمة المرور</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >تطبيق </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> </span><span >غير مسؤول قانونًيا عن الخسائر أو الأضرار أيًا كان نوعها التي قد تنشأ عن استخدام هذا التطبيق بما في ذلك على سبيل المثال لا الحصر الأضرار المباشرة وغير المباشرة</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >قد نقوم في أي وقت بتعديل أو وقف أو قطع خدمات تطبيق </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> </span><span >بدون إرسال إخطار إليك بذلك، كما قد نقوم بوقف استخدامك لخدماتنا إذا قمت بانتهاك هذه الشروط والبنود أو إذا أسأت استخدام هذه الخدمات من وجهة نظرنا</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >مع عدم الإخلال بحقوقه الأخرى فإن لتطبيق </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> </span><span >الحق في وقف أو إلغاء عضويتك أو وصولك إلى التطبيق في أي وقت وبدون إنذار ولأي سبب، ودون تحديد، ويمكنه إلغاء اتفاقية المستخدم هذه</span><span ><span />.</span>
        </p>
        <p >
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >عشرون: التعويضات</span></u></strong>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >أنت توافق على تعويض تطبيق </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> </span><span >وإبراء ذمته ومسؤوليته والدفاع عنه وعن كل طرف تابع لتطبيق </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> </span><span >ضد كافة الدعاوى والمطالبات التي قد يرفعها أو يطالب بها الغير نتيجة استخدامك للتطبيق، أو بسبب قيامك بانتهاك هذه الشروط والبنود أو الإخلال بحقوق المستخدمين الآخرين</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >يستثني التطبيق من ضماناته وبنوده وشروطه أي خسائر مالية قد تلحق بالمستخدم، أو تشويه في السمعة، أو أي أضرار خاصة تنشأ عن سوء استخدامك للخدمات أو التطبيق، وإن التطبيق لا يتحمل أي مسؤوليات أو مطالبات في مثل هذه الحالات</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >لن يكون تطبيق </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> </span><span >ومسؤوليه وموظفيه مسؤولين قانونًا تجاهك أو تجاه أي طرف آخر عن أي خسارة مباشرة أو غير مباشرة أو عن أي تكلفة أخرى قد تنشأ من أو فيما يتصل بتنفيذ هذه اتفاقية، أو فيما يتصل بتقديم خدماتنا</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >على المستخدم أن يحمي تطبيق </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> </span><span >وموظفيه وأن يدافع عنهم ويعوضهم عن أية خسائر أو مصروفات أو مدفوعات بما في ذلك أتعاب المحاماة المعقولة والنفقات القانونية الناتجة عن أية دعوى أو شكوى أو مطالبة تتعلق بالتطبيق أو ناتجة عن عمل أو إهمال من قِبل المستخدم أو ممثليه أو وكلائه</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >أنت ملزمًا بالتعويض عن أي خسائر أو أضرار قد تلحق بالتطبيق نتيجة أي</span><span >&nbsp;</span><span >استخدام غير شرعي أو غير مفوض من قِبلنا</span><span ><span />.</span>
        </p>
        <p >
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >واحد وعشرون: التعديلات</span></u></strong>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >يلتزم المستخدم بما يجريه التطبيق من تغييرات وتعديلات في هذه الاتفاقية والأحكام والشروط المطبقة، وعليه قبول أية تعديلات يراها تطبيق </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> </span><span >لازمة إذا رغب في الاستمرار باستخدام خدمات </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span />، ويجوز لنا في أي وقت إجراء أية تعديلات أو تحسينات نراها ضرورية على التطبيق لزيادة فاعليته، ويلتزم المستخدم بأية توجيهات أو تعليمات يقدمها التطبيق إليه في هذا الخصوص</span><span > إذا رغب في الاستمرار باستخدام خدمات </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >لذلك، عند قيام تطبيق </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> بإجراء تغييرات أو تعديلات في الإفصاح أو شروط هذه الاتفاقية أو تعديل على أي رسوم أو نفقات إضافية مقابل أي خدمات ذات صلة عبر التطبيق، فسيتم اشعارك بها، وأخذ موافقتك عليها صراحة، وبمجرد قبولك لها بالنقر على خيار (موافق </span><span ><span />AGREE</span><span ><span />)، فستكون ملزمًا بالتغييرات والتعديلات.</span>
        </p>
        <p >
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >اثنان وعشرون: إلغاء الاتفاقية</span></u></strong>
        </p>
        <p >
          <span >تعتبر هذه الاتفاقية مفسوخة من تلقاء نفسها دون حاجة إلى أعذار أو اتخاذ إجراءات قضائية في أي من الحالات الآتية</span><span ><span />:</span>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            في حالة ثبوت قيامك بالإخلال بأي فقرة أو بند من بنود هذه الاتفاقية، مع حفظ كافة حقوقنا في المطالبة بالتعويض عن الأضرار المترتبة على ذلك<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            التنازل عن الحساب أو تأجيره من الباطن دون موافقتنا<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            تقاعس المستخدم عن الوفاء بأية التزامات منصوص عليها في هذه الاتفاقية دون أن يتخذ إجراءات جدية لإزالة هذا الإخلال، مع الاحتفاظ بحقنا في المطالبة بالتعويضات إن كان لها محل<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            عدم التمكن من توثيق أي معلومات قمت بتزويدنا بها<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            إذا قررنا في أي وقت أن نشاطاتك قد تتسبب لك أو لمستخدمين آخرين في نزاعات قانونية<span ><span />.</span>
          </li>
        </ol>
        <p >
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >ثلاث وعشرون: روابط المواقع الخارجية</span></u></strong>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            قد يحتوي تطبيق <span ><span />“</span><span >تايقو</span><span ><span />”</span><span > </span>على روابط لمواقع خارجية خاصة بأطراف أخرى، وليس لدينا أي سيطرة على تلك المواقع أو محتوياتها، ولا نتحمل أي مسؤولية عنها أو عن أي خسارة أو ضرر قد ينشأ عن استخدامك لها، وتوافق على عدم إشراكنا في أيّ نزاع قد يكون لديك مع أيّ موقع إلكتروني لأي طرف ثالث<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            قبل تمكين أي من وظائف المشاركة في الموقع للتواصل مع أو زيارة أي موقع من هذه المواقع، نوصيكم بمراجعة وفهم الشروط والأحكام، وسياسة الخصوصية، والإعدادات، ووظائف تبادل المعلومات لكل موقع من مواقع الأطراف الثالثة<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.75pt', textAlign: 'justify', paddingRight: '4.65pt'}}>
            <span >لا تشكل الروابط الموجودة على تطبيق </span><span ><span />“</span><span ><span />تايقو</span><span ><span />”</span><span ><span /> </span><span >موافقة منّا على استخدام مثل هذه المواقع، ويكون استخدامك لهذه المواقع على مسؤوليتك الخاصة</span><span ><span />.</span>
          </li>
        </ol>
        <p >
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >أربع وعشرون: النظام الواجب التطبيق والسلطة القضائية</span></u></strong>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            تخضع هذه الاتفاقية واستخدامك للخدمة، أو أي نزاعات تنشأ بشكل مباشر أو غير مباشر عن هذه الاتفاقية أو لاستخدامك للخدمة، للأنظمة المعمول بها في المملكة العربية السعودية، ويتم تفسيرها وفقًا لها، دون إعمال أي خيار قانوني أو تنازع قواعد أو أحكام القانون، مما قد يؤدي إلى تطبيق قوانين دولة أخرى غير المملكة العربية السعودية.
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            أنت توافق على حل أي نزاع أو مطالبة قد تكون لديك ضد تطبيق <span ><span />“</span><span >تايقو</span><span ><span />”</span><span > </span>حصريًا أمام المحاكم السعودية الموجودة في مدينة الرياض. أنت توافق أيضا، بموجب هذه الشروط على ان تخضع للاختصاص القضائي الحصري لهذه المحاكم بشكل غير قابل للنقض<span ><span /> </span><span >والتنازل عن أي اختصاص قضائي أو مكاني غير ملائم ويعترض على هذه المحاكم.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            إذا تم اعتبار أي بند من هذه الاتفاقية غير قانوني أو غير صالح أو غير قابل للتنفيذ من قبل محكمة مختصة، فيجب عندئذٍ تنفيذ الحكم إلى أقصى حد يسمح به النظام وستظل الأحكام المتبقية من الاتفاقية سارية المفعول والتأثير الكامل.
          </li>
        </ol>
        <p >
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >خمس وعشرون: التعارض</span></u></strong>
        </p>
        <p >
          <span >في حال تعارضت هذه الشروط مع أيّ من إصدارات سابقة لها، فإنّ النسخة الحالية تكون هي السائدة والمعتمدة</span><span ><span />.</span>
        </p>
        <p >
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >ست وعشرون: اللغة</span></u></strong>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >كتبت هذه الشروط باللغتين العربية والإنجليزية، وستعتمد اللغة العربية لغرض تفسير وتنفيذ هذه الشروط أمام جميع الهيئات الرسمية وغير الرسمية إذا تعارضت اللغة الإنجليزية معه</span><span ><span />.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >سبع وعشرون: استقبال ومعالجة الشكاوى</span></u></strong>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            في حال وجود أية شكاوى تتعلق بتوفر خدمات التطبيق، والمشاكل التقنية المتعلقة بها، فلا تتردد في التواصل معنا على مدار الساعة عبر الوسائل المتاحة على التطبيق<span ><span />.</span>
          </li>
          <li  className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            سيقوم تطبيق "تايقو" بالسعي إلى معالجة الشكوى قدر الإمكان، ومع ذلك فإنك تعلم أن مسؤولية معالجة الشكوى إذا كانت تتعلق بخدمات الاتصالات المقدمة من مزودي الخدمة تقع على عاتق مزود الخدمة وليس تطبيق "تايقو"، ومع ذلك سيقوم تطبيق "تايقو" باستقبال الشكوى وتمريرها إلى مزود الخدمة.
          </li>
        </ol>
        <p className="ListParagraph" style={{marginRight: '18.4pt'}}>
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >ثمان وعشرون: معلومات التواصل مع خدمة العملاء مزودي الخدمة</span></u></strong>
        </p>
        <p style={{lineHeight: '108%', fontSize: '12pt'}}>
          <span style={{lineHeight: '108%', fontFamily: '"DIN Next LT Arabic"', fontSize: '11pt'}}>يمكن الوصول الى خدمة العملاء من خلال تطبيق تايقو او قنوات التواصل الرسمية الخاصة بتطبيق تايقو: </span><br /><span style={{lineHeight: '108%', fontFamily: '"DIN Next LT Arabic"', fontSize: '11pt'}}>البريد الاكتروني: </span><a href="mailto:Info@Tygo.sa" style={{textDecoration: 'none'}}><span className="Hyperlink" ><span />Info@Tygo.sa</span></a>
        </p>
        <p >
          <span >رقم واتس اب : </span><span ><span />+966 555701856</span>
        </p>
        <p style={{marginRight: '0.4pt'}}>
          <span >&nbsp;</span>
        </p>
        <p >
          <strong><u><span >تسع وعشرون: الموافقة على الاتفاقية</span></u></strong>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span >أنت تقر بقراءة هذه الشروط وتوافق على الالتزام بأحكامها، وفي حال كان لديك أي أسئلة تتعلق بهذه الاتفاقية، فلا تتردد في التواصل معنا عبر الوسائل المتاحة على التطبيق</span><span ><span />.</span>
        </p>
        <p >
          <span >&nbsp;</span>
        </p>
      </div>
    );
  }


export default TermsAr;
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";

import { useInView } from "react-intersection-observer";


const Animate = ({ children,delay, easing, className }: any) => {
    
    const control = useAnimation();
    const [ref, inView] = useInView();
    const boxVariant = {
        hidden: {
            opacity: 0,
            y: 50,
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delay: delay ? delay : 0,
                duration: 0.5,
                ease: easing ? easing : "easeInOut",
                
            },
        },
    };

  
    useEffect(() => {
      if (inView) {
        control.start("visible");
      }
    }, [control, inView]);
  
    return (
      <motion.div
        className={"box " + className ? className : ""}
        ref={ref}
        variants={boxVariant}
        initial="hidden"
        animate={control}
      >
        {children}
      </motion.div>
    );
  }; 

export default Animate;
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../../components/footer/footer";
import Button from "../../../components/design/buttons/Button";
import { useTranslation } from "react-i18next";
import Header from "../../../components/header/header";
import { HiOutlineThumbUp, HiOutlineUpload } from "react-icons/hi";
import { toast } from "react-toastify";
import SideInfo from "../../store/components/sideInfo";
import { Info } from "../../../components/compare/components/OrderItem";
import { GetAuctionDetails,GetAuctionBids } from "../../../services/auction.service";
import './auctionDetails.css'
import { Logout } from "../../../services/accounts.service";
import { ShimmerTitle } from "react-shimmer-effects-18";

const AuctionDetails = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);
    const [show, setShow] = useState(false);

    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const copyUrl = () => {
        navigator.clipboard.writeText(window.location.href);
        toast.success(t("details.copied"));
    }
    const handleShow = () => setShow(true);
    const [plan, setPlan] = useState(
        {
            description: "test",
            duration: 2,
            endTime: "0001-01-01T00:00:00",
            id: 1,
            imageUrl: "string",
            insuranceAmount: 10,
            minBid: 500,
            mobileNumber: "555555555",
            name: "test",
            providerId: 9,
            startTime: "2024-02-20T08:10:41.296"
          }
    );
    // fetchAuctionDetails(id);
    // fetchAuctionBids(id);
    const [bids, setBids] = useState<any>([]);
    const fetchAuctionDetails = async (id: string) => {
        const response = GetAuctionDetails(id, i18n.language);
        response.then((res) => {
            // setPlan(response.data.data);
            setPlan(res.data.data);
            setIsLoading(false);
        }, (err) => {
            Logout(navigate);
        });
    }

    const fetchAuctionBids = async (id: string) => {
        // const response = await GetAuctionBids(id, i18n.language);
        // setBids(response.data.data);
        const response = GetAuctionBids(id, i18n.language);
        response.then((res) => {
            setBids(res.data.data);
            setIsLoading2(false);
        }, (err) => {
            Logout(navigate);
        });

    }

    useEffect(() => {
        fetchAuctionDetails(id as string);
        fetchAuctionBids(id as string);
    }
    , [id]);

    

    return (
        <>
        <div className="container" style={{minHeight: "calc(100vh - 380px)"}}>
          <Header />
          <h2 className="mt-5 pt-4 text-center mx-auto" style={{maxWidth:"600px", }}>{t("auction.title")}</h2>
        
          <div className="d-flex mt-4 mb-5 pt-2 flex-wrap justify-content-between">
            
          <Button color="circle "   isfullWidth={false} onClick={()=>{
                navigate(-1);
            }} inner={
               <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: i18n.language === "ar" ? "rotate(180deg)" : ""}}>
               <path d="M15.95 31.3669C16.2666 31.3669 16.5833 31.2502 16.8333 31.0002C17.3166 30.5169 17.3166 29.7169 16.8333 29.2335L7.59997 20.0002L16.8333 10.7669C17.3166 10.2835 17.3166 9.48353 16.8333 9.0002C16.35 8.51686 15.55 8.51686 15.0666 9.0002L4.94996 19.1169C4.46663 19.6002 4.46663 20.4002 4.94996 20.8835L15.0666 31.0002C15.3166 31.2502 15.6333 31.3669 15.95 31.3669Z" fill="#292D32"/>
               <path d="M6.1165 21.25H34.1665C34.8498 21.25 35.4165 20.6833 35.4165 20C35.4165 19.3167 34.8498 18.75 34.1665 18.75H6.1165C5.43317 18.75 4.8665 19.3167 4.8665 20C4.8665 20.6833 5.43317 21.25 6.1165 21.25Z" fill="#292D32"/>
               </svg>
               
            } />
             <div className="row mb-3">
                <div className="col-6">
                <Button inner={
                    <div className="d-flex align-items-center justify-content-center" style={{width: "max-content"}}>
                        <HiOutlineThumbUp style={{fontSize: "1.5rem"}} />
                        <p className="mx-3 my-0">{t("details.like")}</p>
                    </div>

                } onClick={function (): void {
                        throw new Error('Function not implemented.');
                    } } color="outline" isfullWidth={true} />

                </div>
                <div className="col-6">
                <Button inner=
                {
                    <div className="d-flex align-items-center justify-content-center">
                        {/* <i className="fas fa-share-alt"></i> */}
                        <HiOutlineUpload style={{fontSize: "1.5rem"}} />
                        <p className="mx-3 my-0">{t("details.share")}</p>
                    </div>
                } onClick={copyUrl}
                 color="outline" isfullWidth={true} />
                </div>
            </div>
          </div>
                <div className="row">
                    <div className="col-12 col-lg-4">
                    <SideInfo 
                            background="https://images.unsplash.com/photo-1491884662610-dfcd28f30cfb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2624&q=80"
                            is_bg={true}
                            elements={[
                                <div className="plan-item m-0 p-0">
                                {
                                    !isLoading?
                                <>
                                <h3 >{plan.mobileNumber}</h3>
                                <span className='price_item'>{plan.minBid} {t('global.sar')}</span>
                                </>:
                                <ShimmerTitle line={2} gap={10} variant="primary" />
                                }
                                <div className="d-flex  gap-2 mt-4">
                                {
                                    !isLoading?
                                <Info name={t('details.validity')}
                                 logo="/media/svgs/clock_.svg" info={"vali" + " " + t('plans.days')} />
                                :
                                <div className="w-100">
                                <ShimmerTitle line={2} gap={10} variant="primary" />
                                </div>

                                }
                                 {
                                    !isLoading?

                                <Info name= {t('store.network')} logo="/media/svgs/rss.svg" info={"prov"} />
                                :
                                <div className="w-100">
                                <ShimmerTitle line={2} gap={10} variant="primary" />
                                </div>
                                }

                                </div>
                                
                                </div>,
                                <Button isfullWidth={true} color={"primary py-3"} inner={<div className="d-flex">
                                    <p className="font-weight-light m-0">{t("details.download")}</p>
                                </div>} onClick={
                                    handleShow
                                } />
                            ]} noBg={[1]}
                    />
                    </div>
                    <div className="col-12 col-lg-8">
                        <RefreshedTime date={new Date(plan.endTime)} isLoading={isLoading} />
                        <span className="top_bids">Top 5 bids</span>
                    
                    {
                        !isLoading2?    
                        bids.sort((a: any, b: any) => b.price - a.price).slice(0, 5).map((bid: any, index: number) => {
                            return <div key={index} className="d-flex white_box_for_auction justify-content-between align-items-center my-3">
                               <div className="d-flex gap-2 align-items-center px-1">

                                {
                                     (index === 0)?
                                     <img src="/media/svgs/first.svg" alt=""  height="32" width="32" />
                                     :
                                     <span style={{width:"32px"}}>0{index + 1}</span>
                                }
                                <span className="user_name">
                                    <div className="avatar">
                                        {bid.userId.toString().substring(0, 2).toUpperCase()}
                                    </div>
                                    {bid.userId}
                                </span>
                               </div>

                                <span className="price">{bid.price} {t("global.sar")}</span>
                            </div>
                        })
                        :
                        <div className="w-100">
                            <div className="d-flex white_box_for_auction justify-content-between align-items-center my-3">
                                <ShimmerTitle line={1} gap={10} variant="primary" />
                            </div>
                        </div>
                        
            
                    }  
                    </div> 
                </div>
            
          
        </div>
        <Footer />
        </>
    );
    }


    const RefreshedTime = ({date, isLoading} : {date: Date, isLoading:Boolean}) => {
        const {t} = useTranslation();
        const [time, setTime] = useState(new Date());
        useEffect(() => {
          const interval = setInterval(() => {
            // date - current date
            const fromNowuntilDate = date.getTime() - new Date().getTime();
            setTime(new Date(fromNowuntilDate));
          }, 1000);
          return () => clearInterval(interval);
        }, []);
        return <div className="d-flex mb-5">
        {/* days, hours, minutes, seconds */}
        <div className="w-100  d-flex align-items-center gap-3 justify-content-center gap-3">
            <div className="d-flex flex-column white_box_for_auction align-items-center w-100">
                {
                    !isLoading?
                    <h3>{time.getDate() - 1}</h3> 
                    :
                    <div className="w-100">
                    <ShimmerTitle line={1} gap={10} variant="primary" />
                    </div>
                }
                <p className="mb-0">{t("auction.days")}</p>
            </div>
            <div className="d-flex flex-column white_box_for_auction align-items-center w-100">
                {
                    !isLoading?
                    <h3>{time.getHours()}</h3>
                    :
                    <div className="w-100">
                    <ShimmerTitle line={1} gap={10} variant="primary" />
                    </div>
                }
                <p className="mb-0">{t("auction.hours")}</p>
            </div>
            <div className="d-flex flex-column white_box_for_auction align-items-center w-100">
                {
                    !isLoading?
                    <h3>{time.getMinutes()}</h3>
                    :
                    <div className="w-100">
                    <ShimmerTitle line={1} gap={10} variant="primary" />
                    </div>
                }
                <p className="mb-0">{t("auction.minutes")}</p>
            </div>
            <div className="d-flex flex-column white_box_for_auction align-items-center w-100">
                {
                    !isLoading?
                    <h3>{time.getSeconds()}</h3>
                    :
                    <div className="w-100">
                    <ShimmerTitle line={1} gap={10} variant="primary" />
                    </div>
                }
                <p className="mb-0">{t("auction.seconds")}</p>
            </div>
        </div>
    </div>
      }
    
export default AuctionDetails;


import React, { useContext, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CompareCardContext } from "../../../components/compare/CompareCard";
import Dragable from "../../../components/compare/Dragable";
import Button from "../../../components/design/buttons/Button";
import Card from "../../../components/design/cards/card";
import Footer from "../../../components/footer/app_footer";
import { getComparePlans } from "../../../services/compare.service";
import { getProviders } from "../../../services/providers.service";
import { getPlanbyId } from "../../../services/stepper.service";
import ProgressBar from "../../stepper/components/ProgressBar";
import PlanBar from "../components/planBar";
import PlanHeader from "../components/planHeader";
import './compare_adv.css';




const CompareAdvScreen = () => {
    const navigate = useNavigate();
    const {t,i18n} = useTranslation();
    const [lastdata, setLastData] = React.useState<any>([]);
    const [dataHeader, setDataHeader] = React.useState<any>(null);
    const id = useParams().id;
    const {addToCart,plans} = useContext(CompareCardContext);
    const [plansData, setPlansData] = React.useState<any>([]);
    const [plansRData, setPlansRData] = React.useState<any>([]);

   

    const feachData = async () => {
        const response = await getComparePlans(plans.map((item:any) => item.id),i18n.language);
        // const response = await getComparePlans(plans.slice(0,3).map((item:any) => item.id));

        try {
            let maxOncalls = 0;
            let maxOncallsOut = 0;
            let maxOncallsAfter = 0;
            let maxOnsms = 0;
            let maxOnsmsOut = 0;
            let maxOnsmsAfter = 0;
            let maxOninternet = 0;

            const providers = (await getProviders(i18n.language)).returnData;


            response.returnData.selectedProviderPlan.map((item:any) => {
                if((item.minutesInSideProvider > maxOncalls && maxOncalls != -1) || item.minutesInSideProvider == -1){
                    maxOncalls = item.minutesInSideProvider;
                }
                if((item.minutesOutSideProvider > maxOncallsOut && maxOncallsOut != -1) || item.minutesOutSideProvider == -1){
                    maxOncallsOut = item.minutesOutSideProvider;
                }
                if((item.localCallsAfterFreeMinutes > maxOncallsAfter && maxOncallsAfter != -1) || item.localCallsAfterFreeMinutes == -1){
                    maxOncallsAfter = item.localCallsAfterFreeMinutes;
                }
                if((item.smSForCurrentProvider > maxOnsms && maxOnsms != -1) || item.smSForCurrentProvider == -1){
                    maxOnsms = item.smSForCurrentProvider;
                }
                if((item.smSForOtherProvider > maxOnsmsOut && maxOnsmsOut != -1) || item.smSForOtherProvider == -1){
                    maxOnsmsOut = item.smSForOtherProvider;
                }
                if((item.smSAfterFreeMinutes > maxOnsmsAfter && maxOnsmsAfter != -1) || item.smSAfterFreeMinutes == -1){
                    maxOnsmsAfter = item.smSAfterFreeMinutes;
                }
                if((item.localInternt > maxOninternet && maxOninternet != -1) || item.localInternt == -1){
                    maxOninternet = item.localInternt;
                }
            })
            // item.minutesInSideProvider / Math.abs(maxOncalls) * 100
            console.log(response.returnData.selectedProviderPlan.map((item:any) => {
                return [
                    (item.minutesInSideProvider/1000) / Math.abs(maxOncalls) * 100,
                    item.minutesInSideProvider,
                    Math.abs(maxOncalls) * 100,
                    maxOncalls

                ]
            }))

            // (item.localInternt ? item.localInternt != -1 ? item.localInternt / Math.abs(maxOninternet) * 100 : 160 : 0) > 160 ? 140 : (item.localInternt ? item.localInternt != -1 ? item.localInternt / Math.abs(maxOninternet) * 100 : 160 : 0)
            console.log(response.returnData.selectedProviderPlan.map((item:any) => {
                return [
                    item.localInternt ? item.localInternt != -1 ?  (item.localInternt/1000/100) * 100
                    : 160 : 0,
                    item.localInternt,
                    Math.abs(maxOninternet) * 100,
                    maxOninternet
                ]
            }))

                    
            const tempPlans = response.returnData.selectedProviderPlan.map((item:any) => {
                return {
                    id: item.id,
                    name: item.name,
                    image: item.imageUrl,
                    title: item.name,
                    type: item.planTypeName,
                    price: item.price,
                    calls: item.minutesInSideProvider ? item.minutesInSideProvider  != -1 ? (item.minutesInSideProvider/1000) * 100 : 160 : 0,
                    callsOutside: item.minutesOutSideProvider ? item.minutesOutSideProvider != -1  ? item.minutesOutsideProvider / Math.abs(maxOncallsOut) * 100 : 160 : 0,
                    callsAfterFreeMinutes: item.localCallsAfterFreeMinutes ? item.localCallsAfterFreeMinutes != -1 ? item.localCallsAfterFreeMinutes / Math.abs(maxOncallsAfter) * 100 : 160 : 0,
                    sms: item.smSForCurrentProvider ? item.smSForCurrentProvider != -1 ? item.smSForCurrentProvider / Math.abs(maxOnsms) * 100 : 160 : 0,
                    smsAfterFreeMinutes: item.smSAfterFreeMinutes ? item.smSAfterFreeMinutes != -1 ? item.smSAfterFreeMinutes / Math.abs(maxOnsmsAfter) * 100 : 160 : 0,
                    smsOtherProvider: item.smSForOtherProvider ? item.smSForOtherProvider != -1 ? item.smSForOtherProvider / Math.abs(maxOnsmsOut) * 100 : 160 : 0,
                    messages: item.smSForCurrentProvider  != undefined ? item.smSForCurrentProvider == -1 ? "Unlimited" : item.smSForCurrentProvider + " Message" : "N/A",
                    messagesAfterFreeMinutes: item.smSAfterFreeMinutes != undefined ? item.smSAfterFreeMinutes == -1 ? "Unlimited" : item.smSAfterFreeMinutes + " Message"  : "N/A",
                    messagesOtherProvider: item.smSForOtherProvider != undefined ? item.smSForOtherProvider == -1  ? "Unlimited" : item.smSForOtherProvider + " Message"  : "N/A",
                    localCalls: item.minutesInSideProvider != undefined ? item.minutesInSideProvider == -1 ? "Unlimited" : item.minutesInSideProvider + " Minutes" : "N/A",
                    calls_: item.minutesOutSideProvider != undefined ? item.minutesOutSideProvider == -1 ? "Unlimited" : item.minutesOutSideProvider + " Minutes" : "N/A",
                    callsAfterFreeMinutes_: item.localCallsAfterFreeMinutes != undefined ? item.localCallsAfterFreeMinutes == -1 ? "Unlimited" : item.localCallsAfterFreeMinutes + " Minutes" : "N/A",
                    internet: item.localInternt ? item.localInternt != -1 ?  (item.localInternt/1000/100) * 100
                    : 160 : 0,
                    internet_: item.localInternt != undefined ? item.localInternt == -1 ? "Unlimited" : item.localInternt/1000 + " GB" : "N/A",
                    // localCalls: item.minutesInSideProvider != undefined ? item.minutesInSideProvider == -1 ? "Unlimited" : item.minutesInSideProvider + " Minutes" : "N/A",
                    // calls are a precentage of the max calls
                    validityTimeInDays: item.validityTimeInDays != undefined ? item.validityTimeInDays == -1 ? "Unlimited" : item.validityTimeInDays + " Days" : "N/A",
                    // "#"ffffff"" to "#ffffff"
                    color: item.primaryColor.replace("\"", "").replace("\"", "") == "#ffffff" ? providers.filter((provider:any) => provider.id == item.providerId)[0].primaryColor  : item.primaryColor.replace("\"", "").replace("\"", ""),
                }
            })

            //see if the calls are above 160
            tempPlans.map((item:any) => {
                if(item.calls > 160){
                    item.calls = 140;
                }
                if(item.callsOutside > 160){
                    item.callsOutside = 140;
                }
                if(item.callsAfterFreeMinutes > 160){
                    item.callsAfterFreeMinutes = 140;
                }
                if(item.internet > 160){
                    item.internet = 140;
                }
                if(item.sms > 160){
                    item.sms = 140;
                }
                if(item.smsAfterFreeMinutes > 160){
                    item.smsAfterFreeMinutes = 140;
                }
                if(item.smsOtherProvider > 160){
                    item.smsOtherProvider = 140;
                }
            })

    
            const a = response.returnData.recommendedProviderPlan.map((plan: any) => {
                return {
                    id: plan.id,
                    name: plan.name,
                    image: plan.imageUrl,
                    title: plan.name,
                    type: plan.planTypeName,
                    price: plan.price.toLocaleString() + ' SAR',
                    messages: plan.smSForCurrentProvider == -1 ? "Unlimited" : (plan.smSForCurrentProvider).toLocaleString() + " Message",
                    internet: plan.localInternt == -1 ? "Unlimited" : (plan.localInternt/1000).toLocaleString() + " GB",
                    calls: plan.minutesInSideProvider == -1 ? "Unlimited" : plan.minutesInSideProvider + " Minutes",
                }
            });
            // console.log(tempPlans.map((item:any) => item.color));
            // console.log(tempPlans.map((item:any) => item.callsOutside));
            // console.log(tempPlans.map((item:any) => item.calls));
            // console.log(tempPlans.map((item:any) => item.sms));
            // console.log(tempPlans.map((item:any) => item.smsAfterFreeMinutes));
            // console.log(tempPlans.map((item:any) => item.smsOtherProvider));
            
    
    
            if (response) {
                setPlansData(tempPlans);
                setPlansRData(a);
            }
        } catch (error) {
            console.log(error);
        }

        // get the container position and add padding to the recomended plans
        const container = document.getElementsByClassName("container")[0];
        if (container) {

            const containerPosition = container.getBoundingClientRect();
            const recomendedPlans = document.getElementById("recomendedPlans");
            const comparePlans = document.getElementById("comparePlans");
            if (recomendedPlans && comparePlans) {
                // .plans-recommendation>h5 {
                //     /* padding-left: 252px; */
                // }

                // .plans-recommendation>.d-flex {
                //     padding-left: 252px;
                //     overflow-x: scroll;
                // }
                
                //get the first h5 element and add padding to it
                const h5 = recomendedPlans.getElementsByTagName("h5")[0];

                if (h5) {
                    h5.style.paddingLeft = containerPosition.left + "px";
                }

                //get the first div element and add padding to it
                const div = recomendedPlans.getElementsByTagName("div")[0];
                
                if (div) {
                    div.style.paddingLeft = containerPosition.left + "px";
                    div.style.overflowX = "scroll";
                }

                // //get the first div element and add padding to it
                // const div2 = comparePlans.getElementsByTagName("div")[0];

                // if (div2) {
                //     div2.style.paddingLeft = containerPosition.left + "px";
                //     div2.style.overflowX = "scroll";
                // }

                // first-column

                // .first-column {
                //     padding-left: 
                // }

                const style = document.createElement('style');
                style.innerHTML = `
                    .first-column {
                        min-width: ${containerPosition.left+ 32 + 326}px !important; 
                    }
                `;
                document.head.appendChild(style);





            }
        
        }
    }


  

      

    useEffect(() => {
        
        feachData();
    }, []);
    return (
        <>
        <div className="bg-vv">

            <div className="position-fixed w-100">
            <div className="bg-center d-none d-lg-flex" >
                <div className="circle_bg">
                    <div className="circle_bg">
                        <div className="circle_bg">
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className="container" style={{minHeight: "100%"}}>
            <div className="d-flex container align-items-center justify-content-between py-4">
            <div className="d-flex align-items-center my-4">
                <Button color="circle "   isfullWidth={false} onClick={()=>{
                navigate(-1);
            }} inner={
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: i18n.language == "ar" ? "rotate(180deg)" : ""}} >
            <path d="M15.95 31.3669C16.2666 31.3669 16.5833 31.2502 16.8333 31.0002C17.3166 30.5169 17.3166 29.7169 16.8333 29.2335L7.59997 20.0002L16.8333 10.7669C17.3166 10.2835 17.3166 9.48353 16.8333 9.0002C16.35 8.51686 15.55 8.51686 15.0666 9.0002L4.94996 19.1169C4.46663 19.6002 4.46663 20.4002 4.94996 20.8835L15.0666 31.0002C15.3166 31.2502 15.6333 31.3669 15.95 31.3669Z" fill="#ffffff"/>
            <path d="M6.1165 21.25H34.1665C34.8498 21.25 35.4165 20.6833 35.4165 20C35.4165 19.3167 34.8498 18.75 34.1665 18.75H6.1165C5.43317 18.75 4.8665 19.3167 4.8665 20C4.8665 20.6833 5.43317 21.25 6.1165 21.25Z" fill="#ffffff"/>
            </svg>
            
            } />
                <strong className=" ms-3 text-white">
                    {t("global.back")}
                </strong>
            </div>
            
            </div>
            <div className="d-none d-lg-block">
            <span className="text-white text-center">
            <h1>{t("compare.best_for_you")} </h1>
            <p className="text-white text-center mb-3">{t("compare.subtitle")}</p>
            
            </span>

                <div className="row w-100 align-items-center justify-content-center">
                {
                        plansData.map((item:any, index:number) => {
                            return (
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div style={{maxWidth: "422px", scale: "0.95"}} >
                                    <Card className="" inner={
                                    <div className="element-plan column-title py-3" key={index}>
                                    <div className="d-flex align-items-start">
                                        <img src={item.image
                                        } alt="" width="50" height="50" style={{borderRadius: "8px"}} />
                                        <div className="d-flex flex-column mx-3">
                                            <strong className="">{item.title}</strong>
                                            <p className="text-muted">{item.type}</p>
                                            <strong className="price">{item.price} {t("plans.sar")} </strong>
                                        </div>
                                    </div>
                                </div>
                                }
                                />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
                <div className="d-flex align-items-center justify-content-center">
                
                <div className="row w-100">
                    {
                        [{
                            title: t("compare.calls_inside"),
                            key: "calls",
                            key_2: "localCalls",
                        },
                        // {
                        //     title: "Calls ( outside network )",
                        //     key: "callsOutside",
                        //     key_2: "calls_",
                        // },
                        // {
                        //     title: "Calls ( after free minutes )",
                        //     key: "callsAfterFreeMinutes",
                        //     key_2: "callsAfterFreeMinutes_",
                        // },
                        {
                            title: t("compare.message_inside"),
                            key: "sms",
                            key_2: "messages",
                        },
                        
                        // {
                        //     title: "SMS ( outside network )",
                        //     key: "smsOtherProvider",
                        //     key_2: "messagesOtherProvider",
                        // },
                        // {
                        //     title: "SMS ( after free minutes )",
                        //     key: "smsAfterFreeMinutes",
                        //     key_2: "messagesAfterFreeMinutes",
                        // },
                        {
                            title: t("compare.internet"),
                            key: "internet",
                            key_2: "internet_",
                        },

                        
                    ].map((item:any, index:number) => {
                            return (
                                <div className="col-12 col-md-6 col-lg-4 mb-3">
                                    
                                    
                                    <div className="adv_compare">
                                    <span style={{fontWeight:"500"}}>{item.title}</span>
                                    <div className="plans_map">
                                        {/* <div className="plans_item">
                                            <div className="color"></div>
                                            <div className="title">{item.title}</div>
                                        </div> */}
                                        {
                                            plansData.map((plan:any, index:number) => {
                                                return (
                                                    <div className="plans_item">
                                                        <div className="color" style={{backgroundColor: plan.color}}></div>
                                                        <div className="title_ mx-3">{plan.name}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                        <div className="chart">
                                            <ul className="lines">
                                                <li className="line l--100">
                                                <span className="line__label">
                                                     {t("plans.unlimited")}
                                                </span>
                                                </li>
                                                <li className="line l--75">
                                                <span className="line__label">
                                                    100{item.key === "internet" ? "" : "0"}
                                                </span>
                                                </li>
                                                <li className="line l--50">
                                                <span className="line__label">
                                                    50{item.key === "internet" ? "" : "0"}
                                                </span>
                                                </li>
                                                <li className="line l--25">
                                                <span className="line__label">
                                                    25{item.key === "internet" ? "" : "0"}
                                                </span>
                                                </li>
                                                <li className="line l--0">
                                                <span className="line__label">
                                                    0
                                                </span>
                                                </li>
                                                
                                            </ul>
                                            
                                            {/* <span className="chart__title">{item.title}</span> */}
                                            <div className="charts">
                                                <div className="chart chart--dev">
                                                <ul className="chart--horiz">
                                                    {
                                                        plansData.map((plan:any, index:number) => {
                                                            return (
                                                                <OverlayTrigger key={index} placement="left" overlay={
                                                                    <Tooltip id={`tooltip-${index}`}>
                                                                        {plan[item.key_2]}
                                                                    </Tooltip>
                                                                }>

                                                                <li className="chart__bar" style={{height: plan[item.key] + '%',
                                                                    // background: linear-gradient(to left, #4cb8c4, #3cd3ad);
                                                                    // background: `linear-gradient(to left, ${plan.color}, ${plan.color})`,
                                                                    backgroundColor: plan[item.key] ? plan.color ? plan.color : "#4cb8c4" : "#e0e0e0",
                                                            }}>
                                                               
                                                                </li>
                                                                </OverlayTrigger>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        })
                    }
                    
                </div>
                </div>
            </div>
        </div>

        </>
    );
    
};

export default CompareAdvScreen;

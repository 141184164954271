import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
// import s'./Store.css';
import React, { useState } from 'react';
import {  getPlans } from '../../services/plans.service';
import { useTranslation } from 'react-i18next';
import PlanItem, { Info } from '../store/components/PlanItem';
import Button from '../../components/design/buttons/Button';
import { HiOutlineThumbUp } from 'react-icons/hi';
import SideInfo from '../store/components/sideInfo';
import './Order.css'
import { IoIosArrowForward, IoIosClipboard, IoIosDocument } from 'react-icons/io';
import { IoCopy, IoCopyOutline } from 'react-icons/io5';
import Animate from '../about/Animate';
import { Carousel } from 'react-bootstrap';
import { getCountryById, getProviderById } from '../../services/providers.service';
import { createOrder, getOrderById } from '../../services/orders.service';
import Card from '../../components/design/cards/card';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from "react-toastify";

const Order = () => {
    
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const [order, setOrder] = React.useState<OrderType>();
    const itemId = useParams().item;
    const [page, setPage] = React.useState<any>(1);
    const [plans, setPlans] = React.useState<any>([]);
    const {t,i18n} = useTranslation();
    const [plan, setPlan] = React.useState<any>(null);
    const [country, setCountry] = React.useState<any>(null);
    const updatePlans = async (
        {
            page,
            country,
        }: any = {
            page: 1,
            country: id,
        }
    ) => {
        const dat = await getPlans({
            countryId: country,
            offset: 1,
            limit: 6,
        }, i18n.language);
        setPlans(dat.returnData);
    }

    const updatePlan = async (
        {
            planId
        }: any 
    ) => {
        getPlans({
            planId,
        }, i18n.language).then(async (dat: any) => {
            const provider = await getProviderById(dat.returnData[0].providerId, i18n.language);
            setPlan({
                ...dat.returnData[0],
                provider,
            });
            console.log(dat.returnData[0]);
        });
        


    }
    const GetOrder = async () => {
        if(!id)
        {
            
            const order__ = JSON.parse(localStorage.getItem('order')!);
            let dat = (await getOrderById(+order__.id!, i18n.language)) as any;
            const order_ = dat.data.returnData.orderDeitalsDtos[0];
            console.log(order_);
            setOrder(
                {
                        messages: order_.smSForCurrentProvider === -1 ? t("plans.unlimited") : order_.smSForCurrentProvider + " " + t("plans.message"),
                        internet: order_.localInternt === -1 ? t("plans.unlimited") : order_.localInternt/1000 + " " + t("plans.gb"),
                        calls: order_.minutesInSideProvider === -1 ? t("plans.unlimited") : order_.minutesInSideProvider + " " + t("plans.minutes"),
                        ...order_,
                }
               
            );
            const country_id = dat.data.returnData.orderDeitalsDtos[0].countryId;
            const resss = await getCountryById(country_id, i18n.language);
            setCountry(resss.data.returnData[0]);
                        // const order_details = JSON.parse(localStorage.getItem('order_details')!);
            // order_.price = order_details.amount ? order_details.amount : '';
            // order_.qty = order_details.qty ? order_details.qty : '';
            
            // setOrder(order_.map((item: any) => {
            //     return {
            //         ...item,
            //         messages: item.smSForCurrentProvider === -1 ? t("plans.unlimited") : item.smSForCurrentProvider + " " + t("plans.message"),
            //         internet: item.localInternt === -1 ? t("plans.unlimited") : item.localInternt/1000 + " " + t("plans.gb"),
            //         calls: item.minutesInSideProvider === -1 ? t("plans.unlimited") : item.minutesInSideProvider + " " + t("plans.minutes"),
            //     }
            // }
            // ));
            return;
        }
        let dat = (await getOrderById(+id!, i18n.language)) as any;
        const order_ = dat.data.returnData.orderDeitalsDtos[0];
        console.log(order_);
        setOrder(
            {
                    messages: order_.smSForCurrentProvider === -1 ? t("plans.unlimited") : order_.smSForCurrentProvider + " " + t("plans.message"),
                    internet: order_.localInternt === -1 ? t("plans.unlimited") : order_.localInternt/1000 + " " + t("plans.gb"),
                    calls: order_.minutesInSideProvider === -1 ? t("plans.unlimited") : order_.minutesInSideProvider + " " + t("plans.minutes"),
                    ...order_,
            }
           
        );
        const country_id = dat.data.returnData.orderDeitalsDtos[0].countryId;
        const resss = await getCountryById(country_id, i18n.language);
        setCountry(resss.data.returnData[0]);
    }

    React.useEffect(() => {
        GetOrder();
    
        if(itemId)
        updatePlan({
            planId: itemId,
        });
        
        updatePlans();
    }
    , [id, page, i18n.language, itemId]);
    const isMobile = window.innerWidth < 992;
    if(!order)
    return <></>;

    return (
        <>
        <div style={{backgroundImage: "url(/media/hero/bg2.png)", backgroundRepeat:"no-repeat", backgroundSize: "cover", backgroundPosition: "top"}}>
        <div className="container">
            <Header />
            <div className="row store" style={{minHeight: "80vh"}}>
            <div className="col-lg-4  col-sm-12 col-xs-12 order-lg-1 order-1 order-lg-2 order-xs-2">
                <div className="side_info_item m-0 mb-3" style={{boxShadow: "0px 0px 40px 0px #00000010"}}>
                            <GetPDFReceipt plan={plan} order={order} />
                </div>
                        
                </div>
                <div className="col-lg-8  col-sm-12 col-xs-12 order-lg-2 order-2 order-lg-1 order-xs-1">
                    {/* back button */}
                    
                    {/* header */}
                    <OrderHeader />
                    {/* <CountryHeader /> */}
                    <div className="order_tabs my-4">
                    <h4>{t("store.install_esim")}</h4>
                    {/* <p>{t("store.step_1")}</p> */}
                    <TabsInfo order={order} />
                    {/* <p>{t("store.step_2")}</p>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="input_gray">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span>
                                    {t("store.celluar_data")}
                                    </span>
                                    <span className='d-flex align-items-center'>
                                        <span>{t("store.on")}</span>
                                        <IoIosArrowForward />
                                    </span>
                                </div>
                            </div>
                            {t("store.access_data")}
                        </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="input_gray">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span>
                                    {t("store.celluar_data")}
                                    </span>
                                    <span className='d-flex align-items-center'>
                                        <span>{t("store.on")}</span>
                                        <IoIosArrowForward />
                                    </span>
                                </div>
                            </div>
                            {t("store.access_data")}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="input_gray">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span>
                                    {t("store.celluar_data")}
                                    </span>
                                    <span className='d-flex align-items-center'>
                                        <span>{t("store.on")}</span>
                                        <IoIosArrowForward />
                                    </span>
                                </div>
                            </div>
                            {t("store.access_data")}
                        </div>
                    </div> */}
                    </div>
                    {/* plans */}
                   
                    {/* pagination */}
                    {/* <div className="pagination">
                        <div className="pagination-item" onClick={() => setPage(page - 1)}>Prev</div>
                        <div className="pagination-item" onClick={() => setPage(page + 1)}>Next</div>
                    </div> */}
                    
                </div>
               
            </div>

        </div>
        </div>
        <Footer />
        </>
    );
}

const OrderHeader = () => {
    const {t,i18n} = useTranslation();
    return (
        <div className="header_order">
            <div className="d-flex">
            <div className="d-flex flex-column justify-content-start align-items-start gap-2">
                <strong>{t("store.the_faster")}</strong>
                <p>{t("store.you_have_signed")}</p>
                <div className='row mb-3'>
                  <div className='col-6'>
                    <a href="https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone](https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone)" target="_blank">
                    <img src="/media/buttons/apple.svg" width={124} />
                    </a>
                  </div>
                  <div className='col-6'>
                    <a href='https://play.google.com/store/apps/details?id=app.tygo.sa' target="_blank">
                    <img src="/media/buttons/google.svg" width={124} />
                    </a>
                  </div>
                </div>
            </div>
            <img src="/media/order_page.png" width={300} className='d-none d-lg-block' />
            </div>
        </div>
    );
}

// const Get PDF Receipt
const GetPDFReceipt = ({
    plan,
    order
}: {
    plan?: any,
    order?: any
}) => {
    const isMobile = window.innerWidth < 992;
    const {t,i18n} = useTranslation();
    // if(!plan) return null;
    return (
        <div className="get_pdf">
             <div className="table_info">
                                <div>
                                    <img src="/media/gifs/done.gif" width={isMobile ? 64 : 200} className={!isMobile ? "d-block m-auto" : ""} />
                                    {  !isMobile && <><br/></>}
                                    <strong>{t("store.payment_success")}</strong>
                                </div>
                                {
                                    !isMobile && <>
                                    
                                <div className="d-flex justify-content-between table_item">
                                    <span>{t("store.price")}</span>
                                    <span>{order.price} {t('global.sar')}</span>
                                </div>
                                <div className="d-flex justify-content-between table_item">
                                    <span>{t("store.quantity")}</span>
                                    <span>{order.qty}</span>
                                </div>
                                <div className="d-flex justify-content-between table_item">
                                    <span>{t("store.fees")}</span>
                                    <span>{0} {t('global.sar')}</span>
                                </div>
                                <div className="devider"></div>
                                <div className="d-flex justify-content-between ">
                                    <span>{t("store.total")}</span>
                                    <strong>{order.price} {t('global.sar')}</strong>
                                </div>
                                </>
                                }
                                {/* <Button inner={
                                    <>
                                    <img src="/media/svgs/download.svg" />
                                    {t("store.get_pdf_receipt")}
                                    </>
                            } color="outline" isfullWidth={true} /> */}

                            </div>
        </div>
    );
}


function TabsInfo({order}:any) {
    const {t,i18n} = useTranslation();
    const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success(t("global.copied_to_clipboard"));
};
  return (
    // Install eSim / Access Data
    <>
    <Tabs
      defaultActiveKey="manual"
      id="uncontrolled-tab-example"
      className="mb-3"
      
    //   fill
    >
<Tab eventKey="manual" title={t("store.manual"+'')}>
        <div className="row">
        <div className="col-12 col-xs-12">
                <div className="input_gray">
                <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column">
                    <span>
                    {t("store.sm")}
                    </span>
                    <span className='text-muted'>
                    {order.sM_DP_Address}
                    </span>
                </div>
                <IoCopyOutline size={22} onClick={() => copyToClipboard(order.sM_DP_Address)} style={{cursor: "pointer"}} />
                </div>
                </div>
            </div>
            <div className="col-12 col-xs-12">
                <div className="input_gray">
                <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column">
                    <span>
                    {t("store.activation_code")}
                    </span>
                    <span className='text-muted'>
                    {order.activationCode}
                    </span>
                </div>
                <IoCopyOutline size={22} onClick={() => copyToClipboard(order.activationCode)} style={{cursor: "pointer"}} />
                </div>
                </div>
            </div>
            <div className="col-12 col-xs-12">
                <div className="input_gray">
                <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column">
                    <span>
                    {t("store.sm")}
                    </span>
                    <span className='text-muted'>
                    {order.sM_DP_Address}
                    </span>
                </div>
                <IoCopyOutline size={22} onClick={() => copyToClipboard(order.sM_DP_Address)} style={{cursor: "pointer"}} />
                </div>
                </div>
            </div>
           
            <Slider
            inners={[[
                {
                    title: t("esim.section5.element1.item1.title"),
                    content: t("esim.section5.element1.item1.sub_title"),
                    image_en: t("esim.section5.element1.item1.image"),
                    image_ar: t("esim.section5.element1.item1.image")
                },
                {
                    title: t("esim.section5.element1.item2.title"),
                    content: t("esim.section5.element1.item2.sub_title"),
                    image_en: t("esim.section5.element1.item2.image"),
                    image_ar: t("esim.section5.element1.item2.image")
                },
                {
                    title: t("esim.section5.element1.item3.title"),
                    content: t("esim.section5.element1.item3.sub_title"),
                    image_en: t("esim.section5.element1.item3.image"),
                    image_ar: t("esim.section5.element1.item3.image")
                } ,
                {
                    title: t("esim.section5.element1.item4.title"),
                    content: t("esim.section5.element1.item4.sub_title"),
                    image_en: t("esim.section5.element1.item4.image"),
                    image_ar: t("esim.section5.element1.item4.image")

                },
                {
                    title: t("esim.section5.element1.item5.title"),
                    content: t("esim.section5.element1.item5.sub_title"),
                    image_en: t("esim.section5.element1.item5.image"),
                    image_ar: t("esim.section5.element1.item5.image")
                },
            ],[
                {
                    title: t("esim.section5.element2.item1.title"),
                    content: t("esim.section5.element2.item1.sub_title"),
                    image_en: t("esim.section5.element2.item1.image"),
                    image_ar: t("esim.section5.element2.item1.image")
                },
                {
                    title: t("esim.section5.element2.item2.title"),
                    content: t("esim.section5.element2.item2.sub_title"),
                    image_en: t("esim.section5.element2.item2.image"),
                    image_ar: t("esim.section5.element2.item2.image")
                },
                {
                    title: t("esim.section5.element2.item3.title"),
                    content: t("esim.section5.element2.item3.sub_title"),
                    image_en: t("esim.section5.element2.item3.image"),
                    image_ar: t("esim.section5.element2.item3.image")

                },
                {
                    title: t("esim.section5.element2.item4.title"),
                    content: t("esim.section5.element2.item4.sub_title"),
                    image_en: t("esim.section5.element2.item4.image"),
                    image_ar: t("esim.section5.element2.item4.image")
                },
                {
                    title: t("esim.section5.element2.item5.title"),
                    content: t("esim.section5.element2.item5.sub_title"),
                    image_en: t("esim.section5.element2.item5.image"),
                    image_ar: t("esim.section5.element2.item5.image")
                    
                }
            ]]}
            seperator={<div className="slider-seperator">
                <div className="slider-seperator__title">
                    <h1>Choose your plan</h1>
                </div>
                <div className="slider-seperator__content">
                    <p>Choose your plan and get your eSIM</p>
                </div>
            </div>
            }
            />
          

        </div>

      </Tab>
      <Tab eventKey="qr" title={t("store.qr_code"+'')}>
        <div className="qr_code d-flex flex-column align-items-center justify-content-center gap-5 my-5">
            <h1>{t("store.scan")}</h1>
            {imageError ? (
        <img src="/media/qrerror.svg" width={200} />
      ) : (
        <img
          src={order?.qrCode}
          width={200}
          onError={handleImageError}
          alt="QR Code"
        />
      )}<p><img src="/media/svgs/download_.svg" width={20} /> {t("store.save_as_image")}</p>
        </div>

      </Tab>
      
    </Tabs>
    </>
  );
}

const Slider =(
    props: {
        inners: any[][],
        seperator: any,
    }
)=> {
    const [index, setIndex] = React.useState(0);
    const [active, setActive] = React.useState(0);
    const [mobile, setMobile] = React.useState(false);
    const {t, i18n} = useTranslation();
  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
  };

    React.useEffect(() => { 
        if (window.innerWidth < 992) {
            setMobile(true);
        }
    }, [])


    return (
            <Animate delay={0.1}>
            {/* <div className=''> */}
                        {/* <Animate delay={0.2}>
                        <div className="d-flex my-4 gap-3" style={{maxWidth: "520px", margin: "0 auto"}}>
                        <Button inner={t("esim.section5.ios")}
                        onClick={
                            () => {
                                setActive(0)
                            }
                        } color={active === 0 ? "primary" : "secondary"}
                        isfullWidth={true} isrounded={true}/>  
                        <Button inner={t("esim.section5.android")}
                                                    onClick={
                            () => {
                                setActive(1)
                            }
                        } color={active === 1 ? "primary" : "secondary"}
                        isfullWidth={true} isrounded={true}/>
                        </div>
                        </Animate> */}
                            <Tabs
      defaultActiveKey="ios"
      id="uncontrolled-tab-example_"
      className="mb-3"
      
    //   fill
    >
                    <Tab eventKey="ios" title={t("esim.section5.ios")}>

                                            {/* <Animate delay={0.3}> */}
                                                {/* <Carousel activeIndex={index} onSelect={handleSelect} controls={false} interval={null} indicators={false}> */}
                                                <Carousel activeIndex={index} onSelect={handleSelect} interval={null} controls={false} indicators={false}>
                                                    {props.inners[0].map((inner, index) => {
                                                        return (
                                                            
                                                            <Carousel.Item>
                                                                <img src={i18n.language === "en" ? inner.image_en : inner.image_ar} 
                                                                height={"384px"} style={{objectFit: "contain"}} className="d-block w-100 my-4" alt="..."/>
                                                                {/* {inner.image} */}
                                                                {/* './media/slider/1.png' */}
                                                            </Carousel.Item>
                                                        )
                                                    })}
                                                </Carousel>
                                            <strong className='d-block m-auto text-center'>
                            
                            <span>{props.inners[0][index].title}</span>
                            <br/>
                            <span style={{fontWeight:"lighter"}}>{props.inners[0][index].content}</span>
                        </strong>
                    </Tab>
                    <Tab eventKey="android" title={t("esim.section5.android")}>
                    <Carousel activeIndex={index} onSelect={handleSelect} interval={null} controls={false} indicators={false}>
                                                    {props.inners[1].map((inner, index) => {
                                                        return (
                                                            
                                                            <Carousel.Item>
                                                                <img src={i18n.language === "en" ? inner.image_en : inner.image_ar} 
                                                                height={"384px"} style={{objectFit: "contain"}} className="d-block w-100 my-4" alt="..."/>
                                                                {/* {inner.image} */}
                                                                {/* './media/slider/1.png' */}
                                                            </Carousel.Item>
                                                        )
                                                    })}
                                                </Carousel>
                                                <strong className='d-block m-auto text-center'>
                            
                            <span>{props.inners[1][index].title}</span>
                            <br/>
                            <span style={{fontWeight:"lighter"}}>{props.inners[1][index].content}</span>
                        </strong>
                    </Tab>
                      
                    {/* <Tab eventKey="android" title={t("esim.section5.android")}>
                    <Animate delay={0.3}>
                            <Carousel activeIndex={index} onSelect={handleSelect} controls={false} interval={null} indicators={false}>
                            <Carousel activeIndex={index} onSelect={handleSelect} interval={null} controls={false} indicators={false}>
                                {props.inners[1].map((inner, index) => {
                                    return (
                                        
                                        <Carousel.Item>
                                            <img src={inner.image_mobile} 
                                            height={"384px"} style={{objectFit: "contain"}} className="d-block w-100 my-4" alt="..."/>
                                        </Carousel.Item>
                                    )
                                })}
                            </Carousel>
                        <Animate delay={0.4}>
                       
                        </Animate>                                   
                        </Carousel>
                        </Animate>
                    </Tab> */}
                    </Tabs>
                    <Animate delay={0.5}>
                        <div className='d-flex my-4 justify-content-center justify-content-center'>
                            {Array.from({length: props.inners[0].length}).map((_, index_) => {
                                return (
                                        
                                        <div className='me-3' onClick={() => setIndex(index_)} style={{cursor: "pointer", width: "32px", height: "6px", borderRadius: "3px", backgroundColor: index === index_ ? "#3EB6AE" : "#DADADA"}}></div>
                                )
                            })}
                        </div>    
                        </Animate>   
                    
            {/* </div> */}
            </Animate>
    );

}
class OrderType {

    imageUrl: string;
    planName: string;
    contractTypeName: string;
    price: number;
    internet: number;
    calls: number;
    messages: number;
    activationCode: string;
    bookedDate: string;
    checkOutId: string;
    checkOutPlans: any;
    coverage: string;
    createdAt: string;
    eSimImportId: number;
    esimStatistic: any;
    esimStatisticId: number;
    esimStatus: any;
    esimStatusId: number;
    expritationDate: any;
    iccid: string;
    id: number;
    importedDate: string;
    isDeleted: boolean;
    isKyc: boolean;
    isUnlimited: boolean;
    isVisible: boolean;
    mobileNumber: string;
    network: string;
    ownerId: string;
    planId: number;
    purchasePrice: number;
    qrCode: string;
    remainingData: number;
    sM_DP_Address: string;
    totalData: number;
    updatedAt: any;
    usedData: number;
    constructor(
        activationCode: string,
        bookedDate: string,
        checkOutId: string,
        checkOutPlans: any,
        coverage: string,
        createdAt: string,
        eSimImportId: number,
        esimStatistic: any,
        esimStatisticId: number,
        esimStatus: any,
        esimStatusId: number,
        expritationDate: any,
        iccid: string,
        id: number,
        importedDate: string,
        isDeleted: boolean,
        isKyc: boolean,
        isUnlimited: boolean,
        isVisible: boolean,
        mobileNumber: string,
        network: string,
        ownerId: string,
        planId: number,
        purchasePrice: number,
        qrCode: string,
        remainingData: number,
        sM_DP_Address: string,
        totalData: number,
        updatedAt: any,
        usedData: number,
        imageUrl: string,
        planName: string,
        contractTypeName: string,
        price: number,
        calls: number,
        messages: number,
        internet: number
    ) {
        this.activationCode = activationCode;
        this.bookedDate = bookedDate;
        this.checkOutId = checkOutId;
        this.checkOutPlans = checkOutPlans;
        this.coverage = coverage;
        this.createdAt = createdAt;
        this.eSimImportId = eSimImportId;
        this.esimStatistic = esimStatistic;
        this.esimStatisticId = esimStatisticId;
        this.esimStatus = esimStatus;
        this.esimStatusId = esimStatusId;
        this.expritationDate = expritationDate;
        this.iccid = iccid;
        this.id = id;
        this.importedDate = importedDate;
        this.isDeleted = isDeleted;
        this.isKyc = isKyc;
        this.isUnlimited = isUnlimited;
        this.isVisible = isVisible;
        this.mobileNumber = mobileNumber;
        this.network = network;
        this.ownerId = ownerId;
        this.planId = planId;
        this.purchasePrice = purchasePrice;
        this.qrCode = qrCode;
        this.remainingData = remainingData;
        this.sM_DP_Address = sM_DP_Address;
        this.totalData = totalData;
        this.updatedAt = updatedAt;
        this.usedData = usedData;
        this.imageUrl = imageUrl;
        this.planName = planName;
        this.contractTypeName = contractTypeName;
        this.price = price;
        this.internet = internet;
        this.calls = calls;
        this.messages = messages;

    }


}
export {Slider};
export default Order;

//for profrssionals page

import  { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import './Professionals.css';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Phone from "./graph/Phone";
import { Modal } from "react-bootstrap";
import Terms, { TermsInner } from "../terms/terms";
import i18n from "../../../config/i18n";
import { useForm, ValidationError } from '@formspree/react';
import Animate from "../about/Animate";
import React from "react";
import { getProviders } from "../../services/providers.service";
import { toAbsoluteUrl } from "../../tools/AssetHelpers";

// Cannot find name 'TweenMax
// import LocomotiveScroll from 'locomotive-scroll';
// import 'react-locomotive-scroll/dist/locomotive-scroll.min.css';

const Professionals = () => {
    const {t, i18n} = useTranslation();
    const [providers, setProviders] = React.useState<any>([]);
    const [loading, setLoading] = React.useState(true);

    const fetchProviders = async () => {
        const response = await getProviders(i18n.language);
        // make the id 9 in place of 16 and 16 in place of 9
        console.log(response.returnData);
        const temp = response.returnData[7];
        console.log(temp);
        response.returnData[7] = response.returnData[2];
        response.returnData[2] = temp;
        console.log(response.returnData);
        setProviders(response.returnData.slice(0, 10));
        setLoading(false);
    }
   


    React.useEffect(() => {
        fetchProviders();
    }
    , [])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {

    }, [])

    const [isAnimating, setIsAnimating] = useState(false)


    return (
        <>
        <div className="professionals__section">

            <div className='container '>
                <Header />
                <div className="row h-100 prof py-5">
          <div className="col-lg-6 col-12 p-0">
            <Forum animate={isAnimating} setAnimate={

                (value: boolean) => 
                {
                    setIsAnimating(value)
                }
            }
            />
          </div>
          {/* <div className="col-lg-6 col-12 p-0 d-none d-lg-block">
            <Phone animate={isAnimating} setAnimate={setIsAnimating}/>
          </div> */}
          <div className='col-12 col-lg-6 order-1 order-lg-2 justify-content-center d-flex align-items-center'>
              <Animate delay={0.5} >
                <div className="position-relative bgas"
                 style={{backgroundImage:'url("./media/landing/hero/bg.svg")', backgroundSize: '130% 126%',
                 backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                {
                    !loading &&
                    <div className="container_circles">
                    {
                        providers.map((provider: { imagePathSmall: string; }) => {
                            return (
                                <div style={{backgroundImage: "url("+toAbsoluteUrl(provider.imagePathSmall)+")"}}>
                      
                                </div>
                            )
                        })
                    }
                    <div  style={{backgroundImage: "url(./media/logos/default-dark.svg)", backgroundSize: "70%", backgroundRepeat: "no-repeat"}}></div>
                    
                </div>

                }
                </div>
                </Animate>
          </div>
        </div>

            </div>
        </div>
                {/* 
                <div className="professionals__section">
                    <div className="professionals__wrapper container h-100">
                      <Header />
                      <Hero />
                    </div>
                </div>
                  <div className='buttom_bg'>
                  <svg  viewBox="0 0 2579 370" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.669983 0L498.094 331.465C547.169 364.168 607.12 376.295 665.046 365.238L2578.6 0H0.669983Z" fill="#2f1952"/>
                  </svg>
                </div>
                <div className='container mt-5'>
                  
                <Contact />
                <Partners />
                </div> 
                */}

            
            <Footer />
        </>
    )
}

export default Professionals;

 


const Forum = ({ animate, setAnimate }: { animate: boolean; setAnimate: any }) => {
    const [stepIndex, setStepIndex] = useState(0);
      const [serviceIndex, setServiceIndex] = useState(0);
      const [choiceIndex, setChoiceIndex] = useState(0);
  
      interface ServiceOptionData {
        capacity: number;
        onNetMin: number;
        offNetMin: number;
        onNetSMS: number;
        offNetSMS: number;
        price: number;
      }
      
      interface Service {
        title: string;
        description: string;
        image: string;
        optionText: string;
        options?: string[];
        optionsData?: ServiceOptionData[];
        outerElement?: JSX.Element;
      }
      
      interface Step {
        title?: string;
        description?: string;
        child: () => JSX.Element;
      }
      const {t, i18n} = useTranslation();

      const services: Service[] = [
        {
          title: t("business.services.routerTitle")+"",
          description: t("business.services.routerDescription")+"",
          image: "icons/router (1) 1.svg",
          optionText: t("business.services.speed")+"",
          options: [
            "4G ( "+t("global.unlimited")+" )",
            "5G ( "+t("global.unlimited")+" )",
            "2 "+t("global.GB"),
            "5 "+t("global.GB"),
            "10 "+t("global.GB"),
            "50 "+t("global.GB"),
            "100 "+t("global.GB"),
            "300 "+t("global.GB"),
            "600 "+t("global.GB")
          ],
        },
        {
          title: t("business.services.fiberTitle")+"",
          description: t("business.services.fiberDescription")+"",
          image: "icons/optical-fiber 1.svg",
          optionText: t("business.services.speed")+"",
          options: ['500 ' + t("business.services.speed")+'', '200 ' + t("business.services.speed")+'', '100 ' + t("business.services.speed")+''],
          
        },
        {
          title: t("business.services.simTitle")+"",
          description: "",
          image: "icons/simcard.svg",
          optionText: t("business.services.choosePlan")+"",
          optionsData: [
            {
              capacity: 100,
              onNetMin: 2411,
              offNetMin: 425,
              onNetSMS: 5362,
              offNetSMS: 632,
              price: 2.62,
            },
            {
              capacity: 100,
              onNetMin: 2411,
              offNetMin: 425,
              onNetSMS: 5362,
              offNetSMS: 632,
              price: 2.62,
            },
            {
              capacity: 100,
              onNetMin: 2411,
              offNetMin: 425,
              onNetSMS: 5362,
              offNetSMS: 632,
              price: 2.62,
            },
            {
              capacity: 100,
              onNetMin: 2411,
              offNetMin: 425,
              onNetSMS: 5362,
              offNetSMS: 632,
              price: 2.62,
            },
          ],
          outerElement: (
            <div className="form-group mb-3">
              <label htmlFor="exampleTextField">{t("business.services.simNumber")}</label>
              <input
                type="number"
                className="form-control"
                id="exampleTextField"
                placeholder="1"
              />
            </div>
          ),
        },
      ];
    
      const steps : Step[] = [
        // {
        //   title: t("business.services.tygoBusiness")+"",
        //   description: t("business.services.regularUse")+"",
        //   child: () => (
        //     <Services
        //       services={services}
        //       stepIndex={stepIndex}
        //       setStepIndex={setStepIndex}
        //       setServiceIndex={setServiceIndex}
        //     />
        //   ),
        // },
        // {
        //   title: services[serviceIndex].title,
        //   description: services[serviceIndex].description,
        //   child: () => (
        //     <ServiceOptions
        //       stepIndex={stepIndex}
        //       setStepIndex={setStepIndex}
        //       choiceIndex={choiceIndex}
        //       setChoiceIndex={setChoiceIndex}
        //       serviceIndex={serviceIndex}
        //       services={services}
        //     />
        //   ),
        // },
        {
          title: t("business.login.title")+"",
          description: t("business.login.description")+"",
          child: () => <Login stepIndex={stepIndex} setStepIndex={setStepIndex} />,
        },
        {
          child: () => (
            <div className="d-flex h-100 align-items-center">
              <div className="pb-5 fs-4 text-center">
                <img src="/media/done.gif" className="w-50"/>
                <br/>{
                t("business.done")
                }
              </div>
            </div>
          ),
        },
      ];
      
      
      const [innerComponent, setInnerComponent] = useState(<></>);
    
      useEffect(() => {
        setInnerComponent(steps[stepIndex].child);
      }, [stepIndex, choiceIndex]);
    
      useEffect(() => {
        setAnimate(true);
      }, [stepIndex])
    
      return (
        <div className="from-wrapper">
        <div className="card-bg d-flex p-4 flex-column align-items-start row-gap-3 overflow-x-hidden overflow-y-auto">
                  {/* <Indicators active={stepIndex} length={3} /> */}
                  {innerComponent && (
                    <InnerContent
                      step={steps[stepIndex]}
                      stepIndex={stepIndex}
                      setStepIndex={setStepIndex}
                      child={innerComponent}
                    />
                  )}
                </div>
        </div>
      );
      }
      const CheckBox = ({ checked } : {checked: boolean}) => {
        return (
          // <label class="check_box my-2 p-lg-4 p-3 align-items-center " style="min-height: 80px;"><input type="checkbox" value="4"><span class="mx-1"></span><span class="">just the basics</span></label>
          <div className="checkbox">
            <div className={`checked ${checked ? "" : "hidden"}`}></div>
          </div>
        );
      };
      
      const Services = ({ services, stepIndex, setStepIndex, setServiceIndex } : { services: any,
        stepIndex: number, setStepIndex: Dispatch<SetStateAction<number>>, setServiceIndex: Dispatch<SetStateAction<number>> }) => {
        const { t, i18n } = useTranslation();
        const onStepIndexChange = (index: number) => {
          setStepIndex(index);
        };
      
        const onServiceIndexChange = (index: number) => {
          setServiceIndex(index);
        };
      
        return (
          <div className="services">
            <h2 className="fs-5 fw-semibold my-4">
              {t("business.services.services")}
              </h2>
            <div className="list row align-items-stretch">
              {services.map((item: any, index: number) => (
                <div className="col-12 col-sm-6 pb-4 d-box">
                  <div
                    className="service h-100 d-flex p-4 position-relative flex-column align-items-start"
                    key={index}
                    onClick={() => {
                      onServiceIndexChange(index);
                      onStepIndexChange(stepIndex + 1);
                    }}
                  >
                    <img className="logo" src={item.image} alt={item.title} />
                    <h3 className="fs-6 fw-semibold">{item.title}</h3>
                    <img className="next" src={"icons/arrow-" + i18n.dir() + ".svg"} alt="next" />
                    
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      };
      
      const ServiceOptions = ({
        stepIndex,
        setStepIndex,
        choiceIndex,
        setChoiceIndex,
        serviceIndex,
        services,
      } : {
        stepIndex: number,
        setStepIndex: Dispatch<SetStateAction<number>>,
        choiceIndex: number,
        setChoiceIndex: Dispatch<SetStateAction<number>>,
        serviceIndex: number,
        services: any
      }) => {
        const OnChoiceClicked = (index: number) => {
          setChoiceIndex(index);
        };
        const { t, i18n } = useTranslation();
        return (
          <>
            <div className={"options mb-2 pt-2 overflow-y-auto position-relative flex-grow-1" + (services[serviceIndex].optionsData != null ? " options-data" : "")}>
              {services[serviceIndex].outerElement != null ? (
                services[serviceIndex].outerElement
              ) : (
                <></>
              )}
              <h2 className="fs-5 py-2 fw-semibold">
                {services[serviceIndex].optionText}
              </h2>
              {services[serviceIndex].options != null ? (
                <div className="togglesList" style={{ height: 0 }}>
                  {services[serviceIndex].options.map((option: any, index: number) => (
                    <div
                      className="option d-flex column-gap-3 align-items-center"
                      key={index}
                      onClick={() => {
                        OnChoiceClicked(index);
                      }}
                    >
                      <CheckBox checked={index == choiceIndex} />
                      <p>{option}</p>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  className="containersList d-flex flex-column align-items-start row-gap-4"
                  style={{ height: 0 }}
                >
                  {services[serviceIndex].optionsData.map((option: any, index: number) => (
                    <div
                      className="option d-flex p-4 align-items-center w-100 fw-medium rounded-3"
                      key={index}
                      onClick={() => {
                        OnChoiceClicked(index);
                      }}
                    >
                      <div className="capacity d-flex align-items-center column-gap-3">
                        <CheckBox checked={index == choiceIndex} />
                        <p>{option.capacity} {t("global.GB")}</p>
                      </div>
                      <div className="net">
                        <div className="row">
                        <p className="netlabel my-1 my-sm-0 text-center col-12 col-md-6">
                          {t("business.services.onNetMinutes")} :{" "}
                          <span className="netvalue">{option.onNetMin}</span>
                        </p>
                        <p className="netlabel my-1 my-sm-0 text-center col-12 col-md-6">
                        {t("business.services.offNetMinutes")} :{" "}
                          <span className="netvalue">{option.offNetMin}</span>
                        </p>
                        <p className="netlabel my-1 my-sm-0 text-center col-12 col-md-6">
                        {t("business.services.onNetSMS")} :{" "}
                          <span className="netvalue">{option.onNetSMS}</span>
                        </p>
                        <p className="netlabel my-1 my-sm-0 text-center col-12 col-md-6">
                        {t("business.services.offNetSMS")} :{" "}
                          <span className="netvalue">{option.offNetSMS}</span>
                        </p>
                        </div>
                      </div>
                      <div className="mx-4 divider"></div>
                      <p>{option.price} {t("global.sar")}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div
              className="button"
              onClick={() => setStepIndex(stepIndex + 1)}
            >
              {t("business.services.next")}
            </div>
          </>
        );
      };
      
      const InnerContent = ({
        step,
        stepIndex,
        setStepIndex,
        child
      }: {
        step: any,
        stepIndex: number,
        setStepIndex: Dispatch<SetStateAction<number>>,
        child: JSX.Element
      }) => {
        return (
          <div className="content d-flex flex-column w-100 h-100">
            {stepIndex === 0 ? (
              <></>
            ) : (
              <img
                className="back mb-2"
                src={"icons/arrow-" + i18n.dir() + ".svg"}
                alt="back"
                onClick={() => {
                  setStepIndex(stepIndex - 1);
                  console.log(stepIndex);
                }}
              />
            )}
            {step.title != null && step.description != null ? (
              <div className="title_">
                <h1 className="fw-bold mb-3">{step.title}</h1>
                <p>{step.description}</p>
              </div>
            ) : (
              <></>
            )}
            {child}
          </div>
        );
      };
   
    
      const Login = ({ stepIndex, setStepIndex } : {stepIndex: number, setStepIndex: Dispatch<SetStateAction<number>>}) => {
       
        const {t} = useTranslation();
        const [state, handleSubmit] = useForm("meqyvlld");
        if (state.succeeded) {
          setStepIndex(stepIndex + 1);
        }
        return (
          <>
          <form onSubmit={handleSubmit}>
            <div className="row group">
              <div className="form-group my-3 group col-12 col-sm-6">
                <label htmlFor="name">{t("business.login.name")}</label>
                
                <input
                  required
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder={t("business.login.name")+""}
                />
                <ValidationError 
                  prefix="Name"
                  field="name"
                  errors={state.errors}
                />
              </div>
              <div className="form-group my-3 group col-12 col-sm-6">
                <label htmlFor="email">{t("business.login.email")}</label>
                <input
                  required
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder={t("business.login.emailExample")+""}
                />
                <ValidationError 
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </div>
            </div>
            <div className="row group">
              <div className="form-group col-12 col-sm-6">
                <label htmlFor="city">{t("business.login.city")}</label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="city"
                  name="city"
                  placeholder={t("business.login.city")+""}
                />
                <ValidationError
                  prefix="City"
                  field="city"
                  errors={state.errors}
                />
              </div>
              <div className="form-group col-12 col-sm-6">
                <label htmlFor="phone">{t("business.login.phone")}</label>
                <input
                  required
                  type="number"
                  className="form-control"
                  id="phone"
                  name="phone"
                  placeholder={t("business.login.phoneExample")+""}
                />
                <ValidationError 
                  prefix="Phone"
                  field="phone"
                  errors={state.errors}
                />
              </div>
            </div>
            <div className="row group">
              <div className="form-group my-3 group">
                <label htmlFor="how many potential customers do you have?">{t("business.login.how many potential customers do you have?")}</label>
                <input
                  required
                  className="form-control"
                  id="how many potential customers do you have?"
                  name="how many potential customers do you have?"
                  type="number"
                />
                <ValidationError 
                  prefix="how many potential customers do you have?" 
                  field="how many potential customers do you have?"
                  errors={state.errors}
                />
              </div>
            </div>
            <div className="row group">
              <div className="form-group my-3 group">
                <label htmlFor="message">{t("business.login.message")}</label>
                <textarea
                  required
                  className="form-control"
                  id="message"
                  name="message"
                  placeholder={t("business.login.message")+""}
                />
                <ValidationError 
                  prefix="Message" 
                  field="message"
                  errors={state.errors}
                />
              </div>
            </div>
            
            <button type="submit" disabled={state.submitting} className="button">
              {t("business.login.submit")}
            </button>
          </form>
          {/* //width 971</> */}
          
          </>
        );
      };
      
      const Indicators = ({ active, length }: { active: number, length: number }) => {
        return (

          <div className="indicators">
            {Array.from({ length }).map((_, index) => {
              return (
                <div
                  className={`indicator rounded-pill col ${
                    (index > active || active >= length) && active !==3  ? "not_active" : "active"
                  }`}
                  key={index}
                ></div>
              );
            })}
          </div>
        );
      };
        
import { Link } from 'react-router-dom';
import Header from '../../components/header/header';
import '../about/about.css';


const Post = () => {
    return <div style={{backgroundImage: "url(./media/blog_bg.png)", backgroundSize: "contain"}}>
    <div className="container about" >
    <Header/>
    <div className="theApp-body">

	
           <section className="pt-xl sal-animate" data-sal="slide-down" data-sal-duration="1000">
                <div className="container">
                    <div className="pt-sm">
					<div className="text-center">
                        <h1 className="fs-xxl mb-e6">
								How do you keep your information private?							</h1>
                        </div>
						<div className="mb-sm">
						<div className=" inViewJs is-3-1 rounded-3 " 
                            style={{
                                height: "300px",
                                overflow: "hidden",
                            }}>
									<img alt="How do you keep your information private?" data-src="./media/blog/2.fa0644a3.jpg" src="./media/blog/2.fa0644a3.jpg"
                                    />
						    </div>
                    </div>
                    </div>
					
                </div>
			
            </section>

	
			 <section className="pt-xl">
                <div className="container">
                    <div className="max-xl mx-auto content">
					    
<p className="has-text-align-right">With the increasing use of the Internet, the constant presence on social media platforms, and the continuous sharing of personal information in this open space, the issue of information privacy has become one of the most important and sensitive topics.</p>

<p className="has-text-align-right">This is because of the great danger it poses to individuals and organizations as well as countries and societies, because the great spread of this information without controls may cause negative effects at all levels.</p>

<p className="has-text-align-right">And because the preservation of personal information is primarily an individual responsibility, we believe in the importance of raising awareness of the privacy of information and keeping it secure. Through this article, we provide you with a set of questions that will help you determine the extent to which you can share</p>

<p className="has-text-align-right">Your information in the Internet</p>

<h3 className="has-text-align-right"><br/><strong>1- Ask yourself: Do I need to share this information?</strong></h3>

<p className="has-text-align-right">Not all information is worth sharing on the Internet and some of it can be harmful to share, so it is always better to have a label of what information you can share that is private and information that can be used in a way that is harmful to you.</p>

<h3 className="has-text-align-right"><br/><strong>2- Ask yourself, does the person or entity have the right to request such information?</strong></h3>

<p className="has-text-align-right">Someone may ask you for some information to perform a certain task, you must be careful at this moment and make sure that the person is entitled to ask for information and that he represents an official body</p>

<h3 className="has-text-align-right"><br/><strong>3- Ask yourself: Is there a risk in sharing this type of information?</strong></h3>

<p className="has-text-align-right">Some information has privacy, private and sharing it in any way on the Internet will cause future problems that you need, so avoid sharing it on the Internet and always try to consult someone you trust about whether or not to share this information</p>

<h3 className="has-text-align-right"><br/><strong>4- Ask yourself: Is the Internet the only way to share this information?</strong></h3>

<p className="has-text-align-right">You might think that the only way to share information would be to post it online but rest assured that this is not always the case</p>

<p className="has-text-align-right">There are other options, such as calling or meeting directly with the people you want to share information with, an appropriate option for the nature of the information you have, so you must determine and decide the appropriateness of sharing this information over the Internet or not.</p>

<p className="has-text-align-right">And do not forget that the information that you publish on the Internet is no longer your private information, but rather it is available to everyone for circulation, re-share and dissemination in wider circles, so be sure and be careful before publishing any information in this open space</p>
                    </div>
                </div>
            </section>

			
	


            <section className="appSlider is-3 pb-xl pt-xl">
                <div className="container">
                    <div className="align-items-center d-flex justify-content-between mb-sm sal-animate" data-sal="slide-up" data-sal-delay="100">
                        <h3 className="fs-xl mb-0">Recommended Reading</h3>
                        <div className="d-flex">
                            <div className="btn btn-outline-dark rounded-pill swiper-button-prev-custom swiper-button-disabled swiper-button-lock"><svg className="svgIcon">
                                    <use xlinkHref="#icon-arrow-start"></use></svg></div>
                            <div className="me-e9"></div>
                            <div className="btn btn-outline-dark rounded-pill swiper-button-next-custom swiper-button-disabled swiper-button-lock"><svg className="svgIcon">
                                    <use xlinkHref="#icon-arrow-end"></use></svg></div>
                        </div>
                    </div>
                    <div className="row">
						
					
                        <div className="col-lg-4 mb-col sal-animate" data-sal="slide-up" data-sal-delay="200">
    



<div className="appCard">
<div className="appCard-header"><Link className="appCard-thumb" to={"/post"} title="How do you keep your information private?">


        <div className="appImage inViewJs is-3-2">
                        <img alt="How do you keep your information private?" data-src="https://tygo.sa/wp-content/uploads/2022/05/2.fa0644a3.jpg" src="https://tygo.sa/wp-content/uploads/2022/05/2.fa0644a3.jpg"/>
                    </div>

    </Link></div>
<div className="appCard-body">
    <h4 className="appCard-title"><Link to={"/post"} title="How do you keep your information private?">How do you keep your information private?</Link></h4>
    <p className="appCard-desc fs-xxxs mb-0 opacity-50 pt-e6">
        With the increasing use of the Internet, the constant presence on social media platforms, and the continuous sharing of personal information in this open space, the issue of information privacy has		
                            </p>
</div>
</div>								</div>

                            <div className="col-lg-4 mb-col sal-animate" data-sal="slide-up" data-sal-delay="200">
    



<div className="appCard">
<div className="appCard-header"><a className="appCard-thumb" href="https://tygo.sa/5-tips-for-managing-data-consumption/" title="5 Tips for managing data consumption">


        <div className="appImage inViewJs is-3-2">
                        <img alt="5 Tips for managing data consumption" data-src="https://tygo.sa/wp-content/uploads/2022/05/1.15d8bd61.jpg" src="https://tygo.sa/wp-content/uploads/2022/05/1.15d8bd61.jpg"/>
                    </div>

    </a></div>
<div className="appCard-body">
    <h4 className="appCard-title"><a href="https://tygo.sa/5-tips-for-managing-data-consumption/" title="5 Tips for managing data consumption">5 Tips for managing data consumption</a></h4>
    <p className="appCard-desc fs-xxxs mb-0 opacity-50 pt-e6">
        With our permanent connection to the Internet, the use of social media platforms and constantly communicating with family, friends, and work, our consumption of data increases dramatically, and it		
                            </p>
</div>
</div>								</div>

                            <div className="col-lg-4 mb-col sal-animate" data-sal="slide-up" data-sal-delay="200">
    



<div className="appCard">
<div className="appCard-header"><a className="appCard-thumb" href="https://tygo.sa/how-to-choose-a-suitable-internet-and-communication-package/" title="How to choose a suitable internet and communication package?">


        <div className="appImage inViewJs is-3-2">
                        <img alt="How to choose a suitable internet and communication package?" data-src="https://tygo.sa/wp-content/uploads/2022/05/2.fa0644a3.jpg" src="https://tygo.sa/wp-content/uploads/2022/05/2.fa0644a3.jpg"/>
                    </div>

    </a></div>
<div className="appCard-body">
    <h4 className="appCard-title"><a href="https://tygo.sa/how-to-choose-a-suitable-internet-and-communication-package/" title="How to choose a suitable internet and communication package?">How to choose a suitable internet and communication package?</a></h4>
    <p className="appCard-desc fs-xxxs mb-0 opacity-50 pt-e6">
        If you thought of subscribing to one of the communication and internet packages, you certainly felt confused by the multiplicity of services		
                            </p>
</div>
</div>								</div>







</div>
                </div>
            </section>

            











	</div>
    </div>
    </div>
    

}

export default Post;
import Axios from "axios";
import { REACT_APP_API_PROVIDERS } from '../../config'

const getComparePlans = async (ids: number[], lang:string) =>{
  const plans = await Axios.post(
    // "https://test.providers.tygo.net/api/plan/Web/compare",
    REACT_APP_API_PROVIDERS + "/api/plan/Web/compare",
    ids,
    {
      headers: {
        accept: "text/plain",
        "Accept-Language": lang,
      }
    }
  );

  return plans.data;
}

export {
    getComparePlans
};
import React, { useState } from 'react';
import { CompareCardContext } from './CompareCard';
// import Offcanvas from 'react-bootstrap/esm/Offcanvas';
interface Props {
    children?: React.ReactNode;
    plan: any;
}

const Dragable: React.FC<Props> = ({children, plan}) => {
    const {addToCart} = React.useContext(CompareCardContext);
    const onDragStart = (e: any) => {
        e.dataTransfer.setData('plan', JSON.stringify(plan));        
    }

    const onDragEnd = (e: any) => {
        e.dataTransfer.clearData();
        e.preventDefault();
    }

    return (
        <div draggable="true" onDragStart={onDragStart} onDragEnd={onDragEnd}>
            {children}
            {/* <button onClick={() => addToCart(plan)}>Add to cart</button> */}
        </div>
    )
}

export default Dragable;
const TermsEn = () => {
    return (

      <div>
       
        <p style={{textAlign: 'justify'}}>
          <span>These terms and conditions (hereinafter referred to as the “</span><strong><span>Agreement</span></strong><span>”) govern your use of the</span><span> “</span><span>Tygo</span><span>” application, and it is important to read and understand them before using "Tygo"</span><strong><span> </span></strong><span>so that you are aware of your legal rights and obligations towards</span><strong><span> </span></strong><span>"Tygo". By clicking “</span><strong><span>I Agree</span></strong><span> - </span><span><span>&nbsp;</span><span>“</span><strong><span>AGREE</span></strong></span><strong><span><span /></span></strong> <span>you agree to be bound by this Agreement if you choose to access or use the</span><span> “</span><span>Tygo</span><span>” service. If you do not agree to this Agreement, you are not permitted to use "Tygo".</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong><u><span>First: Definitions</span></u></strong>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span>The following terms and expressions – wherever mentioned in this Agreement – shall have the meanings indicated next to each of them, unless the context requires otherwise</span><span>:</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span>“</span><span>Tygo</span><span>”</span><span> or </span><span>“</span><span>the Application</span><span>”</span><span> or </span><span>“</span><span>the Site</span><span>”</span><span> or </span><span>“</span><span>we</span><span>”</span><span> or </span><span>“</span><span>the First Person Pronoun</span><span>”</span><span> or </span><span>“</span><span>the Possessive Pronoun</span><span>": refers to </span><span>“</span><span>Tygo</span><span>”</span><span> and Future Communication Company in the Kingdom of Saudi Arabia</span><span>.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span>“</span><span>the User</span><span>”</span><span> or </span><span>“</span><span>you</span><span>”</span><span> or </span><span>“</span><span>the Possessive Pronoun for the Addressee</span><span> “</span><span>: means the person who visits the application, uses it, or registers a membership in the application</span><span>.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span>“</span><span>the Services</span><span>”</span><span> refers to the services offered through the application, which include (traditional SIM cards, eSIMs, internet packages, mobile phone packages, and balance recharge, among others)</span><span>.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span>“</span><span>the Content</span><span>”</span><span> refers to the content of the application, which includes but is not limited to (images, texts, videos, information, data, prices, service lists, service descriptions, advertisements, and all icons, symbols, letters, and numbers)</span><span>.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span>“</span><span>the Agreement</span><span>”</span><span> refers to these terms and conditions, the privacy policy, all main and sub-pages of the application, service descriptions, all information and service content, all instructions, and special agreements made between the application on one hand and any of the application users on the other hand, as well as contracts related to the terms and conditions</span><span>.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span>“</span><span>the Parties to the Agreement</span><span>”</span><span> refers to the application on one hand as (the first party in the contractual relationship), and any person who visits the application, uses it, registers an account in the application, provides any of the application services, or benefits from our services in any way on the other hand as (the second party in the contractual relationship)</span><span>.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span>&nbsp;</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong><u><span>Second: Who are we?</span></u></strong>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span>The "Tygo" application is owned and operated by Future Communication Company, a company established and existing under the laws of the Kingdom of Saudi Arabia</span><span>.</span>
        </p>
        <p style={{textAlign: 'justify'}}>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong><u><span>Third: Our Services</span></u></strong>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            The <span>“</span><span>Tygo</span><span>”</span><span> application is an electronic intermediary for displaying price information, features, terms, and conditions of telecommunications services provided by service providers in the Kingdom of Saudi Arabia and elsewhere, including services related to traditional SIM cards, eSIMs, internet packages, mobile phone packages, and balance recharge. </span>
          </li>
          <li className="ListParagraph" style={{marginRight: '13.75pt', paddingRight: '4.65pt'}}>
            <span>Through the</span><span> “</span><span>Tygo</span><span>”</span><span> application, you can view all these services, compare them, choose the appropriate package and service for you, and purchase them through secure electronic payment methods, after registering a membership in the application to fully benefit from our services</span><span>.</span>
          </li>
        </ol>
        <p style={{textAlign: 'justify'}}>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong><u><span>Fourth: Legal Limits of Our Services</span></u></strong>
        </p>
        <ol style={{margin: '0pt', paddingRight: '0pt'}}>
          <li className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            The application acts as an electronic platform for displaying services, serving as our advertising tool through which we publish all information about the services offered to provide the customer with the ability to view and compare offers from telecommunications operators in the Kingdom of Saudi Arabia and elsewhere<span>.</span>
          </li>
          <li className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            The advertisements for services published through the application are considered “invitations to contract” and not “binding offers<span>”</span><span>.</span>
          </li>
          <li className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            You understand and agree that the role of the application is limited to marketing the services of service providers, while the service is provided and activated by the service providers. Therefore, the application is not considered a service provider but an electronic intermediary<span>.</span>
          </li>
          <li className="ListParagraph" style={{marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            The <span>“</span><span>Tygo</span><span>”</span>application is not an internet service provider, nor a provider of informational content, and is not treated as a publisher of any content published through the application or through any available communication feature within the application<span>.</span>
          </li>
          <li className="ListParagraph" style={{marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            You understand and agree to exempt the <span>“</span><span>Tygo</span><span>”</span> application from any liability for the actions of others, and the <span>“</span><span>Tygo</span><span>”</span><span> application is not responsible for any legal or illegal actions or behaviors of others.</span>
          </li>
          <li className="ListParagraph" style={{marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"'}}>
            All services provided by the <span>“</span><span>Tygo</span><span>”</span><span> application are offered in accordance with the laws in force in the Kingdom of Saudi Arabia.</span>
          </li>
        </ol>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong><u><span>Fifth: Legal Nature of the Agreement</span></u></strong>
        </p>
        <ol style={{ margin: '0pt', paddingRight: '0pt' }}>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            This agreement constitutes the complete and final agreement between the Tygo application and the user regarding the services.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            This agreement is a valid, complete contract with all necessary legal terms and conditions, enforceable against the mentioned parties. Its provisions and obligations are binding on all of them, and no one may withdraw from it or terminate it as long as it has legal effects.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            All parties acknowledge that this agreement constitutes the full agreement between them, and they agree that they have not relied on any representations, whether oral or written, in agreeing to this agreement except for the terms outlined herein.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            The description of services and subpages of the application prepared by Tygo is an integral part of this agreement.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            Complementary contracts to this agreement are an integral part of it and are subject to the same terms and conditions. They are linked to this agreement in an indivisible and inseparable manner.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            Amendments to this agreement shall have the same effect and legal standing as this agreement.
          </li>
        </ol>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong><u><span>Sixth: Consent and Legal Capacity</span></u></strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span>
            You acknowledge that you have the necessary legal capacity to enter into and agree to this agreement, and that you have full legal authority not restricted by the following terms:
          </span>
        </p>
        <ol style={{ margin: '0pt', paddingRight: '0pt' }}>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            Legal capacity and consent of a natural person. A user of Tygo must be at least 18 years old and have the necessary legal capacity to enter into contracts. We are not responsible for verifying the capacity of any users. By using Tygo's services, you agree to this agreement and acknowledge that you are legally bound by the terms and conditions stated herein or their amendments.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            Legal capacity and consent of a minor (under 18 years old). If you are under 18, you may use Tygo's services only under the supervision of a parent or guardian. Tygo reserves the right to restrict your access or cancel your membership if it deems you have not reached 18 years of age.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            Legal status and consent of commercial entities. If you register as a commercial entity, you declare that you have the authority to bind the entity to this user agreement, and both you and the entity you represent will comply with all applicable internet trading laws.
          </li>
        </ol>
        <p style={{ marginRight: '0.4pt', textAlign: 'justify' }}>
          <span>
            In all cases, you agree that you are solely responsible for actions or negligence related to your access to Tygo's services.
          </span>
        </p>
        <p style={{ marginRight: '0.4pt', textAlign: 'justify' }}>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong><u><span>Seventh: Registration and Membership</span></u></strong>
        </p>
        <ol style={{ margin: '0pt', paddingRight: '0pt' }}>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            You must open an account with Tygo to benefit from the application's services.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            Natural persons must register with their real name. The use of any alias, false, or misleading names is prohibited. Once you register as a natural person, you represent yourself only. The account is personal.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            Legal entities must register with their trade name. The use of any unknown, false, or misleading names is prohibited. Once you register as a legal entity, you declare that you are the legal representative of that entity.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            Users must provide their information when registering, which may include but is not limited to:
          </li>
        </ol>
        <p
          className="ListParagraph"
          style={{
            marginRight: '36.4pt',
            marginBottom: '0pt',
            textIndent: '-18pt',
            textAlign: 'justify',
          }}
        >
          <span>-</span>
          <span style={{ width: '13.6pt', font: '7pt "Times New Roman"', display: 'inline-block' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span>Full username.</span>
        </p>
        <p
          className="ListParagraph"
          style={{
            marginRight: '36.4pt',
            marginBottom: '0pt',
            textIndent: '-18pt',
            textAlign: 'justify',
          }}
        >
          <span>-</span>
          <span style={{ width: '13.6pt', font: '7pt "Times New Roman"', display: 'inline-block' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span>User address.</span>
        </p>
        <p
          className="ListParagraph"
          style={{
            marginRight: '36.4pt',
            marginBottom: '0pt',
            textIndent: '-18pt',
            textAlign: 'justify',
          }}
        >
          <span>-</span>
          <span style={{ width: '13.6pt', font: '7pt "Times New Roman"', display: 'inline-block' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span>User contact number.</span>
        </p>
        <p
          className="ListParagraph"
          style={{
            marginRight: '36.4pt',
            marginBottom: '0pt',
            textIndent: '-18pt',
            textAlign: 'justify',
          }}
        >
          <span>-</span>
          <span style={{ width: '13.6pt', font: '7pt "Times New Roman"', display: 'inline-block' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span>User email.</span>
        </p>
        <p
          className="ListParagraph"
          style={{
            marginRight: '36.4pt',
            marginBottom: '0pt',
            textIndent: '-18pt',
            textAlign: 'justify',
          }}
        >
          <span>-</span>
          <span style={{ width: '13.6pt', font: '7pt "Times New Roman"', display: 'inline-block' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span>Any other data or information requested by the application.</span>
        </p>
        <ol start={5} style={{ margin: '0pt', paddingRight: '0pt' }}>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            Membership is free and direct for users and does not require review by the application's management.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            Users agree to keep their account information confidential, such as username and password, and are responsible for any disclosure of this information to others, as well as for any use that occurs from anyone who has been given access to this confidential information.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            Users agree to notify the application immediately in case of account breach, theft, or discovery of any unauthorized use of their account within the application, so that necessary technical actions can be taken to protect the account.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            You may not use another person's account at any time without explicit permission from Tygo.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            We reserve the right to cancel accounts that are unverified, inactive for extended periods, or any other accounts at Tygo's discretion.
          </li>
        </ol>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong><u><span>Eighth: Digital Signature</span></u></strong>
        </p>
        <ol style={{ margin: '0pt', paddingRight: '0pt' }}>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            For services requiring registration: By registering for an account with the application or by clicking to accept the terms of service when prompted, you are deemed to have executed this agreement and other terms of service electronically, and they are legally effective from the date of your account registration or the date of clicking to accept the terms of service.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            For services that do not require registration: Your use of these services constitutes explicit consent to all the terms and conditions stated in this document and other policies. You are legally bound by them from the date of use.
          </li>
        </ol>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong><u><span>Ninth: Acceptable Use Policy</span></u></strong>
        </p>
        <ol style={{ margin: '0pt', paddingRight: '0pt' }}>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            The user acknowledges that by agreeing to this agreement, they will not rely on any other commitments, warranties, or confirmations made by or on behalf of Tygo except as provided in this agreement.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            This license does not include the resale or any commercial use of any of our services or their content. It also does not include copying information available about the account for others, or using data extraction methods or similar data collection tools.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            You may not reproduce or create a matching copy of this application, copy, sell, or resell any part of it, or use it in any other manner for commercial or non-commercial exploitation without explicit written permission from Tygo.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            You may not use descriptive tags or any other "hidden text" that exploits the name Tygo or its trademarks without explicit written permission from the application.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            All information disclosed must be true, updated, and accurate, reflecting you, and must comply with what is required in our registration form.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            Users are obligated to ensure accuracy when entering information required by the application and are responsible for periodically reviewing that information to correct, modify, or renew it whenever new information becomes available. All data and documents proving this must be retained.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            If you provide incorrect, inaccurate, outdated, or incomplete information, or if we have reasonable grounds to suspect that this information is incorrect, inaccurate, outdated, or incomplete, or does not comply with this user's agreement, we may suspend or cancel your membership without affecting any other rights under this agreement or the law.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            You are not licensed in any way to publish any links to other websites or applications through the application or through any features available within the application.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '13.71pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              paddingRight: '4.69pt',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            You agree not to use Tygo or any service provided through it in an illegal, fraudulent, or socially harmful manner as we deem appropriate.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '18.4pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            Users bear full legal responsibilities if they violate any personal or intellectual property rights of any content we publish through the application.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '18.4pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            We are not responsible for any user's violation of others' rights, and it is the user's sole responsibility.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '18.4pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            The application does not bear any responsibility regarding the misuse of content, and the user misusing the content is responsible toward us for their use of the application's content.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '18.4pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            You are responsible for using the application seriously and honestly.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '18.4pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            You may use our services only in a legally permissible manner and in accordance with the terms of this agreement.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '18.4pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            You may not misuse our services in any form.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '18.4pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            Users must notify us if they discover any unlawful use of the application.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '18.4pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            You acknowledge that you will notify us if there are any posts, materials, or transactions that appear to violate the user agreement.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '18.4pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            The use of Tygo may involve providing services or content from other parties, which are not under our control. Consequently, you acknowledge the applicability of terms, conditions, and privacy policies of these third-party services and content.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '18.4pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            The application's services are available inside and outside Saudi Arabia, and you can access the application from anywhere in the world.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '18.4pt',
              marginBottom: '0pt',
              textAlign: 'justify',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            You commit to providing the necessary information and documents requested by the application at any time, whether during subscription and account registration, or while providing services after account registration.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '18.4pt',
              textAlign: 'justify',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            All rights not expressly granted to you in these terms of use or any other service terms are reserved by Tygo.
          </li>
          <li
            className="ListParagraph"
            style={{
              marginRight: '18.4pt',
              textAlign: 'justify',
              fontFamily: '"DIN Next LT Arabic"',
            }}
          >
            Licenses granted by us terminate if you do not comply with these terms of use or any other service terms.
          </li>
        </ol>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong><u><span>Tenth: Service Provision Policy</span></u></strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span>The application </span><span>“</span><span>Taioqo</span><span>”</span><span> is committed to providing all legal guarantees and warranties to the user, in accordance with the regulations in force in the Kingdom of Saudi Arabia, as follows:</span>
        </p>
        <ol style={{ margin: '0pt', paddingRight: '0pt' }}>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            The application offers communication and internet services from various companies within the Kingdom of Saudi Arabia. The customer can access the application and filter services according to their type or the company providing them.
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            The user is informed and agrees that each service provider has its own independent terms and conditions which the user must comply with before using their services available through the application. The service provider may impose specific terms for each service they provide, and similar service terms may vary from one service provider to another.
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Service providers ensure that they possess all the legal rights and authorities to sell services, and they guarantee obtaining all necessary permits to sell the service.
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            <span>“</span><span>Taioqo</span><span>”</span> displays service prices and details in the application according to what service providers offer, ensuring the accuracy of this information. By agreeing to the service data after it is displayed to you and by clicking the (AGREE) option, you acknowledge your consent.
            </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            The service provider will deliver the SIM cards you purchased within a period not exceeding (24) hours from the date of order completion for urban areas, and within a maximum of 5 days for remote areas. However, the “Taioqo” application may undertake the SIM card delivery process if agreed upon with the service provider within the aforementioned time frame, and you will be notified if the “Taioqo” application undertakes the delivery process.
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            The application commits to making every possible effort to obtain guarantees from service operating companies, if any, and hereby waives those guarantees to the user according to the permissible period.
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', textAlign: 'justify', fontFamily: '"DIN Next LT Arabic"' }}>
            The application retains ownership of the service content, including all images and visual files, and holds the rights to publish them at any time.
          </li>
        </ol>
        <p style={{ textAlign: 'justify' }}>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong><u><span>Eleventh: Cancellation and Refund Policy</span></u></strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span>When you purchase a service from service providers and are not fully satisfied with it, you can request a refund of the service and payments within a period not exceeding (3) three days from the date of purchase in case the service is not activated for the client, according to the following policy:</span>
        </p>
        <ol style={{ margin: '0pt', paddingRight: '0pt' }}>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            (International SIM Cards) Services:
          </li>
        </ol>
        <p className="ListParagraph" style={{ marginRight: '36.4pt', marginBottom: '0pt', textIndent: '-18pt' }}>
          <span>-</span><span style={{ width: '13.6pt', font: '7pt "Times New Roman"', display: 'inline-block' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span>If the SIM card is not installed in the device, you can cancel the SIM card and receive a full refund.</span>
        </p>
        <p className="ListParagraph" style={{ marginRight: '36.4pt', marginBottom: '0pt', textIndent: '-18pt' }}>
          <span>-</span><span style={{ width: '13.6pt', font: '7pt "Times New Roman"', display: 'inline-block' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span>If the SIM card is installed in the device, the SIM card cannot be canceled and payments cannot be refunded.</span>
        </p>
        <ol start={2} style={{ margin: '0pt', paddingRight: '0pt' }}>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Local SIM Cards:
          </li>
        </ol>
        <p className="ListParagraph" style={{ marginRight: '36.4pt', marginBottom: '0pt', textIndent: '-18pt' }}>
          <span>-</span><span style={{ width: '13.6pt', font: '7pt "Times New Roman"', display: 'inline-block' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span>Subject to the cancellation and refund policy applied by the service provider (we recommend you review it before purchasing the service).</span>
        </p>
        <ol start={3} style={{ margin: '0pt', paddingRight: '0pt' }}>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Recharge Service:
          </li>
        </ol>
        <p className="ListParagraph" style={{ marginRight: '18.4pt', marginBottom: '0pt' }}>
          <span>Payments cannot be canceled or refunded under any circumstances.</span>
        </p>
        <ol start={4} style={{ margin: '0pt', paddingRight: '0pt' }}>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Refund Duration:
          </li>
        </ol>
        <p className="ListParagraph" style={{ marginRight: '18.4pt', marginBottom: '0pt', textAlign: 'justify' }}>
          <span>Refunds will be returned to the same credit card or payment method used for the purchase within a period ranging from one to fourteen business days.</span>
        </p>
        <ol start={5} style={{ margin: '0pt', paddingRight: '0pt' }}>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Compensation:
          </li>
        </ol>
        <p className="ListParagraph" style={{ marginRight: '18.4pt' }}>
          <span>If a service malfunction is proven, you will be compensated with another similar service or a refund for the purchased service.</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong><u><span>Twelfth: Evaluation Policy</span></u></strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span>The application provides an evaluation and comment service for users, aiming to offer better service to all our customers. The following conditions are required when adding comments:</span>
          <span>.</span>
        </p>
        <ol style={{ margin: '0pt', paddingRight: '0pt' }}>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Comments must be honest, legal, and reflective of the service provided to the user.
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.75pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.65pt' }}>
            <span>Using illegal words, phrases, or terms during the evaluation process is prohibited</span>
            <span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Using logos, names of other websites or applications, or well-known or unknown trademarks during the evaluation process is prohibited
            <span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Making comparisons between our services and those of our competitors that harm us or the competitors is prohibited
            <span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Abusing peoples, countries, cultures, society, or any human, social, or religious values during the evaluation process is prohibited
            <span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Publishing any advertisements or promotion of goods or services through our application's evaluation service is prohibited
            <span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            The application reserves the right not to publish comments if they are illegal or dishonest from our perspective
            <span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            The application reserves the right to delete any published comments if they violate the previous provisions in particular or the terms and conditions of this agreement in general.
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', textAlign: 'justify' }}>
            <span>Third parties who have been abused through the comment service have the right to sue the user who added the illegal comments without referring to us</span>
            <span>.</span>
          </li>
        </ol>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong><u><span>Thirteenth: Copyright</span></u></strong>
        </p>
        <ol style={{ margin: '0pt', paddingRight: '0pt' }}>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            The application and the ideas expressed within it are our exclusive intellectual property rights. Any imitation or copying of the application or some of its services (including ideas, texts, codes, and software) constitutes a violation of our copyrights, and we will take all legal actions against the perpetrators of such violations
            <span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            All content included or available within the services of the <span>“</span><span>Taioqo</span><span>”</span> application, such as (texts, logos, images, graphics, audio recordings, button icons, digital content, downloadable materials, software, and data compilation) is a registered trademark and owned by Future Communication Company and is protected by the laws of the Kingdom of Saudi Arabia and international copyright laws.
            </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Collecting all data listed in the <span>“</span><span>Taioqo</span><span>” </span>service or making it available by any of our services is the exclusive ownership of the <span>"Taioqo"</span> application and is protected under Saudi and international copyright laws as well as protected under applicable international agreements, such as the Berne Convention and TRIPS Agreement
              <span>.</span>
            </li>
        </ol>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong><u><span>Fourteenth: Trademarks</span></u></strong>
        </p>
        <ol style={{ margin: '0pt', paddingRight: '0pt' }}>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            <span>“</span><span>Taioqo</span><span>”</span><span> and its associated logos are trademarks and/or service marks owned by us</span>
            <span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Images, logos, page headers, button icons, texts, service names are commercial trademarks and designs exclusive to the <span>“</span><span>Taioqo</span><span>”</span> application.
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Trademarks and services provided owned by telecommunications companies are owned by the primary service providers, and <span>“</span><span>Taioqo</span><span>”</span><span> is considered an intermediary for re-presenting and displaying these services and trademarks</span>
            <span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Reproducing trademarks or commercial designs of the <span>“</span><span>Taioqo</span><span>”</span> application in any media or advertising means without our written permission is prohibited
            <span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Absolutely, you may not modify or use any name, trademark, or logo that is confusingly similar to the trademarks, service marks, and logos of <span>“</span><span>Taioqo</span><span>”</span> and other service providers.
          </li>
        </ol>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong><u><span>Fifteenth: Communications</span></u></strong>
        </p>
        <ol style={{ margin: '0pt', paddingRight: '0pt' }}>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            You agree to receive communications from us, and we will contact you via email, mobile number, modern communication applications (such as WhatsApp), or by posting notifications through the application or through other <span>Taioqo</span> services
            <span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            You agree that all agreements, notifications, disclosures, and other communications we provide you electronically comply with all legal requirements as if these communications were written, having the same legal effects
            <span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            The <span>Taioqo</span> application requires your consent during the registration process for us to send messages to your email or mobile phone or through the application for promotional purposes, to inform you of any changes, features, or new activities added to the application
            <span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.75pt', textAlign: 'justify', paddingRight: '4.65pt' }}>
            <span>If you decide at any time that you no longer wish to receive promotional messages, you can disable receiving these messages by contacting us, but in this case, we do not guarantee your full use of our services</span>
            <span>.</span>
          </li>
        </ol>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong><u><span>Sixteenth: Notifications</span></u></strong>
        </p>
        <ol style={{ margin: '0pt', paddingRight: '0pt' }}>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Any notifications you wish to send to the <span>Taioqo</span> application must be sent through the means specified within the application, and any notifications sent outside the application are not considered valid, with the possibility of receiving communication on the official platforms owned by <span>“Taioqo”</span>
            <span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Any notifications the <span>Taioqo</span> application wishes to send to you are either by announcing them on the application or by sending them to you via the mobile number or email you provided during the registration process, and it is assumed that you are aware of the notification once it is announced on the application or after 24 hours have passed since the message was sent to you
            <span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.75pt', textAlign: 'justify', paddingRight: '4.65pt' }}>
            <span>If your request is declined by the service provider or if additional documents are requested from you, we will notify you via your email in addition to sending a text message to your mobile number, clarifying the rejection of the request or the additional documents required from you, and how to submit them through the application</span>.
          </li>
        </ol>
        <p style={{ textAlign: 'justify' }}>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong><u><span>Seventeenth: Transfer of Rights and Obligations</span></u></strong>
        </p>
        <ol style={{ margin: '0pt', paddingRight: '0pt' }}>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            The <span>“</span><span>Taioqo</span><span>”</span><span> application is allowed to transfer its business related to the services or any part of them to a subsidiary company or a third party, including, but not limited to, transferring through merger, selling ownership rights, selling all assets, or in any substantial way or any other change in transactions involving such businesses.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Accordingly, the <span>“Taioqo”</span> application has the right to transfer all its rights under this agreement to others and transfer your details and information to the designated party without any objection from the user. All parties are obligated to execute all their commitments under this agreement by the transferee immediately upon being notified of the transfer, and the transferee has the right to take all legal actions against any party failing to fulfill their obligations under this agreement
            <span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.75pt', textAlign: 'justify', paddingRight: '4.65pt' }}>
            <span>You may not assign your commitments and rights under this agreement, or delegate the management of your application account to a third party without obtaining our written consent</span>
            <span>.</span>
          </li>
        </ol>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong><u><span>18: Legal Liability</span></u></strong>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span>You must comply with all applicable laws, regulations, and rules within the country through which you use the application, concerning your use of the application, and you bear all responsibilities arising from your violation of these laws or regulations or rules, as well as adhere to all the terms and conditions stipulated in this agreement.</span><span>.</span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          In the event a user violates any of the terms or conditions of this agreement, the <span>"</span><span>Taioqo</span><span>" </span><span>application has the right to take administrative action within the application, such as suspending membership for a period of time or permanently banning the violating user, and in this case, the user is not entitled to re-register in the application except with explicit consent from the <span>"</span><span>Taioqo</span><span>"</span>.</span>
          </p>
        <p>
          <span>Each party has the right to take all legal actions it deems appropriate at its discretion without any form of recourse to us and without any liability to the application.</span><span>.</span>
        </p>
        <p>
          <span>The client is required under this agreement and by law. They are obligated to indemnify us in the event they submit fraudulent or non-serious purchase requests intended to annoy us or waste our time, and they will be legally responsible to shipping companies in such cases.</span><span>.</span>
        </p>
        <p>
          If you violate this agreement, the <span>"</span><span>Taioqo</span><span>" </span><span>application reserves the right to recover any amounts owed to you, any losses or damages you have caused, and the application has the right to take legal action or resort to competent courts to file civil or criminal lawsuits against you.</span><span>.</span>
          </p>
        <p>
          <span>The <span>"</span><span>Taioqo</span><span>" </span><span>application does not guarantee taking action against all violations that may occur to this user agreement, and our failure to take legal action in any violation cases does not constitute a waiver of our right to take such actions at any time we deem appropriate.</span><span>.</span>
          </span></p>
        <p>
          <strong><u><span>19: Limitation of Our Liability</span></u></strong>
        </p>
        <p>
          <span>You explicitly agree that you use the <span>"</span><span>Taioqo</span><span>" </span><span>application at your own risk.</span><span>.</span>
          </span></p>
        <p>
          <span>We provide our services "as is," without any warranties or guarantees of any kind, whether express or implied, related to the use of this application or its content or the services offered on it.</span><span>.</span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span>To the fullest extent permitted by applicable law, the <span>"</span><span>Taioqo</span><span>" </span><span>application and any of its liabilities, directors, shareholders, employees, partners, or agents,</span><span> are not liable for any losses or damages, whether direct or indirect or consequential or incidental, arising from or related to:</span>
          </span></p>
        <ol style={{ margin: '0pt', paddingRight: '0pt' }}>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Incorrect information in services or recommendations related to services<span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Any interactions between service providers and customers outside the scope of the application<span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Quality of services provided by service providers<span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Loss of profits or sales or business or revenues or business interruption or similar<span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Loss of business opportunities, reputation, or any indirect or consequential damage<span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            Any disclosure of customer data by service providers<span>.</span>
          </li>
          <li className="ListParagraph" style={{ marginRight: '13.71pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
            User's failure to maintain the security, confidentiality, and privacy of account data<span>.</span>
          </li>
        </ol>
        <p style={{ textAlign: 'justify' }}></p>
        <span>The application does not guarantee that technical flaws will be fixed, or that the application or its servers are free from viruses or anything else harmful or destructive, and you acknowledge that websites on the internet are always susceptible to data corruption, unavailability, or delayed appearance, and you accept that.</span><span>.</span>
      <p style={{ textAlign: 'justify' }}>
          <span>The application may be unavailable from time to time due to maintenance, repair, or development work, and you agree that the application is not obligated to provide technical support at any time.</span><span>.</span>
        </p><p style={{ textAlign: 'justify' }}>
          <span>You acknowledge that the <span>"</span><span>Taioqo</span><span>" </span><span>application is an online-based service, and while we make every effort to maintain and keep information secure, we cannot guarantee that the information users receive or broadcast while using the application is always secure.</span><span>.</span>
          </span></p><p style={{ textAlign: 'justify' }}>
          <span>The <span>"</span><span>Taioqo</span><span>" </span><span>application does not provide any warranty and is not liable for the timeliness, popularity, accuracy, or quality of the information received by the user or obtained through the application.</span><span>.</span>
          </span></p><p style={{ textAlign: 'justify' }}>
          <span>The responsibility for using or relying on information received or obtained by the user through the services of the <span>"</span><span>Taioqo</span><span>" </span><span>application lies entirely with the user.</span><span>.</span>
          </span></p><p style={{ textAlign: 'justify' }}>
          <span>The <span>"</span><span>Taioqo</span><span>" </span><span>application does not provide any warranties that this application or its servers or messages sent are free of viruses or other harmful components.</span><span>.</span>
          </span></p><p style={{ textAlign: 'justify' }}>
          <span>The <span>"</span><span>Taioqo</span><span>" </span><span>application cannot confirm that every user of the application is indeed the person they claim to be.</span><span>.</span>
          </span></p><p style={{ textAlign: 'justify' }}>
          <span>The application will under no circumstances be liable for any direct or indirect, consequential or profit loss, or any damages whatsoever, resulting from the disclosure of your username and/or password.</span><span>.</span>
        </p><p style={{ textAlign: 'justify' }}>
          <span>The <span>"</span><span>Taioqo</span><span>" </span><span>application is not legally responsible for any losses or damages of any kind that may arise from the use of this application, including but not limited to direct and indirect damages.</span><span>.</span>
          </span></p><p style={{ textAlign: 'justify' }}>
          <span>We may modify, suspend, or discontinue the services of the <span>"</span><span>Taioqo</span><span>" </span><span>application at any time without prior notice to you, and we may suspend your use of our services if you violate these terms and conditions or misuse these services from our perspective.</span><span>.</span>
          </span></p><p style={{ textAlign: 'justify' }}>
          <span>Without affecting our other rights, the <span>"</span><span>Taioqo</span><span>" </span><span>application has the right to suspend or cancel your membership or access to the application at any time without warning and for any reason, and it can cancel this user agreement.</span><span>.</span>
          </span></p><p>
          <span>&nbsp;</span>
        </p><p>
          <strong><u><span>20: Indemnifications</span></u></strong>
        </p><p style={{ textAlign: 'justify' }}>
          <span>You agree to indemnify and hold harmless the <span>"</span><span>Taioqo</span><span>" </span><span>application and its officers, directors, employees, and agents from and against all claims, demands, liabilities, damages, losses, and expenses, including reasonable attorney fees and costs, arising out of your use of the application, your violation of these terms and conditions, or infringement of the rights of other users.</span><span>.</span>
          </span></p><p style={{ textAlign: 'justify' }}>
          <span>The application excludes from its warranties, terms, and conditions any financial losses that may be incurred by the user, reputation damage, or any special damages arising from your misuse of the services or application, and the application does not bear any responsibilities or claims in such cases.</span><span>.</span>
        </p><p style={{ textAlign: 'justify' }}>
          <span>The <span>"</span><span>Taioqo</span><span>" </span><span>application and its employees are not legally responsible to you or any other party for any direct or indirect losses or any other costs that may arise from or relate to the execution of this agreement, or relate to the provision of our services.</span><span>.</span>
          </span></p><p style={{ textAlign: 'justify' }}>
          <span>The user must protect the <span>"</span><span>Taioqo</span><span>" </span><span>application and its employees and defend and indemnify them against any losses or expenses or payments, including reasonable attorney fees and legal costs, resulting from any lawsuit or complaint or claim related to the application or arising from the user's actions or negligence or those of their representatives or agents.</span><span>.</span>
          </span></p><p style={{ textAlign: 'justify' }}>
          <span>You are obligated to indemnify the application for any losses or damages that may be incurred by the application as a result of any unauthorized or illegal use by us.</span><span>.</span>
        </p><p>
          <span>&nbsp;</span>
        </p><p>
          <strong><u><span>21: Modifications</span></u></strong>
        </p>
         The user agrees to the changes and modifications made by the application to these terms and conditions and applied rules. The user must accept any changes deemed necessary by the <span>"</span><span>Taioqo</span><span>" </span>application if they wish to continue using the services of <span>"</span><span>Taioqo</span><span>" </span><span>, and we may make any modifications or improvements deemed necessary to the application to enhance its effectiveness. The user must comply with any directions or instructions provided by the application in this regard if they wish to continue using the services of <span>"</span><span>Taioqo</span><span>" </span><span>.</span></span>
          
          
            <p style={{ textAlign: 'justify' }}>
              <span>Therefore, when the <span>"</span><span>Taioqo</span><span>" </span><span>application makes changes or modifications to the disclosure or terms of this agreement or adjusts any additional fees or charges for any related services through the application, you will be notified of them and must explicitly agree to them. Once you accept them by clicking the (AGREE) option, you will be bound by the changes and modifications.</span>
              </span></p>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <strong><u><span>22: Termination of the Agreement</span></u></strong>
            </p>
            <p>
              <span>This agreement is automatically terminated without the need for reasons or legal actions in any of the following cases:</span><span>.</span>
            </p>
            <ol style={{ margin: '0pt', paddingRight: '0pt' }}>
              <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
                If it is proven that you have violated any clause or term of this agreement, while preserving all our rights to claim compensation for the resulting damages<span>.</span>
              </li>
              <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
                Transferring or subletting the account without our consent<span>.</span>
              </li>
              <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
                User's failure to fulfill any obligations stipulated in this agreement without taking serious actions to remove this breach, while retaining our right to claim compensations if applicable<span>.</span>
              </li>
              <li className="ListParagraph" style={{ marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
                Inability to authenticate any information you have provided to us<span>.</span>
              </li>
              <li className="ListParagraph" style={{ marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
                If we decide at any time that your activities may cause legal disputes for you or other users<span>.</span>
              </li>
            </ol>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <strong><u><span>Twenty-Three: External Website Links</span></u></strong>
            </p>
            <ol style={{ margin: '0pt', paddingRight: '0pt' }}>
              <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
              The <span>“</span><span>Tygoqo</span><span>”</span> application may contain links to external websites of other parties. We have no control over those websites or their content, and we are not responsible for them or for any loss or damage that may arise from your use of them. You agree not to involve us in any disputes you may have with any third-party website<span>.</span>
              </li>
              <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
              Before enabling any sharing functions on the site to communicate with or visit any of these websites, we recommend that you review and understand the terms and conditions, privacy policies, settings, and information-sharing functions of each third-party website<span>.</span>
              </li>
              <li className="ListParagraph" style={{ marginRight: '13.75pt', textAlign: 'justify', paddingRight: '4.65pt' }}>
              <span>The links on the </span><span>“</span><span>Tygoqo</span><span>”</span><span> </span><span>application do not constitute our endorsement of the use of such websites, and your use of these websites is at your own risk</span><span>.</span>
              </li>
            </ol>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <strong><u><span>Twenty-Four: Applicable Law and Jurisdiction</span></u></strong>
            </p>
            <ol style={{ margin: '0pt', paddingRight: '0pt' }}>
              <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
              This agreement and your use of the service, or any disputes arising directly or indirectly from this agreement or your use of the service, are subject to the laws in force in the Kingdom of Saudi Arabia and shall be interpreted accordingly, without exercising any legal option or conflict of law rules or provisions that may result in the application of the laws of another country besides the Kingdom of Saudi Arabia.
              </li>
              <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
              You agree to resolve any dispute or claim you may have against the <span>“</span><span>Tygoqo</span><span>”</span> application exclusively in the Saudi courts located in Riyadh. You also agree, under these terms, to submit to the exclusive jurisdiction of these courts irrevocably and waive any objection to the non-convenient jurisdiction or venue of these courts.
              </li>
              <li className="ListParagraph" style={{ marginRight: '13.71pt', textAlign: 'justify', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
              If any provision of this agreement is deemed unlawful, void, or unenforceable by a competent court, that provision shall be enforced to the maximum extent permissible by law, and the remaining provisions of the agreement shall remain in full force and effect.
              </li>
            </ol>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <strong><u><span>Twenty-Five: Conflict</span></u></strong>
            </p>
            <p>
              <span>If these terms conflict with any previous versions, the current version shall prevail and be upheld</span><span>.</span>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <strong><u><span>Twenty-Six: Language</span></u></strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span>These terms are written in both Arabic and English, and the Arabic language shall prevail for the purpose of interpreting and enforcing these terms before all official and unofficial bodies if the English language contradicts it</span><span>.</span>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span>&nbsp;</span>
            </p>
            <p>
              <strong><u><span>Twenty-Seven: Receiving and Handling Complaints</span></u></strong>
            </p>
            <ol style={{ margin: '0pt', paddingRight: '0pt' }}>
              <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
              If you have any complaints related to the availability of the application’s services or related technical problems, do not hesitate to contact us around the clock through the available means on the application<span>.</span>
              </li>
              <li className="ListParagraph" style={{ marginRight: '13.71pt', marginBottom: '0pt', paddingRight: '4.69pt', fontFamily: '"DIN Next LT Arabic"' }}>
              The "Tygoqo" application will strive to address the complaint as much as possible. However, you acknowledge that the responsibility for handling complaints related to telecommunications services provided by service providers lies with the service provider and not with the "Tygoqo" application. Nonetheless, the "Tygoqo" application will receive the complaint and forward it to the service provider.
              </li>
            </ol>
            <p className="ListParagraph" style={{ marginRight: '18.4pt' }}>
              <span>&nbsp;</span>
            </p>
            <p>
              <strong><u><span>Twenty-Eight: Customer Service Contact Information for Service Providers</span></u></strong>
            </p>
            <p style={{ lineHeight: '108%', fontSize: '12pt' }}>
              <span style={{ lineHeight: '108%', fontFamily: '"DIN Next LT Arabic"', fontSize: '11pt' }}>Customer service can be reached through the Tygoqo application or the official communication channels of the Tygoqo application: </span><br /><span style={{ lineHeight: '108%', fontFamily: '"DIN Next LT Arabic"', fontSize: '11pt' }}>Email: </span><a href="mailto:Info@Tygo.sa" style={{ textDecoration: 'none' }}><span className="Hyperlink"><span />Info@Tygo.sa</span></a>
            </p>
            <p>
              <span>WhatsApp Number: </span><span><span />+966 555701856</span>
            </p>
            <p style={{ marginRight: '0.4pt' }}>
              <span>&nbsp;</span>
            </p>
            <p>
              <strong><u><span>Twenty-Nine: Agreement Acceptance</span></u></strong>
            </p>
            <p style={{ textAlign: 'justify' }}>
              <span>You acknowledge that you have read these terms and agree to abide by their provisions. If you have any questions regarding this agreement, do not hesitate to contact us through the available means on the application</span><span>.</span>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
          </div>
    );
  }


export default TermsEn;
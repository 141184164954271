import React, { useEffect, useState } from "react";
// styles on same file



const FiltterSection = ({ children, title }: any) => {
    const [filtter, setFiltter] = useState("");
    const [showSection, setShowSection] = useState(false);

    useEffect(() => {
        //check the screen if mobile or desktop
        if (window.innerWidth < 768) {
            setShowSection(false);
        } else {
            setShowSection(true);
        }
    }, []);
    

    
    const handleFiltter = (e: any) => {
        setFiltter(e.target.value);
    };
    
    return (
        <div className="plans-filtter bg-white p-4 p-lg-2 mb-3 m-lg-0" style={{borderRadius:"16px"}}>
            {
                title && (<div className="d-flex align-items-center justify-content-between"
             onClick={() => setShowSection(!showSection)} style={{cursor: "pointer"}}>
                <span className="" style={{fontSize: "0.8rem", fontWeight: "normal", }}>{title}</span>
                <i className="fas fa-chevron-down mx-2" style={{fontSize: ".8rem"}}></i>
            </div> )
            }
            <div className="filtter-section" style={{display: showSection ? "block" : "none"}}>
                    {
                         children
                    }
            </div>
            {/* devider */}
            <div className="d-none d-lg-block" style={{height: "1px", background: "#F4F6F8", margin: "1rem 0"}}></div>

        </div>
    );
};

export default FiltterSection;

import React from 'react';
import { useTranslation } from 'react-i18next';
import './footer.css'
import { BsFacebook, BsInstagram, BsLinkedin, BsWhatsapp, BsEnvelope } from "react-icons/bs";
import { RiTwitterXLine } from "react-icons/ri";

import { Link } from 'react-router-dom';

interface FooterProps {
  // define any props that the component will receive here
}
// AiOutlineTwitter
// AiOutlineFacebook
// AiOutlineInstagram
// AiOutlineLinkedin


const Footer: React.FC<FooterProps> = (props) => {
  const {t,i18n} = useTranslation();
  
  return (
    <footer className="pt-5 pb-0 footer_all">
      <div className="container-lg">
      <div className="d-block do_separator d-lg-flex">
        <div className="col mb-3">
          <Link to={"/"} >
            <img src="/media/logos/default-dark.svg" alt="" width="82" />
          </Link>
          <p className='mt-3'> {t("footer.about_desc")}</p>
          <div className="d-flex gap-5 flex-wrap">
              <div className="d-flex gap-3">
                <div>
                <a className='icon_rounded' href="mailto:info@tygo.sa" target="_blank" rel='noreferrer'>
                <BsEnvelope/>
                </a>
              </div>
                <div>
                  <p className='m-0'>{t("footer.contact")}</p>
                  <span className='c-primary'>info@tygo.sa</span>
                </div>
              </div>
              <div className="d-flex gap-3">
                <div>
                <a className='icon_rounded' href="https://wa.me/966555701856" target="_blank" rel='noreferrer'>
                <BsWhatsapp/>
              </a>
              </div>
                <div>
                  <p className='m-0'>{t("footer.whatsapp")}</p>
                  <span className='c-primary' dir='ltr'
                  >+966 55 570 1856</span>
                </div>
              </div>
          </div>
        </div>
        <div className='d-none d-lg-block ' style={{width:"20%"}}>
          <span style={
            {
              display: 'block',
              width: '1px',
              height: '100%',
              opacity: 0.2,
              background: "#FFF",
              marginLeft: '50%'

            }
          }>
          
          </span>
        </div>

        <div className="col mb-3">
          <h5 className='text-center text-md-start'>{t("footer.download_our_app")}</h5>
          <p>
          {t("footer.download_our_app_desc")}
          </p>
          <div className="d-flex flex-row justify-content-center justify-content-lg-start gap-3 align-items-center align-items-lg-start">
            <a href="https://play.google.com/store/apps/details?id=app.tygo.sa" target="_blank" rel='noreferrer'>
              <img src="./media/buttons/white/google.svg" alt="" width="120" />
            </a>
            <a href="https://apps.apple.com/qa/app/tygo-%D8%AA%D8%A7%D9%8A%D9%82%D9%88/id1619973559" target="_blank" rel='noreferrer'>
              <img src="./media/buttons/white/apple.svg" alt="" width="120" />
            </a>
          </div>
        </div>

      </div>
        <div className="col-lg-3  mb-3">
          <form className='footer-form d-none'>
            <h5>Subscribe to our newsletter</h5>
            {/* <p>Monthly digest of what's new and exciting from us.</p> */}
            <div className="position-relative mt-3">
              <label htmlFor="newsletter1" className="visually-hidden">
                Email address
              </label>
              <input
                id="newsletter1"
                type="text"
                className="form-control"
                placeholder="Email address"
              />
              <button className="subscribe_btn" type="button">
                
                <i className={"fas fa-arrow-" + (i18n.language === 'ar' ? 'left' : 'right')} />
              </button>
            </div>
          </form>
        </div>
        <div className="pt-3 border-top">
          <ul className="nav justify-content-between c-white mx-5">
            <li className="nav-item mb-2">
              <Link to={"/"} className="nav-link p-0 c-white">
                 {t("footer.home")}
              </Link>
            </li>
            {/* <li className="nav-item mb-2">
              <Link to={"/esims"} className="nav-link p-0 c-white">
               {t("footer.esim_for_traveling")}
              </Link>
            </li> */}
            <li className="nav-item mb-2">
              <Link to={"/plans"} className="nav-link p-0 c-white">
               {t("footer.plans")}
              </Link>
            </li>
            <li className="nav-item mb-2">
              <Link to={"/professionals"} className="nav-link p-0 c-white">
                {t("footer.professionals")}
              </Link>
            </li>
            {/* <li className="nav-item mb-2">
              <Link to={"/blog"} className="nav-link p-0 c-white">
               {t("footer.blog")}
              </Link>
            </li> */}
            <li className="nav-item mb-2">
              <Link to={"/about"} className="nav-link p-0 c-white">
               {t("footer.about")}
              </Link>
            </li>
            <li className="nav-item mb-2">
              <Link to={"/terms"} className="nav-link p-0 c-white">
                {t("footer.terms")}
              </Link>
            </li>
            <li className="nav-item mb-2">
              <Link to={"/privacy"} className="nav-link p-0 c-white">
                {t("footer.privacy")}
              </Link>

            </li>
            <li className="nav-item mb-2">
              <a href={"https://intercom.help/tygo/ar"} className="nav-link p-0 c-white" target="_blank">
                {t("footer.help_center")}
              </a>
            </li>
            
          </ul>
        </div>

        <div className="d-flex flex-column  flex-sm-row justify-content-between pb-4 mt-3 align-items-center">
        <ul className="list-unstyled iconsss d-flex justify-content-center mb-3 mb-lg-0 gap-3">
             
          
            {/* <li className="mx-0">
            <a className='icon_rounded' href="https://twitter.com/Tygo_app" target="_blank" rel='noreferrer'>
              <BsFacebook />
              </a>
            </li> */}
            <li className="mx-0">
            <a className='icon_rounded' href="https://www.instagram.com/tygo.app" target="_blank" rel='noreferrer'>
              <BsInstagram />
              </a>
            </li>
            <li className="mx-0">
            <a className='icon_rounded' href="https://www.linkedin.com/company/tygo-app" target="_blank" rel='noreferrer'>
              <BsLinkedin />
              </a>
            </li>
            <li className="mx-0">
              <a className='icon_rounded' href="https://twitter.com/Tygo_app" target="_blank" rel='noreferrer'>
              <RiTwitterXLine />
              </a>
            </li>
          </ul>
         
          <p className='rights_footer mb-0' style={{color:'#9A9EA6'}}> {t("footer.rights")} </p>
         
        </div>
        </div>
      </footer>
      
      );
    };
    
    export default Footer;
    
    
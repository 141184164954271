import Header from "../../components/header/header";
import '../about/about.css'
import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

const AuctionTerms = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isHeadless = searchParams.get('headless');

    return <div >
        <div className="container about mb-5" >
    {
                isHeadless !== 'true' &&
                <Header/>
        
    }
   <TermsInner/>
   
</div>
    <Footer/>
</div>

}
// شروط وأحكام خدمة المزاد:

// أن يكون المشاركون مواطنين سعوديين أو مقيمين في السعودية أو من دول مجلس التعاون الخليجي.
// يجب ألا يقل عمر المشاركين عن 15 عام.
// المشاركة في المزاد متاحة لجميع عملاء شركات الاتصالات.
// على المشاركين في مزاد الأرقام دفع مبلغ تأ ة المحددة لكل رقم بالإضافة إلى ضريبة القيمة
// م بارجاع المبلغ في حال عدم فوز المشارك بعد نهاية المزاد لجميع المشاركين بعد 14 يوم
// عمل على نفس وسيلة الدفع المستخدمة سابقا.
// لا يمكن استخدام مبلغ التأمين المدفوع إلا لمزايدة واحدة.

// يمكن للمشترك الإنضمام والمزايدة في أكثر من مزاد في نفس الوقت لأرقام متعددة. طالما أنه يدفع مبلغ
// التأمين مج ضريبة القيمة المضافة لكل رقم والتي تحجزها تايقو حتى انتهاء المزاد.

// مدة المزادات مختلفة وهي تحدد من قبل تايقو بعد الاتفاق مع مقدم الخدمة.
// سيمنح الفائز بالمزاد أسبوع واحد من تاريخ انتهاء المزاد لدفع المبلغ بالكامل بالإضافة إلى ضريبة القيمة
// المضافة. كما سيتم إرسال رابط صفحة الدفج إلى رقمه برسالة نصية وبريده الإلكتروني في نهاية المزاد أو يكمل
// عملية الدف من خلال التطبيق.

// في حال تخلف الفائز عن دفع كامل المبلغ بالإضافة إلى ضريبة القيمة المضافة خلال الوقت المحدد (سبعة أيام)/
// فلن يتم إرجاع مبلغ التأمين وضريبة القيمة المضافة. وسيكون من حق تايقو التصرف بهذا المبلغ.

// سيكون الحد الأدنى لبدء المزاد موجود عند كل رقم بالإضافة إلى ضريبة القيمة المضافة.

// يحق لتايقو منع أي شخص من المشاركة في المزاد أو إلغاء مزايدته إذا خالف الشروط والأحكام.

// يحق لتايقو تغيير الأسعار وتمديد وقت انتهاء المزاد في أي وقت بعد الاتفاق مع مقدم الخدمة.

// تنص لوائح هيئة الاتصالات والفضاء والتقنية (7ك©) على أن هناك حد لعدد الخطوط التي يمكن للعميل تسجيلها
// برقم هويته الموثقة لدى مشغلي الاتصالات. لذلك لن يتم بيج رقم المزاد للمشارك إذا تجاوز حد الخطوط
// المسموح بها.

// سيكون المزاد معروض للمشاركين وغير المشاركين ولكن المزايدة محددة للمسجلين فقط في تايقو.

// لن يتم قبول أي مدفوعات جزئية ويجب دفع مبلغ الرقم بالكامل.

 
export const TermsInner = () => {
    const { i18n} = useTranslation();
    return  (
        i18n.language === "ar" ?
        <>
        <div style={{maxWidth: "900px", margin: "50px auto auto"}}>

            <h1 className="text-center mb-5 h1">
                شروط وأحكام خدمة المزاد
            </h1>
            <ul>
                <li className="mb-3">
                يجب أن يكون المشاركون مواطنين سعوديين أو مقيمين في السعودية أو من دول مجلس التعاون الخليجي.
                </li>
                <li className="mb-3">
                    يجب ألا يقل عمر المشاركين عن 15 عام.
                </li>
                <li className="mb-3">
                    المشاركة في المزاد متاحة لجميع عملاء شركات الاتصالات.
                </li>
                <li className="mb-3">
                يجب على المشاركين في مزاد الأرقام دفع مبلغ تأ ة المحددة لكل رقم بالإضافة إلى ضريبة القيمة
                    م بارجاع المبلغ في حال عدم فوز المشارك بعد نهاية المزاد لجميع المشاركين بعد 14 يوم
                    عمل على نفس وسيلة الدفع المستخدمة سابقا.
                </li>
                <li className="mb-3">
                    لا يمكن استخدام مبلغ التأمين المدفوع إلا لمزايدة واحدة.
                </li>
                <li className="mb-3">
                    يمكن للمشترك الإنضمام والمزايدة في أكثر من مزاد في نفس الوقت لأرقام متعددة. طالما أنه يدفع مبلغ
                    التأمين مج ضريبة القيمة المضافة لكل رقم والتي تحجزها تايقو حتى انتهاء المزاد.
                </li>
                <li className="mb-3">
                    مدة المزادات مختلفة وهي تحدد من قبل تايقو بعد الاتفاق مع مقدم الخدمة.
                </li>
                <li className="mb-3">
                    سيمنح الفائز بالمزاد أسبوع واحد من تاريخ انتهاء المزاد لدفع المبلغ بالكامل بالإضافة إلى ضريبة القيمة
                    المضافة. كما سيتم إرسال رابط صفحة الدفج إلى رقمه برسالة نصية وبريده الإلكتروني في نهاية المزاد أو يكمل
                    عملية الدف من خلال التطبيق.
                </li>
                <li className="mb-3">
                    في حال تخلف الفائز عن دفع كامل المبلغ بالإضافة إلى ضريبة القيمة المضافة خلال الوقت المحدد (سبعة أيام)/
                    فلن يتم إرجاع مبلغ التأمين وضريبة القيمة المضافة. وسيكون من حق تايقو التصرف بهذا المبلغ.
                </li>
                <li className="mb-3">
                    سيكون الحد الأدنى لبدء المزاد موجود عند كل رقم بالإضافة إلى ضريبة القيمة المضافة.
                </li>
                <li className="mb-3">
                    يحق لتايقو منع أي شخص من المشاركة في المزاد أو إلغاء مزايدته إذا خالف الشروط والأحكام.
                </li>
                <li className="mb-3">
                    يحق لتايقو تغيير الأسعار وتمديد وقت انتهاء المزاد في أي وقت بعد الاتفاق مع مقدم الخدمة.
                </li>
                <li className="mb-3">
                    تنص لوائح هيئة الاتصالات والفضاء والتقنية (CST) على أن هناك حد لعدد الخطوط التي يمكن للعميل تسجيلها
                    برقم هويته الموثقة لدى مشغلي الاتصالات. لذلك لن يتم بيج رقم المزاد للمشارك إذا تجاوز حد الخطوط
                    المسموح بها.
                </li>
                <li className="mb-3">
                    سيكون المزاد معروض للمشاركين وغير المشاركين ولكن المزايدة محددة للمسجلين فقط في تايقو.
                </li>
                <li className="mb-3">
                    لن يتم قبول أي مدفوعات جزئية ويجب دفع مبلغ الرقم بالكامل.
                </li>
            </ul>
        </div>
        </>
        :
        <>
         <div style={{maxWidth: "900px", margin: "50px auto auto"}}>
            <h1 className="text-center mb-5 h1">
              Auction Service Terms and Conditions
            </h1>
            <ul>
                <li className="mb-3">
                Participants must be Saudi citizens or residents in Saudi Arabia or from the Gulf Cooperation Council countries.
                </li>
                <li className="mb-3">
                    Participants must be at least 15 years old.
                </li>
                <li className="mb-3">
                    Participation in the auction is open to all telecommunications company customers.
                </li>
                <li className="mb-3">
                Participants in the number auction must pay a specified insurance amount for each number plus value-added tax. The amount will be refunded if the participant does not win after the auction ends to all participants after 14 working days on the same previously used payment method.
                </li>
                <li className="mb-3">
                    The paid insurance amount can only be used for one bid.
                </li>
                <li className="mb-3">
                    The subscriber can join and bid in more than one auction at the same time for multiple numbers, as long as he pays the insurance amount plus value-added tax for each number, which Tygo reserves until the end of the auction.
                </li>
                <li className="mb-3">
                    The duration of the auctions varies and is determined by Tygo after agreement with the service provider.
                </li>
                <li className="mb-3">
                    The auction winner will be given one week from the date of the end of the auction to pay the full amount plus value-added tax. A link to the payment page will also be sent to his number by text message and his email at the end of the auction or he can complete the payment process through the application.
                </li>
                <li className="mb-3">
                    If the winner defaults on paying the full amount plus value-added tax within the specified time (seven days), the insurance amount and value-added tax will not be returned. And Tygo has the right to dispose of this amount.
                </li>
                <li className="mb-3">
                    The minimum limit to start the auction will be present at each number plus value-added tax.
                </li>
                <li className="mb-3">
                    Tygo has the right to prevent any person from participating in the auction or cancel his bid if he violates the terms and conditions.
                </li>
                <li className="mb-3">
                    Tygo has the right to change prices and extend the auction end time at any time after agreement with the service provider.
                </li>
                <li className="mb-3">
                    The regulations of the Communications, Space and Technology Authority (CST) stipulate that there is a limit to the number of lines that the customer can register with his authenticated identity number with telecommunications operators. Therefore, the auction number will not be paged to the participant if he exceeds the allowed line limit.
                </li>
                <li className="mb-3">
                    The auction will be available to participants and non-participants, but bidding is limited to those registered only in Tygo.
                </li>
                <li className="mb-3">
                    No partial payments will be accepted and the full number amount must be paid.
                </li>
            </ul>
        </div>
        </>
    )
}

export default AuctionTerms;



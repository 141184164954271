import React, { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/esm/Offcanvas';
import './CompareCard.css'
import { toast } from "react-toastify";
import Button from '../design/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaRegTimesCircle } from 'react-icons/fa';

class plan {
    id: number;
    title: string;
    price: number;
    currency: string;
    image: string;
    constructor(id: number, title: string, price: number, currency: string, image: string) {
        this.id = id;
        this.title = title;
        this.price = price;
        this.currency = currency;
        this.image = image;
    }
}

export const CompareCardContext = React.createContext(null as any);

const CompareCardProvider = ({ children }: any) => {
    const {t,i18n} = useTranslation();
    const [plans, setPlans] = useState<plan[]>([]);
    const navigate = useNavigate();
    //eanch time the plan changes, add it to the list
    const addToCart = ( plan:plan,elementId: string,) => {
        if(isInCart(plan))
        {
            toast.warn(t('compare_card.already_added'), {
                position: toast.POSITION.TOP_CENTER
                });
            return;
        }
        if(elementId !== '') {
            //animate the element
            let element = document.getElementById(elementId);
            if (!element) return;
            let clonedElement = element.cloneNode(true);
            //put it over the original element
            if(clonedElement instanceof HTMLElement) {
              clonedElement.style.top = `${element.offsetTop}px`;
              clonedElement.style.left = `${element.offsetLeft}px`;
            }
            //add the animation class
            (clonedElement as HTMLElement).classList.add("dragged");
            let cart = document.querySelector('.cart');
            let objectRect = element.getBoundingClientRect();
            
            let cartRect = cart!.getBoundingClientRect();
            let eleWidth = element.offsetWidth;
            let eleHeight = element.offsetHeight;
            let xDistance = cartRect.left - objectRect.left;
            let yDistance = cartRect.top - objectRect.top;

            // create keyframe animation
            let keyFrames =
                `@keyframes moveToCart {
                    from {
                    transform: translate(0, 0)
                    opacity: .6;
                    width: ${eleWidth}px;
                    height: ${eleHeight}px;
                    }
                    to {
                    transform: translate(${xDistance}px, ${yDistance}px) scale(0);

                    opacity: 0;
                    width: ${eleWidth}px;
                    }
                }`
            let style = document.createElement('style');
            style.innerHTML = keyFrames;
            document.head.appendChild(style);
            //add the element to the body
            document.body.appendChild(clonedElement);
            
            setPlans([...plans, plan]);
            
        }
        else
        {
            
            setPlans([...plans, plan]);
        }
    }

    //remove the plan from the list
    const removeFromCart = (plan: any) => {
        setPlans(plans.filter((p: any) => p.id !== plan.id));
    }

    //check if the plan is already in the list
    const isInCart = (plan: any) => {
        return plans.some((p: any) => p.id === plan.id);
    }

    //render the list of plans
    const renderPlans = () => {
        return plans.map((plan: any, index: number) => {
            return (
                <div className={"plan-in-card me-4" + (index === plans.length - 1 ? '' : (i18n.language === 'ar' ? ' border-left-plan' : ' border-right-plan'))}
                key={plan.id}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="plan-image">
                            <img src={plan.image} alt={plan.title} width="40" height="40" className="border rounded-circle" />
                        </div>
                        <div className="plan-remove" onClick={() => removeFromCart(plan)}>
                            <FaRegTimesCircle />
                        </div>
                    </div>
                    <div className="plan-title">
                        {plan.title}
                    </div>
                    <div className='d-flex gap-2 align-items-center'>
                        <div className="plan-type m-0 ">
                            {plan.type}
                        </div>   
                        <div className="plan-price m-0 border-left-plan">
                            {plan.price} {plan.currency}
                        </div>
                    </div>
                </div>
            )
        })
    }
            
    



        

    const pathInfo = window.location.pathname.split('/');
    const nouser= localStorage.getItem('tygo_user') ? false : true;

    

  return (
    <CompareCardContext.Provider value={{plans, addToCart, removeFromCart, isInCart, renderPlans}}>
        <div className={nouser ? "nouser" : ""}>
       {children}
        {(
            !pathInfo.includes('stepper')
            // && (((plans.length > 0) || (pathInfo.includes('compare') || pathInfo.includes('plans'))))
        ) && (
        <div className=
        {"cart closed" + ((plans.length > 0) || (pathInfo.includes('compare') || pathInfo.includes('plans')) ? ' d-block' : ' d-none')}
         onClick={() => {
            // const cart = document.querySelector('.cart');
            // if(cart instanceof HTMLElement && cart.classList.contains('closeed')) {
            //     cart.classList.remove('closeed');
            // }
        }}
        onDragOver={(e) => {
            //change the cursor to a hand
            e.currentTarget.style.cursor = 'pointer';
            //log the element html that is being dragged
            e.currentTarget.style.opacity = '0.9';
            //and bigger
            e.currentTarget.style.transform = 'scale(1.01)';
            //add highlight
            e.currentTarget.classList.add('card-highlight');
            e.preventDefault();
        }}

        onDragLeave={(e) => {
            //change the cursor to a hand
            e.currentTarget.style.cursor = 'default';
            //opacity of the cart
            e.currentTarget.style.opacity = '1';
            //and bigger
            e.currentTarget.style.transform = 'scale(1)';
            //add highlight
            e.currentTarget.classList.remove('card-highlight');
        }}
        
        onDrop={(e) => {
            //change the cursor to a hand
            e.currentTarget.style.cursor = 'default';
            //opacity of the cart
            e.currentTarget.style.opacity = '1';
            //and bigger
            e.currentTarget.style.transform = 'scale(1)';
            //add highlight
            e.currentTarget.classList.remove('card-highlight');
            var data = e.dataTransfer.getData("plan");
            if(!data || typeof JSON.parse(data) !== 'object')
                return;
            
            const plan = JSON.parse(data);
            if(!isInCart(plan))
                addToCart(plan, '');
            else
            {
                toast.warn("Plan already added to the list", {
                    position: toast.POSITION.TOP_CENTER
                    });
                return;
            }

            e.preventDefault();
        }}

        


        >
            {/* <button className="position-absolute close-button" onClick={() => {
                const cart = document.querySelector('.cart');
                if(cart instanceof HTMLElement) {
                    cart.classList.add('closed');
                }
            }}><i className="fa fa-times"></i></button> */}
            
            <div className="inner-button" onClick={() => {
                const cart = document.querySelector('.cart');
                if(cart instanceof HTMLElement) {
                    cart.classList.remove('closed');
                }
            }}>

                <img src="/media/svgs/compare.svg" alt="compare" />
                <strong>{t('compare_card.compare')}</strong>
                <span className="cart-count">{plans.length}</span>
            </div>

            {/* <div className="cart-header">
                <i className="fa fa-shopping-cart"></i>
                <span className="cart-title">Compare Plans</span>
                <span className="cart-count">{plans.length}</span>
                <i className="fa fa-times"></i>
            </div> */}
            <div className="cart-body">
                {plans.length > 0 ? renderPlans() : <div className="empty-cart">
                   {t('compare_card.no_plans')}
                    {/* <button className="btn btn-primary w-100" onClick={() => {
                        const cart = document.querySelector('.cart');
                        if(cart instanceof HTMLElement)
                            cart.classList.add('closeed');
                    }}>Close</button> */}

                    </div>}
            </div>
            {
                plans.length > 1 ? (
                    <div className="cart-footer">
                        <span className="cart-total">{t('compare_card.total')}:
                         {plans.length}</span>
                        <div className="cart-buttons d-flex gap-3">
                            <Button color="secondary p-3" onClick={() => {
                                                                const cart = document.querySelector('.cart');
                                                                if(cart instanceof HTMLElement)
                                                                    cart.classList.add('closed');
                                
                            }} inner={t("compare_card.close")} />

                            <Button color="primary p-3" onClick={() => {
                                const cart = document.querySelector('.cart');
                                if(cart instanceof HTMLElement)
                                    cart.classList.add('closed');
                            
                                navigate('/compare');
                            }} inner={t("compare_card.compare")} />

                        </div>
                    </div>
                ) : plans.length === 1 ? (
                    <div className="cart-footer">
                        <span className='alert alert-danger m-0'>{t('compare_card.add_more')}</span>
                     
                    </div>
                ) : null

            }
        
        </div>
        )}
        </div>
    </CompareCardContext.Provider>
  );
}

export default CompareCardProvider;


import { useTranslation } from "react-i18next"
import Footer from "../../components/footer/footer"
import Header from "../../components/header/header"
import { Advantages } from "./components/advantages"
import Countries from "./components/countries/countries"
import Faq from "./components/faq"
import Hero from "./components/hero"
import Midle from "./components/midle"
import Slider from "./components/slider"
import './esims.css'
import { useEffect, useState } from "react"
import { getCountries } from "../../services/providers.service"
export const EsimsScreen = () => {
    const {t, i18n} = useTranslation();
    const [countries, setCountries] = useState([]);
    const [countries_, setCountries_] = useState([]);
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'page_view',
          category: 'page_load',
          action: 'user_visited_page',
        });
      }, []); // Empty array ensures this runs only once when the component mounts

    useEffect(() => {
        const fetchData = async () => {
                const result = await getCountries(i18n.language);
                const result_ = await getCountries(i18n.language === "en" ? "ar" : "en");
                console.log(result.returnData);
                setCountries(result.returnData);
                setCountries_(result_.returnData); 
        };
        fetchData();
    }, [i18n.language]);

    

        


    return (
        <div className="esims_container" style={{backgroundImage: "url(./media/hero/bg2.png)", backgroundColor:"#F7F7F7" }}>
            <div className="container">
            <img src="./media/hero/bg2.png" alt="" style={{
                position: "absolute",
                bottom: "0",
                left: "0",
                zIndex: -1,

            }} 
            className="ba_section"
            />
            <Header />
            <Hero countries={[...countries, ...countries_]} />
            <Countries countries={
                countries
            }
            />
            {/* {[
                {
                    title: t("esim.section2.egypt"),
                    image: "./media/flags/egypt.png",
                    price: "$ 0.00"
                },
                {
                    title: t("esim.section2.lebanon"),
                    image: "./media/flags/lebanon.png",
                    price: "$ 0.00"
                },
                
                {
                    title: t("esim.section2.uae"),
                    image: "./media/flags/united-arab-emirates.png",
                    price: "$ 0.00"
                    
                },
                {
                    title: t("esim.section2.kuwait"),
                    image: "./media/flags/kuwait.png",
                    price: "$ 0.00"
                },
                {
                    title: t("esim.section2.tunisia"),
                    image: "./media/flags/tunisia.png",
                    price: "$ 0.00"
                },
                {
                    title: t("esim.section2.turkey"),
                    image: "./media/flags/turkey.png",
                    price: "$ 0.00"
                },
                {
                    title: t("esim.section2.uk"),
                    image: "./media/flags/united-kingdom.png",
                    price: "$ 0.00"
                },
                {
                    title: t("esim.section2.usa"),
                    image: "./media/flags/united-states-of-america.png",
                    price: "$ 0.00"
                },
                {
                    title: t("esim.section2.jordan"),
                    image: "./media/flags/jordan.png",
                    price: "$ 0.00"
                },
                {
                    title: t("esim.section2.egypt"),
                    image: "./media/flags/egypt.png",
                    price: "$ 0.00"
                },
                {
                    title: t("esim.section2.lebanon"),
                    image: "./media/flags/lebanon.png",
                    price: "$ 0.00"
                },
                
                {
                    title: t("esim.section2.uae"),
                    image: "./media/flags/united-arab-emirates.png",
                    price: "$ 0.00"
                    
                },
                {
                    title: t("esim.section2.kuwait"),
                    image: "./media/flags/kuwait.png",
                    price: "$ 0.00"
                },
                {
                    title: t("esim.section2.tunisia"),
                    image: "./media/flags/tunisia.png",
                    price: "$ 0.00"
                },
                {
                    title: t("esim.section2.turkey"),
                    image: "./media/flags/turkey.png",
                    price: "$ 0.00"
                },
                {
                    title: t("esim.section2.uk"),
                    image: "./media/flags/united-kingdom.png",
                    price: "$ 0.00"
                },
                {
                    title: t("esim.section2.usa"),
                    image: "./media/flags/united-states-of-america.png",
                    price: "$ 0.00"
                },
                {
                    title: t("esim.section2.jordan"),
                    image: "./media/flags/jordan.png",
                    price: "$ 0.00"
                }
            ]}  */}
            </div>
            <Midle />
            <div className=" mb-4 container">
            <Advantages />
            <div id="how_to_install_esim">
            <Slider 
                inners={[[
                    {
                        title: t("esim.section5.element1.item1.title"),
                        content: t("esim.section5.element1.item1.sub_title"),
                        image_en: "./media/slider/1.png",
                        image_ar: "./media/slider/11.png"
                    },
                    {
                        title: t("esim.section5.element1.item2.title"),
                        content: t("esim.section5.element1.item2.sub_title"),
                        image_en: "./media/slider/2.png",
                        image_ar: "./media/slider/12.png"
                    },
                    {
                        title: t("esim.section5.element1.item3.title"),
                        content: t("esim.section5.element1.item2.sub_title"),
                        image_en: "./media/slider/3.png",
                        image_ar: "./media/slider/13.png"
                    },
                    {
                        title: t("esim.section5.element1.item4.title"),
                        content: t("esim.section5.element1.item4.sub_title"),
                        image_en: "./media/slider/4.png",
                        image_ar: "./media/slider/14.png"

                    },
                    {
                        title: t("esim.section5.element1.item5.title"),
                        content: t("esim.section5.element1.item5.sub_title"),
                        image_en: "./media/slider/5.png",
                        image_ar: "./media/slider/15.png"
                    },
                ],[
                    {
                        title: t("esim.section5.element2.item1.title"),
                        content: t("esim.section5.element1.item1.sub_title"),
                        image_en: "./media/slider/6.png",
                        image_ar: "./media/slider/16.png"
                    },
                    {
                        title: t("esim.section5.element2.item2.title"),
                        content: t("esim.section5.element1.item.2sub_title"),
                        image_en: "./media/slider/7.png",
                        image_ar: "./media/slider/17.png"
                    },
                    {
                        title: t("esim.section5.element2.item3.title"),
                        content: t("esim.section5.element1.item3.sub_title"),
                        image_en: "./media/slider/8.png",
                        image_ar: "./media/slider/18.png"

                    },
                    {
                        title: t("esim.section5.element2.item4.title"),
                        content: t("esim.section5.element1.item4.sub_title"),
                        image_en: "./media/slider/9.png",
                        image_ar: "./media/slider/19.png"
                    },
                    {
                        title: t("esim.section5.element2.item5.title"),
                        content: t("esim.section5.element1.item5.sub_title"),
                        image_en: "./media/slider/10.png",
                        image_ar: "./media/slider/20.png"
                    }

                ]]}
                seperator={<div className="slider-seperator">
                    <div className="slider-seperator__title">
                        <h1>Choose your plan</h1>
                    </div>
                    <div className="slider-seperator__content">
                        <p>Choose your plan and get your eSIM</p>
                    </div>
                </div>}
            />
            </div>
            <Faq />
            </div>
            <Footer />
        </div>
    )
}


import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/header/header';
import { getProviders } from '../../services/providers.service';
import './about.css'

import Animate  from './Animate';
import Footer from '../../components/footer/footer';
import { useLocation } from 'react-router-dom';
import DownloadSection from '../landing/components/download';

const AboutUs = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isHeadless = searchParams.get('headless');

    const {t, i18n} = useTranslation();
    const [providers, setProviders] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);


    const fetchProviders = async () => {
        const response = await getProviders(i18n.language);
        setProviders(response.returnData);
        setLoading(false);
    }

    useEffect(() => {
        fetchProviders();
        
    }, [i18n.language]);
    
   
        
return (
    <>
    <div className="container about">
    {
        isHeadless !== 'true' &&
        <Header/>
    }
    <div className="theApp-body">
    <div className="vc_row wpb_row vc_row-fluid"><div className="wpb_column vc_column_container vc_col-sm-12"><div className="vc_column-inner"><div className="wpb_wrapper">
         <div className="aboutPage">
            {/* <img className="aboutPage-bg" src="bg.d5f2c53e.png" srcSet="https://tygo.sa/wp-content/themes/mainstream/public/static/img/bg.d5f2c53e.png" alt=""/>  */}
        </div>
        <Animate
        // 300 delay
        // ease-out-back
delay={0.1}
>
    <section className="pt-md "   
>
        <div className="container">
            <div className="align-items-center gx-lg row ">
    
      
                <div className="col-lg-6 pb-md">
                    <div className="d-lg-none mt-lg"></div>
                    <Animate delay={0.3}>
                    <h1 className="fs-xxl mb-e7 sal-animate" data-sal="slide-up" data-sal-delay="300">{t('about.story')}</h1>
                    </Animate>
                    <Animate delay={0.4}>
                    <p className="fs-xs mb-e2 sal-animate" data-sal="slide-up" data-sal-delay="400">{t('about.story_p1')}</p>
                    </Animate>
                    <Animate delay={0.5}>
                    <p className="fs-xs mb-0 sal-animate" data-sal="slide-up" data-sal-delay="500">{t('about.story_p2')}</p>
                    </Animate>
                </div>
                <div className="col-lg-6 col-sm-12 order-lg sal-animate" data-sal="slide-up" data-sal-delay="200"><img className="img-fluid" src="https://tygo.sa/wp-content/uploads/2022/05/1@2x.603f87da.png" srcSet="https://tygo.sa/wp-content/uploads/2022/05/1@2x.603f87da.png" alt=""/></div>
            </div>
        </div>
    </section>
    </Animate>
    <Animate delay={0.1}>

    <section className="pt-xs sal-animate" data-sal="slide-up" data-sal-delay="100">
        <div className="container">
            <div className="gx-3 row">
                <div className="col-lg-5"></div>
                <div className="col-lg-7 sal-animate" data-sal="slide-up" data-sal-delay="200">
                <Animate delay={0.2}>
                    <div className="bg-warning p-lg rounded-3 text-dark">
                        <h3 className="fs-xl">{t('about.vision')}</h3>
                        <p className="fs-sm mb-0">{t('about.vision_p1')}</p>
                    </div>
                </Animate>
                </div>
            </div>
        </div>
    </section>
    </Animate>
    <Animate delay={0.1}>
    <section className="pt-3 sal-animate" data-sal="slide-up" data-sal-delay="100">
        <div className="container">
            <div className="gx-3 row">

                <div className="col-lg-5 sal-animate" data-sal="slide-up" data-sal-delay="200">
                <Animate delay={0.2}>
                    <div className="align-items-center bg-primary d-flex h-100 p-lg rounded-3 text-white">
                        <div>
                            <h3 className="fs-xl">{t('about.mission')}</h3>
                            <p className="fs-sm mb-0">{t('about.mission_p1')}</p>
                        </div>
                    </div>
                </Animate>
                </div>
                <div className="col-lg-7">
                    <div className="d-lg-none mt-3"></div>
                    <Animate delay={0.3}>
                    <div>
                        <div className="aboutAnimate-1 bg-info d-inline-block p-sm rounded-3 sal-animate h-0" data-sal="slide-up" data-sal-delay="300"><img className="img-fluid" src="https://tygo.sa/wp-content/themes/mainstream/public/static/img/3.d354a649.svg" alt=""/></div>
                    </div>
                    </Animate>
                    <Animate delay={0.4}>
                    <div className="d-flex justify-content-end mt-3 sal-animate " data-sal="slide-up" data-sal-delay="400">
                        <div className="aboutAnimate-2 bg-primary d-inline-block p-sm rounded-3 h-0"><img className="img-fluid" src="https://tygo.sa/wp-content/themes/mainstream/public/static/img/4.777d5c88.svg" alt=""/></div>
                    </div>
                    </Animate>
                </div>
            </div>
        </div>
    </section>
    </Animate>
    </div></div></div></div><div className="vc_row wpb_row vc_row-fluid"><div className="wpb_column vc_column_container vc_col-sm-12"><div className="vc_column-inner"><div className="wpb_wrapper">
    <Animate delay={0.1}>
    <section className="pt-3 sal-animate" data-sal="slide-up" data-sal-delay="100">
        <div className="container">
            <div className="gx-3 row">
                <div className="col-lg-5 mb-3" id="order">
                    <div className="pt-md">
                        <Animate delay={0.2}>
                        <h3 className="fs-xl max-xs mb-0 sal-animate" data-sal="slide-up" data-sal-delay="200">{t('about.values')}</h3>
                        </Animate>
                    </div>
                    <Animate delay={0.3}>
                    <div className="aboutAnimate-3 bg-warning d-inline-block mb-2 p-xs rounded-3 text-white mt-sm sal-animate h-0" data-sal="slide-up" data-sal-delay="300"><img className="img-fluid" src="https://tygo.sa/wp-content/themes/mainstream/public/static/img/2.9e3b8986.svg" alt=""/></div>
                    </Animate>
                    <Animate delay={0.4}>
                    <div className="bg-danger p-lg rounded-3 text-white sal-animate" data-sal="slide-up" data-sal-delay="400">
                        <h3 className="fs-lg mb-xs">{t('about.control')}</h3>
                            {/* <a className="btn btn-warning rounded-pill" href="https://tygo.sa/join/" title={t('about.join_list')+''}
                            >{t('about.join_list')}</a> */}
                    </div>
                    </Animate>
                </div>
                <div className="col-lg-7">
                    <Animate delay={0.05}>
                    <div className="bg-secondary p-lg rounded-3 text-white sal-animate" data-sal="slide-up" data-sal-delay="50">
                        <div className="aboutItem sal-animate" data-sal="slide-up" data-sal-delay="100">
                            <svg className="svgIcon">
                                <use xlinkHref="#icon-about-1"></use></svg>
                            <div className="aboutItem-body">
                        <Animate delay={0.1}>
                                <h4 className="fs-sm mb-e7">{t('about.values_p1')}</h4>
                                <p className="fs-xxs mb-0 opacity-50"></p>
                        </Animate>
                            </div>
                        </div>
                        <div className="aboutItem sal-animate" data-sal="slide-up" data-sal-delay="100">
                            <svg className="svgIcon">
                                <use xlinkHref="#icon-about-2"></use></svg>
                            <div className="aboutItem-body">
                        <Animate delay={0.1}>
                                <h4 className="fs-sm mb-e7">{t('about.values_p2')}</h4>
                                <p className="fs-xxs mb-0 opacity-50"></p>
                        </Animate>
                            </div>
                        </div>
                        <div className="aboutItem sal-animate" data-sal="slide-up" data-sal-delay="100">
                            <svg className="svgIcon">
                                <use xlinkHref="#icon-about-3"></use></svg>
                            <div className="aboutItem-body">
                        <Animate delay={0.1}>
                                <h4 className="fs-sm mb-e7">{t('about.values_p3')}</h4>
                                <p className="fs-xxs mb-0 opacity-50"></p>
                        </Animate>
                            </div>
                        </div>
                        <div className="aboutItem sal-animate" data-sal="slide-up" data-sal-delay="100">
                            <svg className="svgIcon">
                                <use xlinkHref="#icon-about-4"></use></svg>
                            <div className="aboutItem-body">
                        <Animate delay={0.1}>
                                <h4 className="fs-sm mb-e7">{t('about.values_p4')}</h4>
                                <p className="fs-xxs mb-0 opacity-50"></p>
                        </Animate>
                            </div>
                        </div>
                    </div>
                    </Animate>
                </div>
            </div>
        </div>
    </section>
    </Animate>



    </div></div></div></div><div className="vc_row wpb_row vc_row-fluid"><div className="wpb_column vc_column_container vc_col-sm-12"><div className="vc_column-inner"><div className="wpb_wrapper">



    <section className="pt-lg pb-xxl">
        <div className="container">
            <Animate delay={0.1}>
            <div className="max-md mb-md mx-auto text-center sal-animate" data-sal="slide-up" data-sal-delay="100">
                <h3 className="fs-xl mb-0">{t('about.compare')}</h3>
            </div>
            </Animate>
            <Animate delay={0.2}>
            <div className="justify-content-center row sal-animate" data-sal="slide-up" data-sal-delay="200">
{
    providers.map((provider,index)=>{
       return <div className="col-4 col-lg-2 col-md-3 col-sm-4 mb-col">
        <div className="text-center">
            <div className="appLogo mx-auto">
                <div className={"appImage inViewJs is-1-1 rounded-pill " + (!loading ? 'loaded' : '')}
                ><img 
                data-src={provider.imagePathSmall}
                alt="Stc" 
                src={provider.imagePathSmall}/></div>
            </div>
            <h4 className="fs-sm mb-0 mt-e5">  {provider.name}</h4>
        </div>
    </div>
    })
}
                                                  </div>
                                                  </Animate>
        </div>
    </section>




    </div></div></div></div>

</div>

</div>
<Footer />
</>
)
}

const AboutUsHeadless = () => {
    const {t, i18n} = useTranslation();
    return (
        <div style={{backgroundImage: "url(./media/hero/bg2.png)", backgroundColor:"#F7F7F7" ,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundAttachment: 'fixed'
        }}>
        
        <div className='container about'>
            <Header />
            <div id='section-1'>
            <div className="row my-5 py-4 justify-content-center">
                    <div className="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12 d-flex flex-column align-items-start justify-content-center my-2" >
                        <Animate delay={0.2}>
                            <p className="tag mt-5 c-primary mx-auto mx-md-0 mb-0">{t("about.title")}</p>
                        </Animate>
                        <Animate delay={0.2}>
                        <h1 className='pb-1' style={{  lineHeight: "1.6"}}>{t("about.story")}</h1>
                        </Animate>
                        <Animate delay={0.3} >
                            <div style={{color:"#7B659F"}}>
                        <p className='py-1'>{t("about.story_p1")}</p>
                        <strong className='py-1'>{t("about.story_p2")}</strong>
                        </div>
                        </Animate>
                        
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex flex-column align-items-center text-center my-2">
                    <Animate delay={0.5}>
                        <img 
                        // src="/media/about/1.png"
                        src=
                        {i18n.language === 'ar' ? "/media/about/1_ar.png" : "/media/about/1.png"}
                         className='w-100' />
                    </Animate>
                    </div>
                </div>
            </div>
            <div id='section-2'>
            <div className="d-flex my-5 py-4 justify-content-center flex-wrap gap-4">
            <div style={{height: 448, width: 448}}>
                <Animate delay={0.2} className="h-100 text-center">
                    <div className="card_about card_about_1  h-100" >
                        <h3 className="fs-xl">{t('about.vision')}</h3>
                        <p className="fs-sm mb-0">{t('about.vision_p1')}</p>
                    {/* <img src="/media/about/2.png" className='mt-4' style={{width:"114%"}} /> */}
                    <img src={i18n.language === 'ar' ? "/media/about/2_ar.png" : "/media/about/2.png"}
                         className="mt-4" style={{width:"114%"}} />
                    </div>
                </Animate>
            </div>
            <div style={{height: 448, width: 448}} >
                <Animate delay={0.3} className="h-100 text-center ">
                    <div className="card_about card_about_2  h-100">
                        <h3 className="fs-xl">{t('about.mission')}</h3>
                        <p className="fs-sm mb-0">{t('about.mission_p1')}</p>
                    {/* <img src="/media/about/3.png" className='w-60 mt-3' /> */}
                    <img src={i18n.language === 'ar' ? "/media/about/3_ar.png" : "/media/about/3.png"}
                         className='w-70 mt-3' style={{position: "relative", bottom: "20px"}} />
                    </div>
                </Animate>
            </div>
            <div style={{height: 448, width: 448}}>
                <Animate delay={0.4} className="h-100 text-center">
                    <div className="card_about card_about_3  h-100">
                        <h3 className="fs-xl">{t('about.compare')}</h3>
                        <p className="fs-sm mb-0">{t('about.compare_p1')}</p>
                        {/* <img src="/media/about/4.png" */}
                        <img src={i18n.language === 'ar' ? "/media/about/4_ar.png" : "/media/about/4.png"}
                         className='w-100 mt-3' />
                    </div>
                </Animate>
            </div>

            </div>
            </div>
            <div id='section-3 '>
            <div className="row  justify-content-center my-5 py-4">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex flex-column align-items-center text-center my-2">
                    <Animate delay={0.5}>
                        {/* <img src="/media/about/5.png" className='w-100' /> */}
                        <img src={i18n.language === 'ar' ? "/media/about/5_ar.png" : "/media/about/5.png"} className='w-100' />
                    </Animate>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12 d-flex flex-column align-items-start justify-content-center my-2">
                        
                        <Animate delay={0.2}>
                        <h2 className='pb-1' style={{  lineHeight: "1.6"}}>{t("about.values")}</h2>
                        </Animate>
                        <Animate delay={0.3} className="w-100">
                            <div className="row w-100">
                                <div className="col-lg-6 mt-4">
                                    <div className="card_values">
                                            <div className='icon'>
                                                <img src="/media/about/mobile.svg" />
                                            </div>
                                            <h5 className="card-title">{t("about.values_p1")}</h5>
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 mt-4">
                                    <div className="card_values">
                                            <div className='icon'>
                                                <img src="/media/about/drop.svg" />
                                            </div>
                                            <h5 className="card-title">{t("about.values_p2")}</h5>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4">
                                    <div className="card_values">
                                            <div className='icon'>
                                                <img src="/media/about/bubble.svg" />
                                            </div>
                                            <h5 className="card-title">{t("about.values_p3")}</h5>
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-4">
                                    <div className="card_values">
                                            <div className='icon'>
                                                <img src="/media/about/like.svg" />
                                            </div>
                                            <h5 className="card-title">{t("about.values_p4")}</h5>
                                    </div>
                                </div>
                            </div>
                        </Animate>
                        
                    </div>
                    
                </div>
            </div>
            <DownloadSection />
        </div>  
            <Footer />
        </div>
    )
}

export default AboutUsHeadless;
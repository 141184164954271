import { useTranslation } from "react-i18next";
import './auction.css';
import {  useEffect, useState } from "react";
import React from "react";
import Button, { ButtonSquare } from "../../components/design/buttons/Button";

import Card from "../../components/design/cards/card";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { GetAuctions } from "../../services/auction.service";
import { useNavigate } from "react-router-dom";

const Auction = () => {
    const {t, i18n} = useTranslation();
    const [plans,setPlans] = useState<any>([]);
    const [key, setKey] = useState('going');
    const fetchData = async () => {
      const response = await GetAuctions(i18n.language); // Pass the required argument to the GetItems function
      const data = response.data.data.map(
        (item: any) => {
          return {
            ...item,
            //      "id": 1,
      // "name": "test",
      // "description": "test",
      // "mobileNumber": "555555555",
      // "imageUrl": "string",
      // "providerId": 9,
      // "startTime": "2024-02-20T08:10:41.296",
      // "endTime": "0001-01-01T00:00:00",
      // "duration": 2,
      // "minBid": 500,
      // "insuranceAmount": 10
            type : item.startTime > new Date() ? 'upcoming' : item.endTime < new Date() ? 'finished' : 'going',
            endTime: new Date(item.endTime)
          }
        }
      )
      setPlans(data);
    }
    useEffect(() => {
      fetchData();
    } , []);

  

    
    

    const navigate = useNavigate();
    return (
      <>
      <div className="container" style={{minHeight: "calc(100vh - 380px)"}}>
        <Header />
        <h2 className="mt-5 pt-4 text-center mx-auto" style={{maxWidth:"600px", }}>{t("auction.title")}</h2>
        <div className="d-flex mt-4 gap-3 flex-wrap">
        <Button inner={t("auction.going")} color={"px-4 py-3 " + (key === 'going' ? "primary" : "primary-outline-primary")}  onClick={() => {
          setKey('going');      
                        }}/>
                        <Button inner={t("auction.upcoming")} color={"px-4 py-3 " + (key === 'upcoming' ? "primary" : "primary-outline-primary")}  onClick={() => {
                            setKey('upcoming');
                          }
                        }/>
                          <Button inner={t("auction.finished")} color={"px-4 py-3 " + (key === 'finished' ? "primary" : "primary-outline-primary")}  onClick={() => {
                            setKey('finished');
                          }
                        }/>
        </div>
        <div className="row mt-4 mb-5 pt-2">
        {
          plans.map((plan, index) => {
            if (plan.type !== key) {
              return null;
            }

            

            return <div className="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12 ">
              <Card inner={
                <div>
                  <div className="d-flex align-items-start">
                      <img src="https://storagetygo01.blob.core.windows.net/images/plans/Mobily_RaqiPlan.jpeg" alt="" width="50" height="50" style={{borderRadius: "8px"}} />
                      <div className="d-flex flex-column mx-3">
                          <strong className="">{plan.mobileNumber}</strong>
                          <p className="text-muted m-0">{plan.type} | <span className="price">{plan.minBid} {t("plans.sar")} </span></p>

                      </div>
                  </div>
                  <div className="d-flex align-items-center my-3 ">
                                                    {/* <i className="fas fa-phone fa-1x mx-3" style={{color: '#3EB6AE'}}></i> */}
                                                    {/* <HiOutlinePhone className="mx-3" style={{color: '#3EB6AE', fontSize: '1.5rem'}} /> */}
                                                    <div className="me-3">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M15.7089 15.1798L12.6089 13.3298C12.0689 13.0098 11.6289 12.2398 11.6289 11.6098V7.50977" stroke="#2F1952" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>

                                                      </div>

                                                    <div>
                                                        {/* <p className="m-0">{plan.endTime_formatted}</p> */}
                                                        <RefreshedTime date={plan.endTime} />

                                                        
                                                        <p className="font-weight-light text-muted m-0">{t("auction.time")}</p>
                                                    </div>
                                                </div>
                          
                          <Button inner={t("auction.details")}
                                             onClick={function (): void {
                                                navigate('/auction/' + plan.id);
                                                } } color="primary px-4 py-3 px-lg-5" isrounded={false}/>
                                                </div>
                 }  />
            </div>
          })
        }
        </div>
        
        
      </div>
      <Footer />
      </>
    );
   
    }

const RefreshedTime = ({date}: {date: Date}) => {
  const [time, setTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      // date - current date
      const fromNowuntilDate = date.getTime() - new Date().getTime();
      setTime(new Date(fromNowuntilDate));

    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return <p className="mb-0">{time.toLocaleTimeString()}</p>
}
    
export default Auction;

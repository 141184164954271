
import React from 'react';
//props
import './select.css'

interface SelectProps {
options: {value: any, name: string}[];
    selected: any;
    onSelect: (option: string) => void;
}


const SelectOptions = (props: SelectProps) => {

    const [showOptions, setShowOptions] = React.useState(false);
    

    return (
        // <div className="select-wrapper col-12 col-md-3 mb-3">
        //     <div className="input-select-dropdown">
        //         <div className="input-select-dropdown-selcted">
        //             <span>{props.selected}</span>
        //             <i className="fas fa-chevron-down"></i>
        //         </div>
        //         <div className="input-select-dropdown-options">
        //             {
        //                 props.options.map((option: string) => (
        //                     <div className="input-select-dropdown-option" key={option} onClick={() => props.onSelect(option)}>
        //                         <span>{option}</span>
        //                     </div>
        //                 ))
        //             }
        //         </div>
        //     </div>
        // </div>

        <div className="select-wrapper col-12 col-lg-5 mb-3 d-flex gap-3">
            <div className="input-select-dropdown">
                <div className="input-select-dropdown-selcted
                " onClick={() => setShowOptions(!showOptions)}>
                    <span>{props.options.find((option: any) => option.value === props.selected)?.name}</span>
                    <i className="fas fa-chevron-down"></i>
                </div>
                {
                    showOptions && (
                        <div className="input-select-dropdown-options">
                            {
                                props.options.map((option: any) => (
                                    <div 
                                    // className="input-select-dropdown-option"
                                    className={`input-select-dropdown-option ${option.value === props.selected ? "input-select-dropdown-option-active" : ""}`}
                                     key={option.value} onClick={() => {
                                        props.onSelect(option.value);
                                        setShowOptions(false);
                                    }}>
                                        <span>{option.name}</span>
                                    </div>
                                ))

                            }
                        </div>
                    )
                }
            </div>

            {/* <label htmlFor='discount' className="input-select-dropdown d-flex align-items-center justify-content-center"
                style={{cursor: "pointer",
                    maxWidth: "156px",
                }}>
                <div className="d-flex align-items-center my-2" style={{cursor: "pointer"}}>
                    <label className="check_box_filtter "><input type="checkbox" id='discount' className=""/>
                        </label><span className="mx-2">Discount</span></div>
                
            </label> */}
        </div>

    )
}

export default SelectOptions;